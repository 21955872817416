import { useState } from "react";

export default function useAddPrice(props) {
  const [price, setPrice] = useState(0);
  const [currency, setCurrency] = useState("");
  const { onSavePrice } = props;

  const handlePriceChange = (e) => {
    setPrice(e.target.value);
  };

  const handleCurrencyChange = (e) => {
    setCurrency(e.target.value);
  };

  const handleSavePrice = () => {
    onSavePrice && onSavePrice(price, currency);
  };

  return {
    price,
    currency,
    onPriceChange: handlePriceChange,
    onCurrencyChange: handleCurrencyChange,
    onSave: handleSavePrice,
  };
}
