import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AppContent from "./AppContent";

// AppRoot is the common feature available between Authenticated and Non-authenticated display.
// Such as footer and common link /contact
//It renders then AppContent (responsible for auth and non-auth render)

const AppRoot = (props) => {
  return (
    <Router>
      <Switch>
        <Route path="/contact"></Route>
        <Route path="/about"></Route>
        <AppContent />
        {/* place footer */}
      </Switch>
    </Router>
  );
};

export default AppRoot;
