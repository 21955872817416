import React from "react";

import useAdminSales from "../hooks/useAdminSales";
import AdminSales from "../components/AdminSales";

const AdminSalesInterface = function (props) {
  const { onEdit, businessType, canEdit } = props;
  const {
    sales,
    superUser,
    onDelete,
    onRefreshData,
    apiError,
    onPdfRequest,
    search,
  } = useAdminSales(props);

  return (
    <AdminSales
      canEdit={canEdit}
      businessType={businessType}
      superUser={superUser}
      sales={sales}
      onEdit={onEdit}
      onDelete={onDelete}
      apiError={apiError}
      onPdfRequest={onPdfRequest}
      onRefreshData={onRefreshData}
      search={search}
    />
  );
};

export default AdminSalesInterface;
