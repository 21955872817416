import React from "react";
import Button from "@material-ui/core/Button";
import CommonDialog from "./utils/Dialogs";
import { TextField, FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles/";
import CustomSnackbar from "./utils/Snakbar";

const useStyles = makeStyles({
  root: {
    marginTop: 20,
    border: "0px solid red",
  },
  subtitle: {
    margin: "10px auto 20px auto",
    display: "inline-block",
  },
  actions: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: 40,
    padding: "10px 0",
  },
});

export default function AddDimension(props) {
  const {
    open,
    dimension,
    type: code,
    submitting,
    openSnack,
    snackbarMessage,
    onDimensionChange,
    onTypeChange,
    openDialog,
    closeDialog,
    onSave,
    onCancel,
    onCloseSnack,
  } = props;

  const classes = useStyles();

  return (
    <React.Fragment>
      <CustomSnackbar
        text={snackbarMessage}
        open={openSnack}
        onCloseSnakbar={onCloseSnack}
      />
      <Button variant="contained" onClick={openDialog}>
        Ajouter une caractéristique
      </Button>
      <CommonDialog
        onClose={closeDialog}
        open={open}
        title="Ajouter une dimension"
        render={() => (
          <React.Fragment>
            <div className={classes.subtitle}>
              Une dimension peut-être une taille, une couleur, etc.
            </div>
            <form noValidate autoComplete="off" className={classes.root}>
              <FormControl fullWidth>
                <TextField
                  label="Dimension"
                  variant="outlined"
                  value={dimension}
                  fullWidth
                  onChange={onDimensionChange}
                ></TextField>
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  label="Code"
                  variant="outlined"
                  margin="normal"
                  value={code}
                  fullWidth
                  onChange={onTypeChange}
                ></TextField>
              </FormControl>
              <div className={classes.actions}>
                <Button
                  variant="contained"
                  onClick={onSave}
                  disabled={submitting}
                >
                  Sauver
                </Button>
                <Button
                  variant="contained"
                  disabled={submitting}
                  onClick={onCancel}
                >
                  Annuler
                </Button>
              </div>
            </form>
          </React.Fragment>
        )}
      ></CommonDialog>
    </React.Fragment>
  );
}

AddDimension.propTypes = {};
