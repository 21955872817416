import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import useAPI from "../hooks/useAPI";

import { useDispatch } from "react-redux";
import allTicketActions from "../state/tickets/actions/index";
import { useParams } from "react-router-dom";

export default function useAdminTickets(props) {
  const dispatch = useDispatch();
  const { callback, onSave } = props;
  const localUser = JSON.parse(localStorage.getItem("kdmUser"));
  const superUser = localUser.sub === process.env.REACT_APP_ADMIN_UID;

  const [apiError] = useState(false); //TODO:Placer dans useAPI
  const [candidateForSMS, setCandidateForSMS] = useState({});

  let { ticketCode } = useParams();

  const [search, setSearch] = useState(ticketCode || "");

  const { data, query: fetchData } = useAPI({
    type: "get",
    url: `tickets`,
  });

  const tickets = useSelector((state) => {
    return state.tickets.tickets;
  });

  const { query: sendSMSForTicket } = useAPI({
    defer: true,
    type: "post",
    url: `ticket/sms/${btoa(localUser.sub)}`,
    body: {
      ticketId: candidateForSMS.ticketId,
      saleId: candidateForSMS.saleId,
      customerId: candidateForSMS.customerId,
    },
  });

  const handleDelete = async (id) => {
    console.log("must no be implemented");
  };

  const handleSMSRequest = async (data) => {
    setCandidateForSMS(data);
  };

  const handleRefreshData = async () => {
    await fetchData();
  };

  const handleSendSMSForTicket = async () => {
    try {
      await sendSMSForTicket();
      onSave && onSave({ success: true, message: "SMS envoyé avec succès." });
      callback && callback();
    } catch (err) {
      console.log(err);
      onSave &&
        onSave({ success: false, message: "Erreur à l'envoi du SMS'." });
    }
  };

  useEffect(() => {
    if (data && data.items) {
      if (search === "[latest]") {
        setSearch(data.items[0].ticketCode);
      }
      dispatch(allTicketActions.ticketActions.fetchAllTickets(data.items));
    }
    if (candidateForSMS.ticketCode) {
      handleSendSMSForTicket();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, candidateForSMS, search]);

  return {
    tickets,
    superUser,
    onDelete: handleDelete,
    onRefreshData: handleRefreshData,
    apiError,
    onSMSRequest: handleSMSRequest,
    candidateForSMS,
    search,
  };
}
