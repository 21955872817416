import React from "react";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";

import { makeStyles } from "@material-ui/core/styles";
// import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 0,
    border: "0px solid red",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  platformTitle: {
    marginTop: 20,
    marginBottom: 20,
  },
  marginRight: {
    marginRight: 10,
  },
  fullWidth: { width: "100%" },
  extraMarginTop: {
    marginTop: 20,
  },
  categories: {
    marginTop: 20,
    marginBottom: 20,
  },
  actions: {
    display: "flex",
    justifyContent: "space-around",
    padding: "10px 0",
  },
  platforms: {
    margin: "20px 0",
  },
}));

// const useStylesBootstrap = makeStyles((theme) => ({
//   arrow: {
//     color: theme.palette.common.black,
//   },
//   tooltip: {
//     // backgroundColor: theme.palette.common.black,
//     backgroundColor: theme.palette.primary.main,
//     fontSize: 13,
//   },
// }));

// function BootstrapTooltip(props) {
//   const classes = useStylesBootstrap();

//   return <Tooltip arrow classes={classes} {...props} />;
// }

const AdminPayment = (props) => {
  const {
    name,
    account,
    owner,
    submitting,
    onNameChange,
    onAccounthange,
    onOwnerNameChange,
    onPostItem,
    onCancelAction,
  } = props;
  const classes = useStyles();

  return (
    <React.Fragment>
      <form noValidate autoComplete="off" className={classes.root}>
        <FormControl fullWidth>
          <TextField
            label="Institution financière"
            margin="normal"
            onChange={onNameChange}
            variant="outlined"
            value={name}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            label="IBAN"
            margin="normal"
            onChange={onAccounthange}
            variant="outlined"
            value={account}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            label="Nom & prénom du propriétaire"
            margin="normal"
            onChange={onOwnerNameChange}
            variant="outlined"
            value={owner}
          />
        </FormControl>
      </form>
      <FormControl fullWidth></FormControl>
      <div className={classes.actions}>
        <Button variant="contained" disabled={submitting} onClick={onPostItem}>
          {submitting ? "processing ..." : "Sauver"}
        </Button>
        <Button
          variant="contained"
          disabled={submitting}
          onClick={onCancelAction}
        >
          Annuler
        </Button>
      </div>
    </React.Fragment>
  );
};

export default AdminPayment;
