import { useState, useEffect } from "react";

import useAPI from "../hooks/useAPI";

import { useDispatch } from "react-redux";
import allMerchantActions from "../state/merchants/actions";

export default function useAdminPickAndSelectMenu(props) {
  const dispatch = useDispatch();
  const localUser = JSON.parse(localStorage.getItem("kdmUser"));

  const [submitting, setSubmitting] = useState(false);
  const { merchants, onPostSave } = props;
  const [menu, setMenu] = useState([]);
  const [disabledButton, setDisableButton] = useState(true);

  const { data, query: fetchData } = useAPI({
    type: "get",
    url: `merchants`,
    defer: true,
  });

  const { query: updateMenus } = useAPI({
    defer: true,
    type: "post",
    url: `merchants`,
    body: { merchants, menuId: menu },
  });

  const handleSubmit = async () => {
    try {
      // setApiError(false);
      setSubmitting(true);
      await updateMenus();
      await fetchData();
      onPostSave({ success: true, message: "Sauvegardé avec succès." });
    } catch (err) {
      onPostSave({
        success: false,
        message: "Une erreur est survenue ä la sauvegarde.",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleChange = (event) => {
    setMenu(event.target.value);
    return setDisableButton(false);
  };

  useEffect(() => {
    if (data && data.merchants) {
      dispatch(
        allMerchantActions.merchantActions.fetchAllMerchants(data.merchants)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    menu,
    onChange: handleChange,
    onSubmit: handleSubmit,
    disabledButton,
    submitting,
  };
}
