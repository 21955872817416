import React from "react";

// import useAdminHelper from "../hooks/useAdminHelper";
import AdminHelper from "../components/AdminHelper";

const AdminMerchantsInterface = function (props) {
  return <AdminHelper />;
};

export default AdminMerchantsInterface;
