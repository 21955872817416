import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  video: {
    border: "0",
    maxWidth: 560,
    width: "100%",
  },
  marginBottom: {
    marginBottom: 20,
  },
}));

function getSteps() {
  return [
    "Configurer votre nouvelle boutique",
    "Création de vos articles",
    "Inclure la boutique au sein de votre site.",
  ];
}

function getStepContent(step, videos, classes) {
  switch (step) {
    case 0:
      return (
        <React.Fragment>
          <iframe
            className={classes.video}
            title={`video-`}
            width="560"
            height="315"
            src={videos.find((v) => v.key === 0).src}
            allowFullScreen
          ></iframe>
        </React.Fragment>
      );
    case 1:
      return (
        <React.Fragment>
          <iframe
            className={classes.video}
            title={`video-`}
            width="560"
            height="315"
            src={videos.find((v) => v.key === 1).src}
            allowFullScreen
          ></iframe>
        </React.Fragment>
      );
    case 2:
      return (
        <React.Fragment>
          <Typography>
            Saviez-vous qu'il était également possible d'inclure le shop au sein
            même de votre site ? Nous nous réjouissons de vous présenter cette
            fonctionalités dans les jours à venir !
          </Typography>
          {/* <iframe
            className={classes.video}
            title={`video-`}
            width="560"
            height="315"
            src={videos.find((v) => v.key === 2).src}
            allowFullScreen
          ></iframe> */}
        </React.Fragment>
      );
    default:
      return "Unknown step";
  }
}

export default function CommonStepper(props) {
  const { videos } = props;
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <Typography variant="h6" className={classes.marginBottom}>
              {label}
            </Typography>
            <StepContent>
              {getStepContent(index, videos, classes)}
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Retour
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? "Fin" : "Suivant"}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>
            Etapes complétées - des questions ? Nous sommes à votre disposition
            !
          </Typography>
          <Button onClick={handleReset} className={classes.button}>
            Recommencer
          </Button>
        </Paper>
      )}
    </div>
  );
}
