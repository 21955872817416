import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import useAPI from "../hooks/useAPI";

import { useDispatch } from "react-redux";
import allPaymentsActions from "../state/payments/actions/index";

export default function useAdminPayments() {
  const dispatch = useDispatch();
  const localUser = JSON.parse(localStorage.getItem("kdmUser"));

  const [apiError, setApiError] = useState(false); //TODO:Placer dans useAPI

  const { data, query: fetchData } = useAPI({
    type: "get",
    url: `paymentsx`,
  });

  const payments = useSelector((state) => {
    return state.payments.payments;
  });

  const { query: deleteItem } = useAPI({
    defer: true,
    type: "delete",
    url: "/payment",
    body: {},
  });

  const handleDelete = async (id) => {
    try {
      setApiError(false);
      await deleteItem({
        url: `payment/${id}`,
      });
      await fetchData();
    } catch (error) {
      throw error;
      // setApiError(true); // TODO: Voir si on garde ce truc, pas vraiment utile je crois
    }
  };

  const handleRefreshData = async () => {
    await fetchData();
  };

  useEffect(() => {
    if (data && data.payments) {
      dispatch(
        allPaymentsActions.paymentActions.fetchAllPayments(data.payments)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    payments,
    onDelete: handleDelete,
    onRefreshData: handleRefreshData,
    apiError,
  };
}
