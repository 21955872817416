import React from "react";
// import { SignOut } from "aws-amplify-react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { NavLink } from "react-router-dom";
// import { Button } from "@material-ui/core";
import DeckIcon from "@material-ui/icons/Deck";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import DashboardIcon from "@material-ui/icons/Dashboard";
import HelpIcon from "@material-ui/icons/Help";
import DehazeIcon from "@material-ui/icons/Dehaze";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PeopleIcon from "@material-ui/icons/People";
import CategoryIcon from "@material-ui/icons/Category";

// import MenuOpenIcon from "@material-ui/icons/MenuOpen";

const localUser = JSON.parse(localStorage.getItem("kdmUser"));
const superUser =
  localUser && localUser.sub === process.env.REACT_APP_ADMIN_UID;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 220,
    background: theme.palette.secondary.main,
    paddingTop: 20,
    color: "#000",
    height: "inherit",

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  listItem: {
    paddingLeft: 5,
    padding: "6px 0",
  },
}));

const AdminSidebar = () => {
  const classes = useStyles();
  const localUser = JSON.parse(localStorage.getItem("kdmUser"));
  const { email } = localUser;

  return (
    <div className={classes.root}>
      <List component="nav" className={classes.root} aria-label="dashboard">
        <ListItem button to="/profile" component={NavLink}>
          <PersonOutlineIcon />
          <ListItemText className={classes.listItem} inset primary={email} />
        </ListItem>
        <ListItem button to="/merchants" component={NavLink}>
          <DeckIcon />
          <ListItemText
            className={classes.listItem}
            inset
            primary="Point de vente"
          />
        </ListItem>
        <ListItem button to="/categories" component={NavLink}>
          <CategoryIcon />
          <ListItemText
            className={classes.listItem}
            inset
            primary="Mes menus"
          />
        </ListItem>
        <ListItem button to="/items" component={NavLink}>
          <DehazeIcon />
          <ListItemText
            className={classes.listItem}
            inset
            primary="Mes articles"
          />
        </ListItem>
        <ListItem button to="/sales" component={NavLink}>
          <MonetizationOnIcon />
          <ListItemText
            className={classes.listItem}
            inset
            primary="Mes ventes"
          />
        </ListItem>
        <ListItem button to="/payments" component={NavLink}>
          <AccountBalanceIcon />
          <ListItemText className={classes.listItem} inset primary="Paiement" />
        </ListItem>
        <ListItem button to="/customers" component={NavLink}>
          <MonetizationOnIcon />
          <ListItemText
            className={classes.listItem}
            inset
            primary="Mes clients"
          />
        </ListItem>{" "}
        <ListItem button to="/tickets" component={NavLink}>
          <MonetizationOnIcon />
          <ListItemText
            className={classes.listItem}
            inset
            primary="Mes tickets générés"
          />
        </ListItem>
        {superUser ? (
          <ListItem button to="/users" component={NavLink}>
            <PeopleIcon />
            <ListItemText
              className={classes.listItem}
              inset
              primary="Utilisateurs"
            />
          </ListItem>
        ) : null}
        <ListItem button to="/helper" component={NavLink}>
          <HelpIcon />
          <ListItemText className={classes.listItem} inset primary="Aide" />
        </ListItem>
        <ListItem button to="/logout" component={NavLink}>
          <ExitToAppIcon />
          <ListItemText
            className={classes.listItem}
            inset
            primary="Se déconnecter"
          />
        </ListItem>
        <ListItem button to="/" component={NavLink}>
          <DashboardIcon />
          <ListItemText
            className={classes.listItem}
            inset
            primary="Dashboard"
          />
        </ListItem>
        {/* <ListItem
          className={classes.listItem}
          button
          to="/logout"
          component={NavLink}
        >
          <ExitToAppIcon />
          <SignOut />
        </ListItem> */}
      </List>
    </div>
  );
};

export default AdminSidebar;
