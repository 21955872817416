import React from "react";

import useAdminTickets from "../hooks/useAdminTickets";
import AdminTickets from "../components/AdminTickets";

const AdminTicketsInterface = function (props) {
  const { onEdit, businessType, canEdit } = props;
  const {
    tickets,
    superUser,
    onDelete,
    onRefreshData,
    onSMSRequest,
    apiError,
    candidateForSMS,
    search,
  } = useAdminTickets(props);
  return (
    <AdminTickets
      businessType={businessType}
      superUser={superUser}
      tickets={tickets}
      onEdit={onEdit}
      canEdit={canEdit}
      onDelete={onDelete}
      apiError={apiError}
      onRefreshData={onRefreshData}
      onSMSRequest={onSMSRequest}
      candidateForSMS={candidateForSMS}
      search={search}
    />
  );
};

export default AdminTicketsInterface;
