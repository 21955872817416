import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: 10,
  },
  search: {
    marginTop: 20,
    marginBottom: 20,
  },
}));

export default function SearchBar(props) {
  const classes = useStyles();
  const { onUpdateSearch, search } = props;
  const [searchValue, setSearchValue] = React.useState(search || "");

  const handleChangeValue = (e) => {
    setSearchValue(e.target.value);
    onUpdateSearch && onUpdateSearch(e.target.value);
  };

  return (
    <div className={classes.root}>
      <FormControl fullWidth noValidate autoComplete="off">
        <TextField
          variant="outlined"
          className={classes.search}
          label="Rechercher"
          id="search"
          value={searchValue}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleChangeValue}
        />
      </FormControl>
    </div>
  );
}
