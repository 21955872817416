import React from "react";
import AdminMenu from "../layout/AdminMenu";

export default function AdminPage() {
  return (
    <React.Fragment>
      <AdminMenu />
    </React.Fragment>
  );
}
