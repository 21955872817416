import { combineReducers } from "redux";
import items from "./items/reducers/index";
import merchants from "./merchants/reducers/index";
import users from "./users/reducers/index";
import sales from "./sales/reducers/index";
import payments from "./payments/reducers/index";
import categories from "./categories/reducers/index";
import customers from "./customers/reducers/index";
import tickets from "./tickets/reducers/index";

export default combineReducers({
  items,
  merchants,
  sales,
  users,
  payments,
  categories,
  customers,
  tickets,
});
