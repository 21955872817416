import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
    marginTop: "2%",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontSize: "40px",
    fontWeight: "500",
    marginTop: "80px",
    textAlign: "center",
  },
  media: {
    height: 400,
    width: "100%",
  },
});

export default function AdminDashboard() {
  const classes = useStyles();
  // const imageSrc = `https://neilpatel.com/wp-content/uploads/2017/09/content-marketing-tools.jpg`;
  // const imageSrc = `https://i2.wp.com/dbpspc.org.au/wp-content/uploads/2019/09/Online-Payment-072418.jpg?fit=1024%2C650&ssl=1`;
  const imageSrc = `https://cdni.rbth.com/rbthmedia/images/2018.10/original/5bc920f985600a58bc5f370e.jpg`;
  // const imageSrc = `https://blog.ca-nord-est.fr/wp-content/uploads/2020/01/iStock-675913278-Protection-donn%C3%A9es.jpg`;

  return (
    <div className={classes.root}>
      <Card className={classes.root}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Admin • Kadomi.ch
          </Typography>
          <Typography>
            Bienvenue sur l'espace d'administration de Kadomi.ch
          </Typography>
          <Typography>
            Saisissez vos articles, ajoutez vos plateformes de ventes de choix
            et vendez vos articles.
          </Typography>
        </CardContent>
        <CardMedia
          className={classes.media}
          image={imageSrc}
          title="Contemplative Reptile"
        />
      </Card>
    </div>
  );
}
