import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { Typography } from "@material-ui/core";

import { useDispatch } from "react-redux";
import allActions from "./../state/items/actions/index";

export default function AdminLogout(props) {
  const [logout, setlogout] = useState(false);
  const dispatch = useDispatch();


  const signOut = async () => {
    try {
      await Auth.signOut();
      setlogout(true);
      window.location.href = "/";
      dispatch(allActions.itemActions.clearUser());
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  

  useEffect(() => {
    signOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return logout ? (
    <Typography>... Déconnexion en cours...</Typography>
  ) : (
    <Typography>Non déconnecté.</Typography>
  );
}
