import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import useAPI from "../hooks/useAPI";

import { useDispatch } from "react-redux";
import allSaleActions from "../state/sales/actions/index";

import { useParams } from "react-router-dom";

export default function useAdminSales(props) {
  const dispatch = useDispatch();
  const { callback, onSave } = props;
  const localUser = JSON.parse(localStorage.getItem("kdmUser"));
  const superUser = localUser.sub === process.env.REACT_APP_ADMIN_UID;

  const [apiError] = useState(false); //TODO:Placer dans useAPI
  const [candidateForPdf, setCandidateForPdf] = useState({});

  const { saleId: search } = useParams();

  const { data, query: fetchData } = useAPI({
    type: "get",
    url: `payments`,
  });

  const sales = useSelector((state) => {
    return state.sales.sales;
  });

  const { query: requestPdfForTicket } = useAPI({
    defer: true,
    type: "post",
    url: `ticket/pdf/${btoa(localUser.sub)}`,
    body: {
      ticketId: candidateForPdf.ticketId,
      saleId: candidateForPdf.saleId,
      customerId: candidateForPdf.customerId,
    },
  });

  // methods
  const handleRequestPdfForTicket = async () => {
    try {
      await requestPdfForTicket();
      onSave && onSave({ success: true, message: "PDF générés avec succès." });
      callback && callback();
    } catch (err) {
      console.log(err);
      onSave &&
        onSave({ success: false, message: "Erreur à la génération du PDF." });
    }
  };

  const handleDelete = async (id) => {
    console.log("must no be implemented");
  };

  const handleRefreshData = async () => {
    await fetchData();
  };

  const handlePdfRequest = async (data) => {
    setCandidateForPdf(data);
  };

  useEffect(() => {
    if (data && data.sales) {
      dispatch(allSaleActions.saleActions.fetchAllSales(data.sales));
    }

    if (candidateForPdf.id && candidateForPdf.customerId) {
      handleRequestPdfForTicket();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, candidateForPdf]);

  return {
    sales,
    superUser,
    onDelete: handleDelete,
    onRefreshData: handleRefreshData,
    onPdfRequest: handlePdfRequest,
    apiError,
    search,
  };
}
