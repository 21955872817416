import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ChipInput from "material-ui-chip-input";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    margin: "10px auto",
    color: theme.palette.primary.main,
  },
  delete: {
    position: "absolute",
    marginTop: "-14px",
    marginLeft: "-6px",
  },
}));

export default function ItemsEnumerator(props) {
  const classes = useStyles();
  const { values, title, type, canDelete, onChange, onDelete } = props;
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        {title}
        {canDelete ? (
          <IconButton className={classes.delete} onClick={() => onDelete(type)}>
            <HighlightOffIcon />
          </IconButton>
        ) : null}
      </div>
      <ChipInput
        defaultValue={values}
        onChange={(chips) => onChange(chips, type)}
      />
    </div>
  );
}
