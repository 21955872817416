import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import SettingsIcon from "@material-ui/icons/Settings";
import IconButton from "@material-ui/core/IconButton";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import SendIcon from "@material-ui/icons/Send";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import CommonDialog from "./utils/Dialogs";
import CustomSnackbar from "./utils/Snakbar";
import IconRefresh from "./icons/Refresh";
import DoneIcon from "@material-ui/icons/Done";
import ErrorIcon from "@material-ui/icons/Error";
import SearchBar from "./utils/SearchBar";
import { isMobile } from "react-device-detect";
import { CircularProgress, Tooltip } from "@material-ui/core";

import parse from "html-react-parser";
import { parseSaleNote } from "./utils/applibs";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  tableWrapper: {},
  imgItem: {
    height: 40,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  chipize2: {
    margin: theme.spacing(0.5),
    fontSize: "13px",
  },
  search: {
    margin: "20px auto",
  },
  success: {
    background: "#bcffbc",
    padding: "5px 9px",
    borderRadius: "7px",
  },
  open: {
    background: "#d8eafd",
    padding: "5px 9px",
    borderRadius: "7px",
  },
  pending: {
    background: "#fff1bc",
    padding: "5px 9px",
    borderRadius: "7px",
  },
  failed: {
    background: "#ef7b7b",
    color: "white",
    padding: "5px 9px",
    borderRadius: "7px",
  },
  saleDateMobile: {
    fontSize: "11px",
    display: "block",
    marginTop: "8px",
  },
  tablecellHeaderMobile: {
    fontSize: "14px",
  },
  tablecellMobile: {
    fontSize: "14px !important",
  },
}));

export default function StickyHeadTable(props) {
  // const {  } = props;
  const classes = useStyles();
  const {
    superUser,
    columns,
    data,
    onEdit,
    onEdit2,
    onRefreshData,
    onDelete,
    onItemsForSelectionChange,
    checkboxEnabled,
    onShoppingSnipetRequest,
    onSMSRequest,
    onPdfRequest,
    filters,
    search,
    businessType,
    canEdit,
  } = props;

  const [isLoading, setIsLoading] = React.useState(true);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [currentId, setCurrentId] = React.useState(0);

  const [open, setOpen] = React.useState(false); // dialog
  const [title, setTitle] = React.useState(""); // dialog

  const [success, setSuccess] = React.useState(false); //snack
  const [textSnack, setTextSnack] = React.useState(""); //snack

  const [selected, setRowSelected] = React.useState([]);

  const [searchValue, setSearchValue] = React.useState(search || "");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDialog = (open, title, mode, id) => {
    if (id) {
      setCurrentId(id); // to handle the local confirmation delete
    }

    if (mode === "edit") {
      onEdit(id);
    } else {
      // assume : delete action
      setOpen(open);
      setTitle(title);
    }
  };

  const confirmDelete = async () => {
    try {
      await onDelete(currentId);
      setTextSnack("Article supprimé");
      setCurrentId(0);
      setOpen(false);
      handleSnackbar(true);
    } catch (error) {
      setTextSnack("Erreur lors de la suppression de l'article!");
      setOpen(false);
      handleSnackbar(true);
    }
  };

  const cancelDelete = async () => {
    setCurrentId(0);
    setOpen(false);
  };

  const handleSnackbar = bool => {
    setSuccess(bool);
  };

  const DeleteConfirmationComponent = () => {
    return (
      <React.Fragment>
        Vous êtes sur le point de supprimer l'article #{currentId}, continuer ?
        <div style={{ margin: "20px 0" }}>
          <Button onClick={() => confirmDelete()}>Oui</Button>
          <Button onClick={() => cancelDelete()}>Annuler</Button>
        </div>
      </React.Fragment>
    );
  };

  const handleRowClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setRowSelected(newSelected);
    onItemsForSelectionChange && onItemsForSelectionChange(newSelected);
  };

  const handleSelectAllClick = event => {
    let newSelecteds = [];
    if (event.target.checked) {
      newSelecteds = rows.map(r => r.id);
      setRowSelected(newSelecteds);
      onItemsForSelectionChange && onItemsForSelectionChange(newSelecteds);
      return;
    }
    setRowSelected([]);
    onItemsForSelectionChange && onItemsForSelectionChange(newSelecteds);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  const handleUpdateSearch = value => {
    setSearchValue(value);
  };

  const findInRow = (row, value) => {
    let found = false;
    filters.forEach(filter => {
      let val = row[filter];
      if (typeof val === "string") {
        if (val.toLowerCase().indexOf(value.toLowerCase().trim()) !== -1) {
          found = true;
        }
      }

      if (val && typeof val === "object") {
        for (const obj of val) {
          if (
            obj.name &&
            obj.name.toLowerCase().indexOf(value.toLowerCase().trim()) !== -1
          ) {
            found = true;
          }
        }
      }
    });
    return found;
  };

  const HtmlTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  const treatRows = data => {
    try {
      return (
        (Array.isArray(data) && data.map(d => d)).filter(r => {
          return findInRow(r, searchValue);
        }) || []
      );
    } catch (error) {
      console.log("error", error);
      return [];
    }
  };

  const rows = treatRows(data);

  const isItems = rows.some(d => !!d.eventPlanner);

  const getSaleCreationDate = row => {
    const sale = rows.find(r => r.saleId === row.saleId);
    return sale.saleCreated;
  };

  const getCustomerEmail = row => {
    const user = rows.find(r => r.userId === row.userId);
    return user.userEmail !== "" ? user.userEmail : null;
  };

  const getParsedSaleNote = row => {
    const sale = rows.find(r => r.saleId === row.saleId);
    return parseSaleNote(sale.dimensions);
  };

  const getCustomerSummary = row => {
    const sale = rows.find(r => r.saleId === row.saleId);
    const concatName = `${sale.userFirstname} ${sale.userLastname} (${sale.customerId})`;
    return concatName !== " " ? concatName : null;
  };

  const preventDeleteItem = row => {
    return row.busy > 0 || parseInt(row.demo, 10) === 0;
  };

  const getStatusClass = value => {
    if (value === "success") {
      return "success";
    }
    if (value === "opened") {
      return "open";
    }
    if (value === "pending") {
      return "pending";
    }
    if (value === "#error") {
      return "failed";
    }
    return "";
  };

  useEffect(() => {
    if (search) {
      setSearchValue(search);
    }
    if (data.length > 0) {
      setIsLoading(false);
    }
  }, [search, data]);

  return (
    <React.Fragment>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Paper className={classes.root}>
          <CustomSnackbar
            text={textSnack}
            open={success}
            onCloseSnakbar={handleSnackbar}
          />
          <CommonDialog
            onClose={() => setOpen(false)}
            title={title}
            open={open}
            render={() => <DeleteConfirmationComponent />}
          />

          <SearchBar search={searchValue} onUpdateSearch={handleUpdateSearch} />
          <Button onClick={onRefreshData}>
            <IconRefresh />
          </Button>
          {!isMobile ? (
            <Button
              disabled={selected.length < 1}
              onClick={() =>
                handleDialog(true, "Supprimer un article", "delete", selected)
              }
            >
              <DeleteIcon />
            </Button>
          ) : null}

          <div className={classes.tableWrapper}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {checkboxEnabled && !isMobile ? (
                    <TableCell>
                      <Checkbox
                        checked={
                          rows.length > 0 && selected.length === rows.length
                        }
                        onChange={handleSelectAllClick}
                      />
                    </TableCell>
                  ) : null}
                  {columns.map(column => (
                    <TableCell
                      className={
                        isMobile ? classes.tablecellHeaderMobile : null
                      }
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.id === "action" && isMobile ? (
                        <SettingsIcon />
                      ) : (
                        column.label
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, key) => {
                    const isItemSelected = isSelected(row.id);
                    return (
                      <TableRow
                        key={key}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        // key={row.id}
                        onClick={event => handleRowClick(event, row.id)}
                      >
                        {checkboxEnabled && !isMobile ? (
                          <TableCell>
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": null }}
                            ></Checkbox>
                          </TableCell>
                        ) : null}

                        {columns.map(column => {
                          const value = row[column.id] || "";

                          return column.id === "action" ? (
                            <TableCell key={column.id}>
                              <IconButton
                                edge="end"
                                aria-label="Editer"
                                title="Editer"
                                onClick={() =>
                                  handleDialog(
                                    true,
                                    "Editer l'article",
                                    "edit",
                                    row.id
                                  )
                                }
                              >
                                <EditIcon />
                              </IconButton>
                              {businessType === "sales" &&
                              onPdfRequest &&
                              row.saleStatus === "success" ? (
                                <IconButton
                                  edge="Regénérer le PDF"
                                  aria-label="Regénérer le PDF"
                                  title="Regénérer le PDF"
                                  onClick={() => {
                                    onPdfRequest(row);
                                  }}
                                >
                                  <PictureAsPdfIcon />
                                </IconButton>
                              ) : null}

                              {isItems ? (
                                <IconButton
                                  edge="end"
                                  aria-label="Créer un ticket"
                                  title="Créer un ticket"
                                  onClick={() => onEdit2(row.id)}
                                >
                                  <AddCircleIcon />
                                </IconButton>
                              ) : null}

                              {!superUser ? (
                                <IconButton
                                  disabled={preventDeleteItem(row)}
                                  edge="end"
                                  aria-label="Supprimer"
                                  title="Supprimer"
                                  onClick={() =>
                                    handleDialog(
                                      true,
                                      "Supprimer un article",
                                      "delete",
                                      row.id
                                    )
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              ) : null}

                              {onShoppingSnipetRequest ? (
                                <IconButton
                                  edge="end"
                                  aria-label="code du site"
                                  onClick={() => onShoppingSnipetRequest(row)}
                                >
                                  <ShoppingCartIcon />
                                </IconButton>
                              ) : null}

                              {columns.find(c => c.id === "ticketCode") ? (
                                <IconButton
                                  edge="end"
                                  aria-label="renvoyer le SMS"
                                  title="renvoyer le SMS"
                                  onClick={() => onSMSRequest(row)}
                                >
                                  <SendIcon />
                                </IconButton>
                              ) : null}
                            </TableCell>
                          ) : (
                            <TableCell
                              className={
                                isMobile ? classes.tablecellMobile : null
                              }
                              key={column.id}
                              align={column.align}
                            >
                              {column.format && typeof value === "number" ? (
                                column.format(value)
                              ) : column.id === "apikey" ? (
                                `${value.substr(0, 5)}...`
                              ) : column.id === "files" ? (
                                Array.isArray(value) ? (
                                  value.map((v, k) => {
                                    const url =
                                      process.env.REACT_APP_BUCKETS3 + v;
                                    return (
                                      <img
                                        key={k}
                                        alt={`${v}`}
                                        className={classes.imgItem}
                                        src={url}
                                      />
                                    );
                                  })
                                ) : (
                                  value
                                )
                              ) : column.id === "platforms" ? (
                                Array.isArray(value) ? (
                                  value.map((v, i) => {
                                    let icon;
                                    return (
                                      <Chip
                                        key={i}
                                        icon={icon}
                                        label={
                                          v &&
                                          v.name &&
                                          v.name.replace("https://", "")
                                        }
                                        // onDelete={data.label === 'React' ? undefined : handleDelete(data)}
                                        className={classes.chip}
                                      />
                                    );
                                  })
                                ) : (
                                  ""
                                )
                              ) : column.id === "referer" ? (
                                value.replace("https://", "")
                              ) : column.id === "compliant" ? (
                                value.length === 0 ? (
                                  <Tooltip title="Article conforme">
                                    <IconButton aria-label="Article conforme">
                                      <DoneIcon />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <HtmlTooltip
                                    title={
                                      <React.Fragment>
                                        {value.map(v => v).join(" | ")}
                                      </React.Fragment>
                                    }
                                  >
                                    <IconButton aria-label="delete">
                                      <ErrorIcon />
                                    </IconButton>
                                  </HtmlTooltip>
                                )
                              ) : column.id === "ticketCode" ? (
                                <React.Fragment>
                                  {canEdit ? (
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={
                                        "https://code.mayan-payments.com:5000/f/c=" +
                                        value +
                                        "&h=MTAzZ&0"
                                      }
                                    >
                                      {value}
                                    </a>
                                  ) : (
                                    value
                                  )}

                                  <div>
                                    <Chip
                                      key="0"
                                      label={
                                        rows.find(
                                          r => r.ticketId === row.ticketId
                                        ).mobile
                                      }
                                      className={classes.chip}
                                    />
                                  </div>
                                </React.Fragment>
                              ) : column.id === "titleFormatted" &&
                                businessType === "sales" ? (
                                <React.Fragment>
                                  {parse(
                                    isMobile ? `${row.saleId}#${value}` : value
                                  )}
                                  {getCustomerSummary(row) ? (
                                    <div>
                                      <Chip
                                        key="0"
                                        label={getCustomerSummary(row)}
                                        className={classes.chip}
                                      />
                                    </div>
                                  ) : null}
                                </React.Fragment>
                              ) : column.id === "userMobile" &&
                                businessType === "customers" ? (
                                <React.Fragment>
                                  {parse(value)}
                                  {getCustomerEmail(row) ? (
                                    <div>
                                      <Chip
                                        key="0"
                                        label={getCustomerEmail(row)}
                                        className={classes.chip}
                                      />
                                    </div>
                                  ) : null}
                                </React.Fragment>
                              ) : column.id === "saleStatus" &&
                                businessType === "sales" ? (
                                <React.Fragment>
                                  <span
                                    className={classes[getStatusClass(value)]}
                                  >
                                    {parse(value)}
                                  </span>
                                  {isMobile ? (
                                    <div className={classes.saleDateMobile}>
                                      {getSaleCreationDate(row)}
                                    </div>
                                  ) : null}
                                </React.Fragment>
                              ) : column.id === "saleRefNumber" &&
                                businessType === "sales" ? (
                                <React.Fragment>
                                  <div>{value}</div>
                                  <Chip
                                    key="0"
                                    label={getParsedSaleNote(row)}
                                    className={classes.chipize2}
                                  />
                                </React.Fragment>
                              ) : column.id === "saleId" &&
                                businessType === "tickets" ? (
                                <NavLink to={`/sales/${value}`}>
                                  {value}
                                </NavLink>
                              ) : (
                                parse(value)
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[25, 50, 200]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </React.Fragment>
  );
}
