import React from "react";
import AdminSale from "../components/AdminSale";
import useAdminSale from "../hooks/useAdminSale";

const AdminSaleInterface = (props) => {
  const {
    superUser,
    saleId,
    saleCreated,
    productName,
    productType,
    onProductTypeChange,
    saleStatus,
    onStatusChange,
    saleNote,
    onNoteChange,
    productId,
    onProductIdChange,
    saleCurrency,
    onCurrencyChange,
    saleAmount,
    onAmountChange,
    saleNb,
    onNbChange,
    saleRefNumber,
    onRefnumberChange,
    salePaymentMethod,
    onPaymentMethodChange,
    saleNotif,
    onSaleNotifChange,
    saleUppTransactionNumber,
    onChangeSaleUppTransactionNumber,
    saleReferer,
    onRefererChange,
    submitting,
    handlePostItem,
    onProductNameChange,
  } = useAdminSale(props);

  const { onCancelAction, canEdit } = props;

  return (
    <AdminSale
      canEdit={canEdit}
      superUser={superUser}
      saleId={saleId}
      created={saleCreated}
      productName={productName}
      productType={productType}
      onProductTypeChange={onProductTypeChange}
      status={saleStatus}
      onStatusChange={onStatusChange}
      saleNote={saleNote}
      onNoteChange={onNoteChange}
      productId={productId}
      onProductIdChange={onProductIdChange}
      currency={saleCurrency}
      onCurrencyChange={onCurrencyChange}
      amount={saleAmount}
      onAmountChange={onAmountChange}
      saleNb={saleNb}
      onNbChange={onNbChange}
      refnumber={saleRefNumber}
      onRefnumberChange={onRefnumberChange}
      paymentMethod={salePaymentMethod}
      onPaymentMethodChange={onPaymentMethodChange}
      saleNotif={saleNotif}
      onSaleNotifChange={onSaleNotifChange}
      saleUppTransactionNumber={saleUppTransactionNumber}
      onChangeSaleUppTransactionNumber={onChangeSaleUppTransactionNumber}
      referer={saleReferer}
      onRefererChange={onRefererChange}
      submitting={submitting}
      handlePostItem={handlePostItem}
      onProductNameChange={onProductNameChange}
      onCancelAction={onCancelAction}
    />
  );
};

export default AdminSaleInterface;
