import React from "react";

import useAdminCustomers from "../hooks/useAdminCustomers";
import AdminCustomers from "../components/AdminCustomers";

const AdminCustomersInterface = function (props) {
  const { onEdit, businessType } = props;
  const {
    customers,
    superUser,
    onDelete,
    onRefreshData,
    apiError,
  } = useAdminCustomers();
  return (
    <AdminCustomers
      businessType={businessType}
      superUser={superUser}
      customers={customers}
      onEdit={onEdit}
      onDelete={onDelete}
      apiError={apiError}
      onRefreshData={onRefreshData}
    />
  );
};

export default AdminCustomersInterface;
