import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";

const useStyles = makeStyles({
  root: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400,
  },
});

export default function Treeview(props) {
  const classes = useStyles();
  const { categories = [], onSelectedNode, expanded } = props;

  const handleSelect = (event, nodeIds) => {
    onSelectedNode && onSelectedNode(nodeIds);
  };

  const loopAndDisplayNode = (node) => {
    return (
      <TreeItem
        key={node.id}
        nodeId={`${node.id}`}
        label={`${node.id}-${node.label}`}
      >
        {(node.children || []).length > 0
          ? node.children.map((n) => loopAndDisplayNode(n))
          : null}
      </TreeItem>
    );
  };

  return (
    <TreeView
      expanded={expanded}
      onNodeSelect={handleSelect}
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {loopAndDisplayNode(categories)}
    </TreeView>
  );
}
