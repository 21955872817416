const fetchAllSales = (sales) => {
  return {
    type: "FETCH_ALL_SALES",
    sales,
  };
};

export default {
  fetchAllSales,
};
