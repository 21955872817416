import React from "react";

import useAdminPickAndSelectMenu from "../hooks/useAdminPickAndSelectMenu";
import AdminPickAndSelectMenu from "../components/AdminPickAndSelectMenu";

export default function AdminPickAndSelectMenuInterface(props) {
  const { onCancelAction, merchants, menus, onPostSave } = props;

  const {
    menu,
    onChangeMultiple,
    onChange,
    disabledButton,
    onSubmit,
    submitting,
  } = useAdminPickAndSelectMenu({ merchants, onPostSave, menus });

  return (
    <AdminPickAndSelectMenu
      menus={menus}
      menu={menu}
      merchants={merchants}
      onCancelAction={onCancelAction}
      disabledButton={disabledButton}
      onChangeMultiple={onChangeMultiple}
      onChange={onChange}
      onSubmit={onSubmit}
      submitting={submitting}
    />
  );
}
