import React, { useState } from "react";

import { Auth } from "aws-amplify";

import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";

import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
  root: {},
  header: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  container: {
    margin: "0 auto",
    background: "#fcfcfc",
    maxWidth: 460,
    width: "100%",
    marginTop: 120,
  },
  actions: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: 0,
    padding: "10px 0",
  },
  error: {
    color: "red",
  },
}));

const AuthVerify = function (props) {
  const classes = styles();
  const { username, destination } = props;

  const [code, setCode] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState([]);

  const handleVerify = async () => {
    setError([]);
    setSubmitting(true);
    try {
      await Auth.confirmSignUp(username, code);
      window.location.href = "/login";
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      setError([error.message || error.code]);
    }
  };

  const onCodeChange = (e) => {
    setCode(e.target.value);
  };

  return (
    <div className={classes.root}>
      <Card className={classes.container}>
        <CardHeader
          className={classes.header}
          title="Validation du compte"
          // subheader={`Le code de vérification à été envoyé à l'adresse ${destination}.`}
        />
        <CardContent>
          <typography>{`Le code de vérification à été envoyé à l'adresse ${destination}.`}</typography>
          <form noValidate autoComplete="off" className={classes.root}>
            <FormControl fullWidth>
              <TextField
                label="code vérification"
                margin="normal"
                placeholder="Saisissez le code de vérification"
                onChange={onCodeChange}
                variant="outlined"
                value={code}
              />
            </FormControl>
          </form>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button
            variant="contained"
            disabled={submitting || code === ""}
            onClick={handleVerify}
          >
            {submitting ? "processing ..." : "Envoyer"}
          </Button>
        </CardActions>
        {error.length > 0
          ? error.map((e, key) => (
              <Typography className={classes.error} key={key}>
                {e}
              </Typography>
            ))
          : null}
      </Card>
    </div>
  );
};

export default AuthVerify;
