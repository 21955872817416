import React from "react";

import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles/";
import AppRoot from "./components/layout/AppRoot";

let theme = createTheme({
  palette: {
    primary: { main: "#272535" },
    // secondary: { main: "#636efd" },
    secondary: { main: "#e3e3e3" },
  },
  status: {
    danger: "orange",
  },
  typography: {
    fontSize: 11,
    body2: {
      fontSize: "14px",
      fontWeight: "400",
      textAlign: "justify",
    },
  },
});

theme = responsiveFontSizes(theme);

function App() {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <AppRoot />
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
