const initialState = [];

const sales = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ALL_SALES":
      state = action.sales;
      return state;
    default:
      return state;
  }
};

export default sales;
