import React from "react";

import useAdminListItems from "../hooks/useAdminListItems";
import AdminItems from "../components/AdminItems";

export default function AdminListItemsInterface(props) {
  const { onEdit, onEdit2 } = props;
  const {
    items,
    merchants,
    menu,
    superUser,
    onDelete,
    onRefreshData,
    apiError,
  } = useAdminListItems();

  return (
    <AdminItems
      superUser={superUser}
      items={items}
      merchants={merchants}
      menu={menu}
      onDelete={onDelete}
      apiError={apiError}
      onEdit={onEdit}
      onRefreshData={onRefreshData}
      onEdit2={onEdit2}
    />
  );
}
