import React from "react";
import { render } from "react-dom";
// import { StrictMode } from 'react';

import "./index.css";

import { createStore } from "redux";
import { Provider } from "react-redux";
import App from "./App";
// import rootReducer from "./state/items/reducers/index";
import rootReducer from "./state/index";

import * as serviceWorker from "./serviceWorker";

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

// store.subscribe(() => console.log(store.getState()));

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
