import React from "react";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";

import {
  getSaleStatus,
  isValidJSON,
  getProductType,
  parseSaleNote,
} from "./utils/applibs";
import ReactJson from "react-json-view";

const useStyles = makeStyles({
  root: {
    // marginTop: 0,
    display: "flex",
    flexWrap: "wrap",
  },
  marginRight: {
    marginRight: 10,
  },
  fullWidth: { width: "100%" },
  grid: {
    padding: 10,
  },
  actions: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: 40,
    padding: "10px 0",
  },
});

const AdminSale = (props) => {
  const {
    canEdit,
    saleId,
    created,
    productName,
    onProductNameChange,
    productType,
    onProductTypeChange,
    status,
    onStatusChange,
    saleNote,
    onNoteChange,
    productId,
    onProductIdChange,
    currency,
    onCurrencyChange,
    amount,
    onAmountChange,
    saleNb,
    onNbChange,
    refnumber,
    onRefnumberChange,
    paymentMethod,
    onPaymentMethodChange,
    saleUppTransactionNumber,
    onChangeSaleUppTransactionNumber,
    saleNotif,
    onSaleNotifChange,
    referer,
    onRefererChange,
    submitting,
    handlePostItem,
    onCancelAction,
  } = props;

  const classes = useStyles();

  const saleNotifJSON = isValidJSON(saleNotif)
    ? JSON.parse(saleNotif || "{}")
    : saleNotif;

  const paymentMethods = [
    { key: 3, value: "STRIPE" },
    { key: 1, value: "DATATRANS" },
  ];

  const paymentMethodStr =
    (paymentMethods.find((p) => p.key === parseInt(paymentMethod, 10)) || {})
      .value || "n/a";

  return (
    <React.Fragment>
      <div className={classes.root}>
        <form noValidate autoComplete="off">
          <Grid container spacing={3}>
            <Grid md={6} item xs={12}>
              <Paper className={classes.grid} spacing={3}>
                <TextField
                  className={classes.marginRight}
                  label="Id Vente"
                  margin="normal"
                  variant="outlined"
                  value={saleId}
                />
                <TextField
                  className={classes.marginRight}
                  label="Id Article"
                  margin="normal"
                  variant="outlined"
                  value={productId}
                  onChange={onProductIdChange}
                />
                <FormControl fullWidth>
                  <TextField
                    label="Produit"
                    margin="normal"
                    variant="outlined"
                    value={productName}
                    onChange={onProductNameChange}
                  />
                </FormControl>
                <div className={classes.fullWidth}>
                  <TextField
                    className={classes.marginRight}
                    label="Unité"
                    margin="normal"
                    variant="outlined"
                    value={saleNb}
                    onChange={onNbChange}
                  />
                  <TextField
                    className={classes.marginRight}
                    label="Montant"
                    margin="normal"
                    variant="outlined"
                    value={amount}
                    onChange={onAmountChange}
                  />
                  <TextField
                    className={classes.marginRight}
                    label="Devise"
                    margin="normal"
                    variant="outlined"
                    value={currency}
                    onChange={onCurrencyChange}
                  // disabled={true}
                  />
                  <TextField
                    label="Methode de paiement"
                    margin="normal"
                    variant="outlined"
                    value={paymentMethodStr}
                    onChange={onPaymentMethodChange}
                  // disabled={true}
                  />
                  <FormControl fullWidth>
                    <TextField
                      label="Transaction 3rd party (saleUppTransactionNumber)"
                      margin="normal"
                      variant="outlined"
                      value={saleUppTransactionNumber}
                      onChange={onChangeSaleUppTransactionNumber}
                    // disabled={true}
                    />
                  </FormControl>
                </div>
              </Paper>
            </Grid>
            <Grid md={6} item xs={12}>
              <Paper className={classes.grid} spacing={3}>
                {/* <div className={classes.fullWidth}> */}
                <TextField
                  fullWidth
                  className={classes.marginRight}
                  label="Transaction interne"
                  margin="normal"
                  variant="outlined"
                  value={refnumber}
                  onChange={onRefnumberChange}
                // disabled={true}
                />
                <TextField
                  className={classes.marginRight}
                  label="Crée le"
                  margin="normal"
                  variant="outlined"
                  value={created}
                  disabled={true}
                />

                <TextField
                  className={classes.marginRight}
                  label="Type de produit"
                  margin="normal"
                  onChange={onProductTypeChange}
                  variant="outlined"
                  value={getProductType(productType)}
                />
                <TextField
                  className={classes.marginRight}
                  label="Status"
                  margin="normal"
                  onChange={onStatusChange}
                  variant="outlined"
                  value={getSaleStatus(status)}
                />
                <TextField
                  className={classes.marginRight}
                  label="Information"
                  margin="normal"
                  onChange={onNoteChange}
                  variant="outlined"
                  value={parseSaleNote(saleNote)}
                />
                {/* </div> */}
                <FormControl fullWidth>
                  <TextField
                    label="Plateforme"
                    margin="normal"
                    variant="outlined"
                    value={referer}
                    onChange={onRefererChange}
                  />
                </FormControl>
              </Paper>
            </Grid>
            {canEdit ? (
              <Grid md={12} item xs={12}>
                <Paper className={classes.grid} spacing={3}>
                  <FormControl fullWidth>
                    {saleNotifJSON.payment_intent || saleNotifJSON.livemode ? (
                      <ReactJson collapsed="true" src={saleNotifJSON} />
                    ) : (
                      <TextField
                        label="Notif 3rd-party"
                        margin="normal"
                        variant="outlined"
                        value={JSON.stringify(saleNotifJSON)}
                        onChange={onSaleNotifChange}
                      />
                    )}
                  </FormControl>
                </Paper>
              </Grid>
            ) : null}
          </Grid>

          <div className={classes.actions}>
            {canEdit ? (
              <Button
                variant="contained"
                disabled={submitting}
                onClick={handlePostItem}
              >
                {submitting ? "processing ..." : "Sauver"}
              </Button>
            ) : null}

            <Button
              variant="contained"
              disabled={submitting}
              onClick={onCancelAction}
            >
              Annuler
            </Button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default AdminSale;
