import React from "react";
import { Button, Chip, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    margin: "10px auto",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  chip: {
    margin: "4px auto",
    padding: 2,
  },
  actions: {
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: 40,
    padding: "10px 0",
  },
}));

export default function AdminPickAndSelectMenuTree(props) {
  const { menuParsedAsTag, selected, onChange, onCancelAction } = props;
  const classes = useStyles();

  const color = (key) => (selected.indexOf(key) !== -1 ? "primary" : "default");

  return (
    <React.Fragment>
      <Typography variant="h5">
        Sélectionner les catégories de menu pour le(s) article(s)
      </Typography>
      <div className={classes.chips}>
        {menuParsedAsTag.map((m) => (
          <Chip
            className={classes.chip}
            key={m.key}
            label={`${m.key}#${m.label}`}
            color={color(m.key)}
            onClick={() => {
              onChange(m.key);
            }}
          />
        ))}
      </div>
      <div className={classes.actions}>
        <Button variant="contained" onClick={onCancelAction}>
          Fermer
        </Button>
      </div>
    </React.Fragment>
  );
}
