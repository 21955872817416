import React, { useState, useEffect } from "react";

import Amplify, { Auth } from "aws-amplify";
import configAmplify from "./../../config-amplify";

import AdminAuth from "./AdminAuth";
import MainApp from "./MainApp";
// import useAppContent from "../../hooks/useAppContent";

// AppContent is responsible to render conditionnaly the auth or non-auth component
// Based on the Amplify Authentification SDK

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: configAmplify.cognito.REGION,
    userPoolId: configAmplify.cognito.USER_POOL_ID,
    identityPoolId: configAmplify.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: configAmplify.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: configAmplify.s3.REGION,
    bucket: configAmplify.s3.BUCKET,
    identityPoolId: configAmplify.cognito.IDENTITY_POOL_ID,
  },
});

const AppContent = function (props) {
  const [user, setUser] = useState(null);
  // const { onFetchMerchants } = useAppContent();

  const fetchCurrentUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      if (user) {
        setUser(user);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCurrentUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, ["user"]);

  return <React.Fragment>{user ? <MainApp /> : <AdminAuth />}</React.Fragment>;
};

export default AppContent;
