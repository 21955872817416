import React from "react";

import StickyHeadTable from "./StickyHeadTable";
import { makeStyles } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";

var dateFormat = require("dateformat");

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 30,
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(95, 85, 87, 0.3)",
    padding: "20px 30px",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  tableCommands: {
    margin: "16px auto",
  },
  panelTableSelection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  selection: {
    display: "inline-block",
    marginLeft: 20,
  },
}));

const AdminPayments = function (props) {
  const classes = useStyles();
  const { payments: items, onDelete, onEdit, onRefreshData } = props;
  const [itemsForSelection, setItemsForSelection] = React.useState([]);

  const handleItemsForSelectionChange = (items) => {
    setItemsForSelection(items);
  };

  const displayedColumns = [
    { id: "id", label: "#", minWidth: 50, align: "left" },
    { id: "name", label: "Institution", minWidth: 170 },
    { id: "owner", label: "Propriétaire", minWidth: 170 },
    { id: "created", label: "Ajouté le", minWidth: 60 },
    { id: "action", label: "Actions", minWidth: isMobile ? 90 : 120 },
  ];

  return (
    <div className={classes.root}>
      <div>
        Enregistrements: ({(items && items.length) || 0})
        <span className={classes.selection}>
          {" "}
          {itemsForSelection.length === 0
            ? `Aucune sélection`
            : itemsForSelection.length === 1
            ? `${itemsForSelection.length} sélectionné`
            : `${itemsForSelection.length} sélectionnés`}
        </span>
      </div>

      <StickyHeadTable
        filters={["created"]}
        columns={
          isMobile
            ? displayedColumns.filter(
                (c) => c.id === "name" || c.id === "action"
              )
            : displayedColumns
        }
        data={
          (items &&
            items.map((i) => {
              return {
                ...i,
                ...{ created: dateFormat(i.created, "mmm dS, H:MM") },
              };
            })) ||
          []
        }
        onDelete={onDelete}
        onEdit={onEdit}
        onItemsForSelectionChange={handleItemsForSelectionChange}
        onRefreshData={onRefreshData}
        checkboxEnabled={true}
      />
    </div>
  );
};

export default AdminPayments;
