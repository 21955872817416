import React from "react";
import useAdminProfile from "../hooks/useAdminProfile";
import AdminProfile from "../components/AdminProfile";

const AdminProfileInterface = (props) => {
  const { defaultParams } = useAdminProfile(props);
  return <AdminProfile defaultParams={defaultParams} />;
};

export default AdminProfileInterface;
