import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    marginBottom: 20,
  },
}));

export default function CommonDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { onClose, open, title } = props;
  const classes = useStyles();
  const maxWidth = props.maxWidth || "sm";

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      // fullWidth={true}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      onClose={handleClose}
      aria-labelledby="common-dialog-title"
      open={open}
    >
      <DialogTitle className={classes.title} id="common-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>{props.render(props)}</DialogContent>
    </Dialog>
  );
}

CommonDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  // maxWidth: PropTypes.string.isRequired,
};
