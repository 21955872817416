import React from "react";
import Snackbar from "@material-ui/core/Snackbar";

export default function CustomSnackbar(props) {
  const [state] = React.useState({
    vertical: "top",
    horizontal: "center",
  });
  const { text } = props;

  const { vertical, horizontal } = state;
  const open = props.open || false;

  const handleClose = () => {
    props.onCloseSnakbar && props.onCloseSnakbar();
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        key={`${vertical},${horizontal}`}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{text}</span>}
      />
    </div>
  );
}
