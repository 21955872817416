import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  TextField,
  Button,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function AddPrice(props) {
  const { price, currency, onPriceChange, onCurrencyChange, onSave } = props;

  const classes = useStyles();

  return (
    <div>
      <FormControl fullWidth>
        <TextField
          label="Prix"
          margin="normal"
          onChange={onPriceChange}
          variant="outlined"
          value={price}
        />
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">Devise</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={currency}
            onChange={onCurrencyChange}
            label="Devise"
          >
            <MenuItem value={`chf`}>CHF</MenuItem>
            <MenuItem value={`eur`}>EUR</MenuItem>
          </Select>
        </FormControl>

        <Button variant="contained" onClick={onSave}>
          Ajouter
        </Button>
      </FormControl>
    </div>
  );
}
