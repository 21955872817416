import React from "react";
import StickyHeadTable from "./StickyHeadTable";
import { makeStyles } from "@material-ui/core/styles";

import { isMobile } from "react-device-detect";

var dateFormat = require("dateformat");

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 30,
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(95, 85, 87, 0.3)",
    padding: "20px 30px",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  tableCommands: {
    margin: "16px auto",
  },
  selection: {
    display: "inline-block",
    marginLeft: 20,
  },
}));

const AdminCategories = (props) => {
  const classes = useStyles();
  const { superUser, categories, onDelete, onEdit, onRefreshData } = props;
  const [categoriesForSelection, setCategoriesForSelection] = React.useState(
    []
  );
  const handleCategoriesForSelectionChange = (categories) => {
    setCategoriesForSelection(categories);
  };

  const formattedCategories = (categories || []).map((i) => ({
    ...i,
    ...{ created: dateFormat(i.created, "mmm dS, H:MM") },
  }));

  const displayedColumns = [
    { id: "id", label: "#", minWidth: 50, align: "left" },
    { id: "title", label: "Catégorie", minWidth: 170 },
    { id: "created", label: "Crée le", minWidth: 60 },
    { id: "action", label: "Actions", minWidth: isMobile ? 90 : 120 },
  ];

  return (
    <div className={classes.root}>
      <div>
        Enregistrements: ({(categories && categories.length) || 0})
        <span className={classes.selection}>
          {" "}
          {categoriesForSelection.length === 0
            ? `Aucune sélection`
            : categoriesForSelection.length === 1
            ? `${categoriesForSelection.length} sélectionné`
            : `${categoriesForSelection.length} sélectionnés`}
        </span>
      </div>

      <StickyHeadTable
        superUser={superUser}
        filters={["id", "title", "description", "published", "platforms"]}
        columns={
          isMobile
            ? displayedColumns.filter(
                (c) => c.id === "title" || c.id === "action"
              )
            : displayedColumns
        }
        data={formattedCategories}
        onDelete={onDelete}
        onEdit={onEdit}
        onCategoriesForSelectionChange={handleCategoriesForSelectionChange}
        onRefreshData={onRefreshData}
        checkboxEnabled={true}
      />
    </div>
  );
};

export default AdminCategories;
