import { useState, useEffect } from "react";
import useAPI from "../hooks/useAPI";
import { useDispatch } from "react-redux";
import allActionsCat from "../state/categories/actions/index";

export default function useAdminCategory(props) {
  const dispatch = useDispatch();
  const localUser = JSON.parse(localStorage.getItem("kdmUser"));
  const superUser = localUser.sub === process.env.REACT_APP_ADMIN_UID;

  const { currentItem, onSave, callback } = props;

  const [title, setTitle] = useState(
    !!currentItem && currentItem.title ? currentItem.title : ""
  );

  const [description, setDescription] = useState(
    !!currentItem && currentItem.description ? currentItem.description : ""
  );

  const [graph, setGraph] = useState(
    !!currentItem && currentItem.graph
      ? currentItem.graph
      : { id: 0, label: "Racine" }
  );

  const [submitting, setSubmitting] = useState(false);

  const { data, query: fetchData } = useAPI({
    type: "get",
    url: `categories`,
    defer: true,
  });

  const { query: postCategory } = useAPI({
    defer: true,
    type: "post",
    url: "categories",
    body: {
      uid: btoa(localUser.sub),
      title,
      description,
      graph,
    },
  });

  const { query: updateCategory } = useAPI({
    defer: true,
    type: "post",
    url: `categories`,
    body: {
      id: !!currentItem && currentItem.id ? currentItem.id : 0,
      uid: btoa(localUser.sub),
      value: "put",
      title,
      description,
      graph,
    },
  });

  const handlePostCategory = async () => {
    setSubmitting(true);
    try {
      if (!!currentItem && currentItem.id) {
        await updateCategory();
      } else {
        await postCategory();
      }
      await fetchData();
      setSubmitting(false);
      onSave({ success: true, message: "Menu sauvée avec succcès" });
      callback && callback();
    } catch (err) {
      console.log(err);
      onSave({ success: false, message: "Erreur à la sauvegarde du menu" });
      setSubmitting(false);
    }
  };

  const handleTitleChange = (e) => {
    const { value } = e.target;
    if (value.length > 50) {
      return;
    }
    setTitle(value);
  };

  const handleDescriptionChange = (e) => {
    const { value } = e.target;
    setDescription(value);
  };

  const handleGraphChange = (gaph) => {
    setGraph(gaph);
  };

  const handleNodeSave = async () => {
    handlePostCategory();
  };

  useEffect(() => {
    if (data && data.categories) {
      dispatch(allActionsCat.categoryActions.fetchAllCat(data.categories));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    title,
    description,
    graph,
    submitting,
    superUser,
    handlePostCategory,
    onTitleChange: handleTitleChange,
    onDescriptionChange: handleDescriptionChange,
    onGraphChange: handleGraphChange,
    onNodeSave: handleNodeSave,
  };
}
