import { useState, useEffect } from "react";

import useAPI from "../hooks/useAPI";

import { useDispatch } from "react-redux";
import allActions from "../state/items/actions/index";

export default function useAdminPickAndSelectSite(props) {
  const dispatch = useDispatch();
  const localUser = JSON.parse(localStorage.getItem("kdmUser"));

  const [submitting, setSubmitting] = useState(false);
  const { items, merchants: platforms, onPostSave } = props;
  const [platform, setPlatform] = useState([]);
  const [disabledButton, setDisableButton] = useState(true);

  const { data, query: fetchData } = useAPI({
    type: "get",
    url: `items`,
    defer: true,
  });

  const { query: updateSites } = useAPI({
    defer: true,
    type: "post",
    url: `items`,
    body: { items: items, platforms: platform },
  });

  const handleSubmit = async () => {
    try {
      // setApiError(false);
      setSubmitting(true);
      await updateSites();
      await fetchData();
      onPostSave({ success: true, message: "Sauvegardé avec succès." });
    } catch (err) {
      onPostSave({
        success: false,
        message: "Une erreur est survenue ä la sauvegarde.",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleToggle = (event) => {
    let platformUpdated = [];
    if (event.target.checked) {
      const platformToAdd = platforms.find((p) => p.id === event.target.name);
      platformUpdated = platform.concat([platformToAdd]);
    } else {
      platformUpdated = platform.filter((p) => p.id !== event.target.name);
    }
    setPlatform(platformUpdated);
    return setDisableButton(platformUpdated.length === 0);
  };

  useEffect(() => {
    if (data && data.items) {
      dispatch(allActions.itemActions.fetchAll(data.items));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    platform,
    onToggle: handleToggle,
    onSubmit: handleSubmit,
    disabledButton,
    submitting,
  };
}
