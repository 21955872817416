const initialState = [];

const users = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ALL_USERS":
      state = action.users;
      return state;
    default:
      return state;
  }
};

export default users;
