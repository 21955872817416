import React from "react";
import AddPrice from "../components/AddPrice";
import useAddPrice from "../hooks/useAddPrice";

export default function AddPriceInterface(props) {
  const {
    price,
    currency,
    onPriceChange,
    onCurrencyChange,
    onSave,
  } = useAddPrice(props);

  return (
    <AddPrice
      price={price}
      currency={currency}
      onPriceChange={onPriceChange}
      onCurrencyChange={onCurrencyChange}
      onSave={onSave}
    />
  );
}
