const initialState = [];

const items = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ALL":
      state = action.items || [];
      return state;
    case "SET_USER":
      localStorage.setItem("kdmUser", JSON.stringify(action.user));
      //TODO: Change structure of REDUX Store
      return state;
    case "CLEAR_USER":
      localStorage.clear();
      break;
    default:
      return state;
  }
};

export default items;
