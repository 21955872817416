const initialState = [];

const merchants = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ALL_MERCHANTS":
      state = action.merchants || [];
      return state;
    case "FETCH_ALL_MERCHANTS_ADMIN":
      state = action.merchants;
      return state;
    default:
      return state;
  }
};

export default merchants;
