import React, { useState } from "react";

import { Auth } from "aws-amplify";

import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";

import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles({
  root: {},
  container: {
    margin: "0 auto",
    background: "#fcfcfc",
    maxWidth: 460,
    width: "100%",
    marginTop: 120,
    borderRadius: "0px",
  },
  actions: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: 0,
    padding: "10px 0",
  },
  resend: {
    display: "fley",
    flexDirection: "row",
    width: "100%",
  },
});

const AuthRePassword = function (props) {
  const classes = styles();
  const { username, destination } = props;

  const [newPassword, setNewPassword] = useState("");
  const [code, setCode] = useState("");
  const [codeExipred, setCodeExipred] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState([]);

  const handlePasswordSubmit = async () => {
    setError([]);
    setSubmitting(true);
    try {
      await Auth.forgotPasswordSubmit(username, code, newPassword);
      setSubmitting(false);
      window.location.href = "/login";
    } catch (error) {
      setSubmitting(false);
      if (error.code.match(/ExpiredCodeException/)) {
        setCodeExipred(true);
      }
      setError([error.message || error.code]);
    }
  };

  const handleResendCode = async () => {
    setError([]);
    try {
      setSubmitting(true);
      await Auth.forgotPassword(username);
      setCodeExipred(false);
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      setError([error.message || error.code]);
    }
  };

  const onCodeChange = (e) => {
    setCode(e.target.value);
  };

  const onNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  return (
    <div className={classes.root}>
      <Card className={classes.container}>
        <CardHeader
          title="Kadomi.Shop • Validation du compte"
          subheader={`Le code de vérification à été envoyé à l'adresse ${destination}.`}
          disableTypography={true}
        />

        <CardContent>
          {codeExipred ? (
            <div className={classes.resend}>
              <Typography>Le code a expiré.</Typography>
              <Button variant="contained" onClick={handleResendCode}>
                Renvoyer
              </Button>
            </div>
          ) : null}
          <form noValidate autoComplete="off" className={classes.root}>
            <FormControl fullWidth>
              <TextField
                label="Code"
                margin="normal"
                placeholder="Code de vérification"
                onChange={onCodeChange}
                variant="outlined"
                value={code}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                label="Nouveau mot de passe"
                margin="normal"
                placeholder="Veuillez saisir votre mot de passe"
                onChange={onNewPasswordChange}
                variant="outlined"
                value={newPassword}
                type="password"
              />
              <Typography>
                Le mot de passe doit comporter au moins 8 caractères et composé
                de minuscule, majuscule et chiffre.
              </Typography>
            </FormControl>
          </form>
        </CardContent>

        <CardActions className={classes.actions}>
          <Button
            variant="contained"
            disabled={
              submitting || newPassword === "" || code === "" || codeExipred
            }
            onClick={handlePasswordSubmit}
          >
            {submitting ? "processing ..." : "Envoyer"}
          </Button>
        </CardActions>
        {error.length > 0
          ? error.map((e, key) => (
              <Typography className={classes.error} key={key}>
                {e}
              </Typography>
            ))
          : null}
      </Card>
    </div>
  );
};

export default AuthRePassword;
