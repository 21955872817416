import React from "react";
// import useItemsEnumerator from "../hooks/useItemsEnumerator";
import ItemsEnumerator from "../components/ItemsEnumerator";

export default function ItemsEnumeratorInterface(props) {
  const { values, title, type, canDelete, onChange, onDelete } = props;

  return (
    <ItemsEnumerator
      values={values}
      title={title}
      type={type}
      canDelete={canDelete}
      onChange={onChange}
      onDelete={onDelete}
    />
  );
}
