import React from "react";

import useAdminItem from "../hooks/useAdminItem";
import AdminItem from "../components/AdminItem";

export default function AdminItemInterface(props) {
  const {
    title,
    description,
    tags,
    tagsAsMenu,
    prices,
    nb,
    available,
    busy,
    files,
    filesUploaded,
    dimensions,
    platforms,
    submitting,
    currentItem,
    published,
    dialogPricing,
    superUser,
    eventPlanner,
    isEvent,
    requiredFormular,
    dataMenus,
    handlePostItem,
    onTitleChange,
    onDescriptionChange,
    onTagsChange,
    onTagsAsMenuChange,
    onNbItemChange,
    onFileChange,
    onChangeDimension,
    onDeleteDimension,
    onAddDimension,
    onStatusPublishedChange,
    openDialogPricing,
    onDialogClosePricing,
    onSavePrice,
    onDeleteSavedPrice,
    onEventPlanner,
    onSetEvent,
    onSetFormular,
    onSaveMenuForItemRefresh,
    onHandleDeleteFile,
  } = useAdminItem(props);

  const { onCancelAction } = props;

  return (
    <AdminItem
      superUser={superUser}
      title={title}
      description={description}
      tags={tags}
      tagsAsMenu={tagsAsMenu}
      prices={prices}
      nb={nb}
      available={available}
      busy={busy}
      files={files}
      filesUploaded={filesUploaded}
      dimensions={dimensions}
      platforms={platforms}
      published={published}
      eventPlanner={eventPlanner}
      isEvent={isEvent}
      requiredFormular={requiredFormular}
      dataMenus={dataMenus}
      handlePostItem={handlePostItem}
      onTitleChange={onTitleChange}
      onDescriptionChange={onDescriptionChange}
      onTagsChange={onTagsChange}
      onTagsAsMenuChange={onTagsAsMenuChange}
      onNbItemChange={onNbItemChange}
      onFileChange={onFileChange}
      submitting={submitting}
      currentItem={currentItem}
      dialogPricing={dialogPricing}
      onCancelAction={onCancelAction}
      onChangeDimension={onChangeDimension}
      onDeleteDimension={onDeleteDimension}
      onAddDimension={onAddDimension}
      onStatusPublishedChange={onStatusPublishedChange}
      openDialogPricing={openDialogPricing}
      onDialogClosePricing={onDialogClosePricing}
      onSavePrice={onSavePrice}
      onDeleteSavedPrice={onDeleteSavedPrice}
      onEventPlanner={onEventPlanner}
      onSetEvent={onSetEvent}
      onSetFormular={onSetFormular}
      onSaveMenuForItemRefresh={onSaveMenuForItemRefresh}
      onHandleDeleteFile={onHandleDeleteFile}
    />
  );
}
