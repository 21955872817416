import { useState, useEffect } from "react";
import axios from "axios";
import qs from "qs";

export default args => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);

  const basePath =
    process.env.REACT_APP_API_URL || "https://api.neo-payment.com/admin/";

  const { type, body, url, defer } = args || {};

  const localUser = JSON.parse(localStorage.getItem("kdmUser"));
  const userId = localUser.sub;

  const fetch = async args => {
    const overrideUrl = (args && args.url) || null;
    setLoading(true);

    await axios({
      method: type || "get",
      headers: {
        "content-type": "application/json",
        "x-api-key": "L__MVzTAeHv_XGb2CqBhbGSI5UAwBVeg",
        cgnusr: btoa(userId),
      },
      url: overrideUrl ? `${basePath}${overrideUrl}` : `${basePath}${url}`,
      data: body,
    })
      .then(response => {
        if (!type || type === "get") {
          if (response.data) setData(response.data);
        }
      })
      .catch(err => {
        setApiError(err);
        throw err;
      });
    setLoading(false);
  };

  useEffect(() => {
    if (!defer) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defer]);

  return {
    query: fetch,
    data,
    loading,
    apiError,
  };
};
