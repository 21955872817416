var slugify = require("slugify");

const SALE_STATUS = [
  { code: "0", label: "failed" },
  { code: "1", label: "success" },
  { code: "2", label: "cancelled" },
  { code: "4", label: "opened" },
  { code: "5", label: "pending" },
];

export function getPaymentMethodLabel(paymethod) {
  if (paymethod === "3") {
    return "STRIPE";
  }
  if (paymethod === "1") {
    return "DATATRANS";
  }
  return "UNKNOWN";
}

export function getSaleStatus(status) {
  const statusFound = SALE_STATUS.find((s) => s.code === status);

  if (!statusFound) {
    return `#error (${status})`;
  }
  return statusFound.label;
}

export function getProductType(type) {
  const productTypes = [
    { key: "1", value: "PRODUIT" },
    { key: "2", value: "SERVICE" },
    { key: "3", value: "TICKET" },
  ];

  const productType = productTypes.find((p) => p.key === type);

  if (!productType) {
    return `#error (${type})`;
  }
  return productType.value;
}

export function slugifier(value, forceLowerCase) {
  const valueParsed = value.replace(/'/g, "");
  return forceLowerCase
    ? slugify(valueParsed).toLowerCase()
    : slugify(valueParsed);
}

export function parsePartnerURL(locationPartner, shopPartner) {
  const locationPartnerParsed = locationPartner.replace(/'/g, "");
  const shopPartnerParsed = shopPartner.replace(/'/g, "");

  return `https://kadomi.ch/${slugify(
    locationPartnerParsed
  ).toLowerCase()}/boutique/${slugify(shopPartnerParsed).toLowerCase()}`;
}

export const extractIDsFromGraph = (ids = [], node) => {
  if (!node || !node.id) {
    return [];
  }
  ids.push(node.id.toString());
  if (node.children) {
    node.children.map((n) => extractIDsFromGraph(ids, n));
  }
  return ids;
};

export const parseMenuGraphAsTags = (dataMenus = []) => {
  const menuParsedAsTag = [];

  const loopNode = (menuId, node) => {
    if (node.label && !node.children) {
      menuParsedAsTag.push({ key: `${menuId}-${node.id}`, label: node.label });
      return menuParsedAsTag;
    }
    node.children.map((n) => loopNode(menuId, n));
  };

  dataMenus.map((menu) => loopNode(menu.id, menu.graph));
  return menuParsedAsTag;
};

export const isValidJSON = (jsonString = "") => {
  try {
    var o = JSON.parse(jsonString);
    if (o && typeof o === "object") {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const parseSaleNote = (saleNote) => {
  try {
    const parseNote = JSON.parse(saleNote);
    if (parseNote.infoSale) {
      return parseNote.infoSale;
    }

    if (Array.isArray(parseNote) && parseNote.length === 1) {
      return parseNote[0];
    }
    return ""; // means no format recognized
  } catch (e) {
    return "";
  }
};
