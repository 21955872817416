import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import useAPI from "../hooks/useAPI";

import { useDispatch } from "react-redux";
import allActions from "../state/users/actions/index";

export default function useAdminUsers() {
  const dispatch = useDispatch();
  const localUser = JSON.parse(localStorage.getItem("kdmUser"));
  const superUser = localUser.sub === process.env.REACT_APP_ADMIN_UID;

  const { data, query: fetchData } = useAPI({
    type: "get",
    url: `members`,
  });

  const users = useSelector((state) => {
    return state.users.users;
  });

  const [apiError, setApiError] = useState(false); //TODO:Placer dans useAPI

  const { query: deleteUser } = useAPI({
    defer: true,
    type: "delete",
    url: "user",
    body: {},
  });

  /**
   *
   * @param {string|array} id   @param id   ID(s) of users to delete
   */
  const handleDelete = async (id) => {
    try {
      setApiError(false);
      await deleteUser({
        url: `user/${id}`,
      });
      await fetchData();
    } catch (error) {
      throw error;
      // setApiError(true); // TODO: Voir si on garde ce truc, pas vraiment utile je crois
    }
  };

  const handleRefreshData = async () => {
    await fetchData();
  };

  useEffect(() => {
    if (data && data.users) {
      dispatch(allActions.usersActions.fetchAllUsers(data.users));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    users,
    superUser,
    onDelete: handleDelete,
    onRefreshData: handleRefreshData,
    apiError,
  };
}
