import React from "react";
import Container from "@material-ui/core/Container";
import AdminSidebar from "../layout/AdminSidebar";
import AppBar from "./AppBar";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";

import PropTypes from "prop-types";
import AdminManager from "../AdminManager";
import AdminListItemsInterface from "../../interfaces/AdminListItemsInterface";
import AdminItemInterface from "../../interfaces/AdminItemInterface";
import AdminMerchantInterface from "../../interfaces/AdminMerchantInterface";
import AdminMerchantsInterface from "../../interfaces/AdminMerchantsInterface";
import AdminCategoryInterface from "../../interfaces/AdminCategoryInterface";
import AdminCategoriesInterface from "../../interfaces/AdminCategoriesInterface";
import AdminSalesInterface from "../../interfaces/AdminSalesInterface";
import AdminSaleInterface from "../../interfaces/AdminSaleInterface";
import AdminPaymentInterface from "../../interfaces/AdminPaymentInterface";
import AdminPaymentsInterface from "../../interfaces/AdminPaymentsInterface";
import AdminCustomersInterface from "../../interfaces/AdminCustomersInterface";
import AdminTicketsInterface from "../../interfaces/AdminTicketsInterface";
import AdminUsersInterface from "../../interfaces/AdminUsersInterface";
import AdminHelperManager from "../AdminHelperManager";
import AdminDashboard from "../AdminDashboard";
import AdminProfileInterface from "../../interfaces/AdminProfileInterface";
import AdminLogout from "../AdminLogout";
import AdminManualTicketInterface from "../../interfaces/AdminManualTicketInterface";
import AdminTicketInterface from "../../interfaces/AdminTicketInterface";

const localUser = JSON.parse(localStorage.getItem("kdmUser"));
const superUser =
  localUser && localUser.sub === process.env.REACT_APP_ADMIN_UID;

const styles = (theme) => ({
  root: {
    "& > *": {},
    height: "100vh",
    overflow: "hidden",
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
  },

  container: {
    height: "100vh",
    overflow: "overlay",
    padding: "0 0 140px 0",
    [theme.breakpoints.up("lg")]: {
      maxWidth: "85%",
    },
  },

  link: {
    textDecoration: "none",
    color: "black",
  },
});

const AdminMenu = (props) => {
  const { classes } = props;

  return (
    <React.Fragment>
      <Router>
        <div className={classes.root}>
          <AppBar />
          <div className={classes.wrapper}>
            <AdminSidebar />
            <Container className={classes.container}>
              <Switch>
                <Route
                  path="/items"
                  render={() => (
                    <AdminManager
                      type="items"
                      labelAddItem="Ajouter un article"
                      breadcrumTitle="Gestion des articles"
                      EditComponent={AdminItemInterface}
                      ListComponent={AdminListItemsInterface}
                      CustomComponent={AdminManualTicketInterface}
                      labelCustomComponent={"Générer un ticket"}
                    />
                  )}
                />
                <Route
                  path="/merchants"
                  render={() => (
                    <AdminManager
                      type="merchants"
                      labelAddItem="Ajouter un point de vente"
                      breadcrumTitle="Gestion des marchants"
                      EditComponent={AdminMerchantInterface}
                      ListComponent={AdminMerchantsInterface}
                    />
                  )}
                />
                <Route
                  path="/categories"
                  render={() => (
                    <AdminManager
                      type="categories"
                      labelAddItem="Ajouter un menu"
                      breadcrumTitle="Gestion des menus"
                      EditComponent={AdminCategoryInterface}
                      ListComponent={AdminCategoriesInterface}
                    />
                  )}
                />
                <Route
                  path="/sales/:saleId?"
                  render={() => (
                    <AdminManager
                      type="sales"
                      labelAddItem="Ajouter une vente"
                      breadcrumTitle="Gestion des ventes"
                      EditComponent={AdminSaleInterface}
                      ListComponent={AdminSalesInterface}
                    />
                  )}
                />
                <Route
                  path="/payments"
                  render={() => (
                    <AdminManager
                      type="payments"
                      labelAddItem="Ajouter un paiement"
                      breadcrumTitle="Gestion des paiements"
                      EditComponent={AdminPaymentInterface}
                      ListComponent={AdminPaymentsInterface}
                    />
                  )}
                />
                <Route
                  path="/customers"
                  render={() => (
                    <AdminManager
                      type="customers"
                      labelAddItem="Ajouter un client"
                      breadcrumTitle="Gestion des clients"
                      EditComponent={null}
                      ListComponent={AdminCustomersInterface}
                    />
                  )}
                />
                <Route
                  path="/tickets/:ticketCode?"
                  render={() => (
                    <AdminManager
                      type="tickets"
                      labelAddItem="Ajouter un ticket"
                      breadcrumTitle="Gestion des tickets générés"
                      EditComponent={AdminTicketInterface}
                      ListComponent={AdminTicketsInterface}
                    />
                  )}
                />
                {superUser ? (
                  <Route
                    path="/users"
                    render={() => (
                      <AdminManager
                        type="users"
                        labelAddItem="Ajouter un utilisateur"
                        EditComponent={() => {}}
                        ListComponent={AdminUsersInterface}
                      />
                    )}
                  />
                ) : null}
                <Route path="/helper" component={AdminHelperManager} />
                <Route path="/profile" component={AdminProfileInterface} />
                <Route path="/logout" component={AdminLogout} />
                <Route path="/" component={AdminDashboard} />
              </Switch>
            </Container>
          </div>
          {/* <Footer /> */}
        </div>
      </Router>
    </React.Fragment>
  );
};

AdminMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminMenu);
