import React, { useState } from "react";

import Button from "@material-ui/core/Button";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {},
  img: {
    maxHeight: 120,
  },
});

const UploadImage = (props) => {
  const classes = useStyles();
  const { onFileChange } = props;

  const [previewImage, setPreviewImage] = useState(null);

  const handlePreviewImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      function() {
        setPreviewImage(reader.result);
      },
      false,
    );

    if (file) {
      reader.readAsDataURL(file);
      onFileChange(e);
    }
  };

  return (
    <React.Fragment>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="raised-button-file"
        multiple
        type="file"
        onChange={handlePreviewImage}
      />
      <label htmlFor="raised-button-file">
        <Button variant="contained" component="span">
          Ajouter une image
        </Button>
      </label>
      {previewImage && (
        <div>
          <img
            alt="any illustration"
            src={previewImage}
            className={classes.img}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default UploadImage;
