import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import { Typography } from "@material-ui/core";

import CommonStepper from "./utils/Stepper";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 30,
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(95, 85, 87, 0.3)",
    padding: "20px 30px",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  marginBottom: {
    marginBottom: 20,
  },
}));

const AdminHelper = function (props) {
  const classes = useStyles();
  const videos = [
    {
      key: 0,
      name: "video 1",
      src: "https://www.youtube.com/embed/-S-rGvmoC7g",
    },
    {
      key: 1,
      name: "video 2",
      src: "https://www.youtube.com/embed/Vmd_i1wrjVM",
    },
    {
      key: 2,
      name: "video 3",
      src: "https://www.youtube.com/embed/-S-rGvmoC7g",
    },
  ];

  return (
    <div className={classes.root}>
      <Typography className={classes.marginBottom} variant="h5">
        Votre boutique en trois étapes !
      </Typography>
      <Typography>
        Nous souhaitons tout mettre en oeuvre afin de votre expérience des plus
        agréables. Pour cela nous avons mis à disposition une série de petites
        vidéos afin de vous guider dans la création de votre boutique.
      </Typography>
      <CommonStepper videos={videos} />
    </div>
  );
};

export default AdminHelper;
