import React from "react";
import AdminMerchant from "../components/AdminMerchant";
import useAdminMerchant from "../hooks/useAdminMerchant";

const AdminMerchantInterface = (props) => {
  const {
    typeShop,
    paymethod,
    email,
    name,
    address,
    currency,
    currencies,
    hasPagination,
    lang,
    langs,
    apikey,
    container,
    referer,
    submitting,
    locationPartner,
    shopPartner,
    editMode,
    tags,
    categories,
    facebook,
    instagram,
    emailOwner,
    usershop,
    demo,
    partnerCategories,
    superUser,
    handlePostItem,
    onEmailChange,
    onNameChange,
    onAddressChange,
    onLangChange,
    onRefererChange,
    onCurrencyChange,
    onPaginationChange,
    onContainerChange,
    onChangeTypeShop,
    onChangePaymethod,
    onChangeLocationPartner,
    onChangeShopPartner,
    onTagsChange,
    onCategoriesChange,
    onChangeSocialInsta,
    onChangeSocialFB,
    onChangeUsershop,
    onEmailOwner,
    onChangeDemoSite,
  } = useAdminMerchant(props);

  return (
    <AdminMerchant
      superUser={superUser}
      typeShop={typeShop}
      paymethod={paymethod}
      email={email}
      name={name}
      address={address}
      currency={currency}
      currencies={currencies}
      hasPagination={hasPagination}
      lang={lang}
      langs={langs}
      apikey={apikey}
      container={container}
      referer={referer}
      submitting={submitting}
      locationPartner={locationPartner}
      shopPartner={shopPartner}
      editMode={editMode}
      tags={tags}
      categories={categories}
      instagram={instagram}
      facebook={facebook}
      emailOwner={emailOwner}
      usershop={usershop}
      demo={demo}
      partnerCategories={partnerCategories}
      handlePostItem={handlePostItem}
      onEmailChange={onEmailChange}
      onNameChange={onNameChange}
      onAddressChange={onAddressChange}
      onLangChange={onLangChange}
      onRefererChange={onRefererChange}
      onCurrencyChange={onCurrencyChange}
      onPaginationChange={onPaginationChange}
      onContainerChange={onContainerChange}
      onChangeTypeShop={onChangeTypeShop}
      onChangePaymethod={onChangePaymethod}
      onChangeLocationPartner={onChangeLocationPartner}
      onChangeShopPartner={onChangeShopPartner}
      onTagsChange={onTagsChange}
      onCategoriesChange={onCategoriesChange}
      onChangeSocialInsta={onChangeSocialInsta}
      onChangeSocialFB={onChangeSocialFB}
      onChangeUsershop={onChangeUsershop}
      onEmailOwner={onEmailOwner}
      onChangeDemoSite={onChangeDemoSite}
      onCancelAction={props.onCancelAction}
    />
  );
};

export default AdminMerchantInterface;
