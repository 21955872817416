import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import DatePicker from "../components/utils/DatePicker";
import DoneIcon from "@material-ui/icons/Done";
import ErrorIcon from "@material-ui/icons/Error";

const styles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
  },
  periodInvalid: {
    display: "flex",
    color: "#ab2525",
  },
});

export default function AdminDatePeriodManager(props) {
  const classes = styles();
  const { label, date, dateId, onEventPlanner } = props;

  const start = new Date(date.start).getTime() / 1000;
  const end = new Date(date.end).getTime() / 1000;
  const isPeriodValid = !isNaN(start) && !isNaN(end) ? end - start > 0 : false;
  const displayPeriodValidityFlag = !isNaN(start) && !isNaN(end);

  return (
    <React.Fragment>
      <Typography variant="h6">
        {label}{" "}
        {isPeriodValid ? (
          <DoneIcon />
        ) : displayPeriodValidityFlag ? (
          <div className={classes.periodInvalid}>
            <ErrorIcon /> <Typography>Période invalide</Typography>
          </div>
        ) : null}
      </Typography>
      <div className={classes.root}>
        <span>Du</span>{" "}
        <DatePicker
          date={date.start}
          dateId={dateId}
          moment="start"
          onEventPlanner={onEventPlanner}
        />
      </div>
      <div className={classes.root}>
        <span>Au</span>{" "}
        <DatePicker
          date={date.end}
          dateId={dateId}
          moment="end"
          onEventPlanner={onEventPlanner}
        />
      </div>
      <br />
    </React.Fragment>
  );
}
