import React from "react";

import useAdminUsers from "../hooks/useAdminUsers";
import AdminUsers from "../components/AdminUsers";

export default function AdminUsersInterface(props) {
  const { onEdit } = props;
  const {
    users,
    superUser,
    onDelete,
    onRefreshData,
    apiError,
  } = useAdminUsers();

  return (
    <AdminUsers
      superUser={superUser}
      users={users}
      onDelete={onDelete}
      apiError={apiError}
      onEdit={onEdit}
      onRefreshData={onRefreshData}
    />
  );
}
