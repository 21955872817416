import { parseMenuGraphAsTags } from "../components/utils/applibs";

export default function useAdminPickAndSelectMenuTree(props) {
  const { dataMenus } = props;

  const menuParsedAsTag = parseMenuGraphAsTags(dataMenus);

  return {
    menuParsedAsTag,
  };
}
