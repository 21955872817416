import React, { useState } from "react";

import { Auth } from "aws-amplify";

import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";

import { Link } from "react-router-dom";

import AuthVerify from "./AuthVerify";

const styles = makeStyles((theme) => ({
  root: {},
  header: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  container: {
    margin: "0 auto",
    background: "#fcfcfc",
    maxWidth: 460,
    width: "100%",
    marginTop: 120,
    borderRadius: "0px",
  },
  actions: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: 0,
    padding: "10px 0",
  },
  marginTop: {
    marginTop: 10,
  },
  link: {
    color: "#413c3c",
    padding: "8px 0px",
    textDecoration: "none",
    borderRadius: 5,
    fontWeight: 550,
  },
  error: {
    color: "red",
  },
}));

const AuthRegister = function (props) {
  const classes = styles();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [destination, setDestination] = useState("");
  const [registered, setRegistered] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState([]);

  const handleRegister = async () => {
    setError([]);
    setSubmitting(true);
    try {
      /**
       * response => '{"CodeDeliveryDetails":{"AttributeName":"email","DeliveryMedium":"EMAIL","Destination":"m***@f***.com"},"UserConfirmed":false,"UserSub":"00924bfa-c559-46b8-bf8b-5668730afe1a"}'
       */
      const userRegistered = await Auth.signUp({
        username,
        password,
      });
      setDestination(userRegistered.codeDeliveryDetails.Destination);
      setRegistered(true);
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      setError([error.message || error.code]);
    }
  };

  const onUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return registered ? (
    <AuthVerify username={username} destination={destination} />
  ) : (
    <div className={classes.root}>
      <Card className={classes.container}>
        <CardHeader
          className={classes.header}
          title="Kadomi.Shop • Créez votre compte"
          subheader=""
          disableTypography={true}
        />
        <CardContent>
          <form noValidate autoComplete="off" className={classes.root}>
            <FormControl fullWidth>
              <TextField
                label="E-mail *"
                margin="normal"
                placeholder="Saisissez votre nom d'utilisateur"
                onChange={onUsernameChange}
                variant="outlined"
                value={username}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                label="Mot de passe *"
                type="password"
                margin="normal"
                placeholder="Saisissez votre mot de passe"
                onChange={onPasswordChange}
                variant="outlined"
                value={password}
              />
            </FormControl>
          </form>
          <Typography className={classes.marginTop}>
            Mot de passe oublié ?{" "}
            <Link className={classes.link} to="/recover-password">
              Réinitialisez votre mot de passe
            </Link>
          </Typography>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button variant="contained" disabled={false} onClick={handleRegister}>
            {submitting ? "processing ..." : "Créer mon compte"}
          </Button>
        </CardActions>
        {error.length > 0
          ? error.map((e, key) => (
              <Typography className={classes.error} key={key}>
                {e}
              </Typography>
            ))
          : null}
      </Card>
    </div>
  );
};

export default AuthRegister;
