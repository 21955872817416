import React, { useState } from "react";
import { useDispatch } from "react-redux";
import allActions from "./../state/items/actions/index";

import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";

import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import { Typography } from "@material-ui/core";

import { Auth } from "aws-amplify";

const styles = makeStyles((theme) => ({
  root: {},
  titleTop: {
    padding: 20,
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  container: {
    margin: "0 auto",
    background: "#fcfcfc",
    maxWidth: 460,
    width: "100%",
    marginTop: 80,
    borderRadius: "0px",
  },
  marginTop: {
    marginTop: 20,
  },
  demo: {
    margin: "20px auto",
    display: "block",
    textAlign: "center",
  },
  demolink: {
    textDecoration: "none",
    color: "white",
    background: "#4783b6",
    padding: "4px 8px",
    borderRadius: "7px",
  },
  actions: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: 0,
    padding: "10px 0",
  },
  link: {
    color: "#413c3c",
    padding: "8px 0px",
    textDecoration: "none",
    borderRadius: 5,
    fontWeight: 550,
  },
  error: {
    color: "red",
  },
}));

const AuthLogin = function (props) {
  const dispatch = useDispatch();
  const classes = styles();

  const [user, setUser] = useState(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState([]);

  const handleLogin = async () => {
    setError([]);
    setSubmitting(true);
    try {
      const user = await Auth.signIn(username, password);
      if (user) {
        setUser(user);
        dispatch(allActions.itemActions.setUser(user.attributes));
        setSubmitting(false);
        window.location.href = "/dashboard";
      }
    } catch (error) {
      setSubmitting(false);
      setError([error.message || error.code]);
    }
  };

  const onUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <div className={classes.root}>
      <Card className={classes.container}>
        <Typography variant="h5" className={classes.titleTop}>
          Votre boutique en ligne en quelques minutes.
        </Typography>

        <CardHeader
          className={classes.header}
          title="Kadomi.Shop • Connexion à votre compte"
          subheader=""
          disableTypography={true}
        />
        <CardContent>
          <form noValidate autoComplete="off" className={classes.root}>
            <FormControl fullWidth>
              <TextField
                label="Nom d'utilisateur ou E-mail *"
                margin="normal"
                placeholder="Saisissez votre nom d'utilisateur"
                onChange={onUsernameChange}
                variant="outlined"
                value={username}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                label="Mot de passe *"
                type="password"
                margin="normal"
                placeholder="Saisissez votre mot de passe"
                onChange={onPasswordChange}
                variant="outlined"
                value={password}
              />
            </FormControl>
          </form>
          <Button
            variant="contained"
            disabled={submitting || password === "" || username === ""}
            onClick={handleLogin}
          >
            {false ? "processing ..." : "Se connecter"}
          </Button>
          <Typography className={classes.marginTop}>
            Mot de passe oublié ?{" "}
            <Link className={classes.link} to="/recover-password">
              Réinitialisez votre mot de passe
            </Link>
          </Typography>
        </CardContent>
        <CardActions className={classes.actions}>
          <Typography>
            Pas encore de compte ?{" "}
            <Link className={classes.link} to="/register">
              Créer un compte
            </Link>
          </Typography>
        </CardActions>
        <div className={classes.demo}>
          <a
            className={classes.demolink}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.youtube.com/watch?v=8nhvKZaY53I"
          >
            Voir une démo
          </a>
        </div>
        {error.length > 0
          ? error.map((e, key) => (
            <Typography className={classes.error} key={key}>
              {e}
            </Typography>
          ))
          : null}
      </Card>
    </div>
  );
};

export default AuthLogin;