import { useState } from "react";
import useAPI from "./useAPI";
// import { useHistory } from "react-router-dom";

export default function useAdminManualTicket(props) {
  const { currentItem, onSave, callback } = props;

  // let history = useHistory();

  const [name, setName] = useState("");
  const [firstname, setFirstname] = useState("");
  const [mobile, setMobile] = useState("");
  const [price, setPrice] = useState("");
  const [title, setTitle] = useState(
    !!currentItem && currentItem.title ? currentItem.title : ""
  );
  const [info, setInfo] = useState(
    !!currentItem && currentItem.info ? currentItem.info : ""
  );
  const [saleId, setSaleId] = useState(
    !!currentItem && currentItem.saleId ? currentItem.saleId : ""
  );

  const [submitting, setSubmitting] = useState(false);

  const [checkedSendSMS, setCheckedSendSMS] = useState("1");

  const localUser = JSON.parse(localStorage.getItem("kdmUser"));

  const { query: createTicket } = useAPI({
    defer: true,
    type: "post",
    url: `ticket/manual/${btoa(localUser.sub)}`,
    body: {
      uid: btoa(localUser.sub),
      saleId,
      title,
      name,
      firstname,
      mobile,
      price,
      info,
      itemId: (currentItem && currentItem.id) || null,
      checkedSendSMS,
    },
  });

  const handleCheckedSendSMS = (event) => {
    setCheckedSendSMS(event.target.checked ? "1" : "0");
  };

  const handleNameChange = (e) => {
    const { value } = e.target;
    setName(value);
  };

  const handleFirstnameChange = (e) => {
    const { value } = e.target;
    setFirstname(value);
  };

  const handleMobileChange = (e) => {
    const { value } = e.target;
    const valueCleand = value.replace(/\D+/g, "");
    if (isFinite(valueCleand)) {
      setMobile(valueCleand);
    }
  };

  const handlePriceChange = (e) => {
    const { value } = e.target;
    if (!isNaN(parseFloat(value)) && isFinite(value)) {
      setPrice(value);
    }
  };

  const handleTitleChange = (e) => {
    const { value } = e.target;
    setTitle(value);
  };

  const handleOnInfoChange = (e) => {
    const { value } = e.target;
    setInfo(value);
  };

  const handleSaleIdChange = (e) => {
    const { value } = e.target;
    setSaleId(value);
  };

  const handleCreateTicket = async () => {
    setSubmitting(true);

    try {
      await createTicket();
      setSubmitting(false);
      onSave({ success: true, message: "Ticket généré avec succcès" });
      // redirect to ticket created
      // NOT FOR THE MOMENT
      // setTimeout(() => {
      //   history.push(`/tickets/latest`);
      // }, 2000);

      callback && callback();
    } catch (err) {
      console.log(err);
      onSave({
        success: false,
        message: "Erreur: Vérifier la validité de vos données.",
      });
      setSubmitting(false);
    }
  };

  return {
    name,
    onNameChange: handleNameChange,
    firstname,
    onFirstnameChange: handleFirstnameChange,
    mobile,
    onMobileChange: handleMobileChange,
    price,
    onPriceChange: handlePriceChange,
    title,
    onTitleChange: handleTitleChange,
    saleId,
    info,
    onSaleIdChange: handleSaleIdChange,
    onInfoChange: handleOnInfoChange,
    checkedSendSMS,
    onCheckedSendSMS: handleCheckedSendSMS,
    submitting,
    onCreateTicket: handleCreateTicket,
  };
}
