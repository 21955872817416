import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  title: { fontSize: "1.5em", fontWeight: "normal" },
  actions: {
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: 40,
    padding: "10px 0",
  },
  grid: {
    padding: 10,
    marginBottom: 10,
  },
  infoSMS: {
    fontSize: "14px",
    marginBottom: "10px",
  },
}));

const AdminManualTicket = (props) => {
  const classes = useStyles();
  const {
    name,
    onNameChange,
    firstname,
    onFirstnameChange,
    mobile,
    onMobileChange,
    price,
    onPriceChange,
    title,
    onTitleChange,
    info,
    onInfoChange,
    saleId,
    onSaleIdChange,
    submitting,
    onCancelAction,
    onCreateTicket,
    ticketTitle,
    canEdit,
    checkedSendSMS,
    onCheckedSendSMS,
  } = props;

  return (
    <React.Fragment>
      <h1 className={classes.title}>{ticketTitle}</h1>
      <form noValidate autoComplete="off" className={classes.root}>
        <Grid container spacing={3}>
          <Grid md={12} item xs={12}>
            <Paper className={classes.grid} spacing={3}>
              <TextField
                fullWidth
                label="Ticket *"
                margin="normal"
                onChange={onTitleChange}
                variant="outlined"
                value={title}
              />
              <TextField
                fullWidth
                label="Info complémentaire"
                margin="normal"
                onChange={onInfoChange}
                variant="outlined"
                value={info}
              />
              {canEdit ? (
                <FormControl fullWidth>
                  <TextField
                    label="Vente existante non aboutie"
                    margin="normal"
                    onChange={onSaleIdChange}
                    placeholder="Sale Id"
                    variant="outlined"
                    value={saleId}
                  />
                </FormControl>
              ) : null}

              <FormControl fullWidth>
                <TextField
                  label="Nom *"
                  margin="normal"
                  onChange={onNameChange}
                  variant="outlined"
                  value={name}
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  label="Prénom *"
                  margin="normal"
                  onChange={onFirstnameChange}
                  variant="outlined"
                  value={firstname}
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  label="Mobile *"
                  margin="normal"
                  onChange={onMobileChange}
                  variant="outlined"
                  value={mobile}
                />
              </FormControl>
              <div className={classes.infoSMS}>
                Le code QR est envoyé sur le mobile du client. (0.30CHF par SMS)
              </div>
              {canEdit ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedSendSMS === "1"}
                      onChange={onCheckedSendSMS}
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  }
                  label="Envoyer le code par SMS (ou ultérieurement dans la section 'tickets générés')"
                />
              ) : null}

              <FormControl fullWidth>
                <TextField
                  label="Prix du ticket (en CHF) à titre informatif *"
                  margin="normal"
                  onChange={onPriceChange}
                  variant="outlined"
                  value={price}
                />
              </FormControl>
            </Paper>
          </Grid>
        </Grid>
      </form>

      <div className={classes.actions}>
        <Button
          variant="contained"
          disabled={
            submitting || name === "" || firstname === "" || mobile.length === 0
          }
          onClick={onCreateTicket}
        >
          {submitting ? "processing ..." : "Sauver"}
        </Button>
        <Button
          variant="contained"
          disabled={submitting}
          onClick={onCancelAction}
        >
          Annuler
        </Button>
      </div>
    </React.Fragment>
  );
};

export default AdminManualTicket;
