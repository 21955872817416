import React from "react";

import useAdminCategory from "../hooks/useAdminCategory";
import AdminCategory from "../components/AdminCategory";

export default function AdminCategoryInterface(props) {
  const {
    title,
    description,
    graph,
    superUser,
    currentItem,
    onTitleChange,
    onDescriptionChange,
    onGraphChange,
    onNodeSave,
  } = useAdminCategory(props);

  const { onCancelAction } = props;

  return (
    <AdminCategory
      superUser={superUser}
      title={title}
      description={description}
      graph={graph}
      currentItem={currentItem}
      onTitleChange={onTitleChange}
      onDescriptionChange={onDescriptionChange}
      onGraphChange={onGraphChange}
      onNodeSave={onNodeSave}
      onCancelAction={onCancelAction}
    />
  );
}
