import { useState } from "react";

const useAdminTicket = function (props) {
  const { currentItem } = props;

  const [ticketCode] = useState(
    !!currentItem && currentItem.ticketCode ? currentItem.ticketCode : ""
  );

  const [productName] = useState(
    !!currentItem && currentItem.productName ? currentItem.productName : ""
  );

  const [lastname] = useState(
    !!currentItem && currentItem.lastname ? currentItem.lastname : ""
  );

  const [firstname] = useState(
    !!currentItem && currentItem.firstname ? currentItem.firstname : ""
  );

  const [mobile] = useState(
    !!currentItem && currentItem.mobile ? currentItem.mobile : ""
  );

  const [saleTransaction] = useState(
    !!currentItem && currentItem.saleTransaction
      ? currentItem.saleTransaction
      : ""
  );

  const [ticketConsumed] = useState(
    !!currentItem && currentItem.ticketConsumed
      ? currentItem.ticketConsumed
      : ""
  );

  const [ticketCreated] = useState(
    !!currentItem && currentItem.ticketCreated ? currentItem.ticketCreated : ""
  );

  const [ticketStatus] = useState(
    !!currentItem && currentItem.ticketStatus ? currentItem.ticketStatus : ""
  );

  return {
    ticketCode,
    productName,
    lastname,
    firstname,
    mobile,
    saleTransaction,
    ticketConsumed,
    ticketCreated,
    ticketStatus,
  };
};

export default useAdminTicket;
