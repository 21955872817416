const fetchAllMerchants = (merchants) => {
  return {
    type: "FETCH_ALL_MERCHANTS",
    merchants,
  };
};

const fetchAllMerchantsAdmin = (merchants) => {
  return {
    type: "FETCH_ALL_MERCHANTS_ADMIN",
    merchants,
  };
};

export default {
  fetchAllMerchants,
  fetchAllMerchantsAdmin,
};
