import React from "react";
import AdminDatePeriodManager from "../components/AdminDatePeriodManager";

export default function AdminDatePeriodManagerInterface(props) {
  const { label, date, dateId, onEventPlanner } = props;

  return (
    <AdminDatePeriodManager
      label={label}
      date={date}
      dateId={dateId}
      onEventPlanner={onEventPlanner}
    />
  );
}
