import React from "react";

import useAdminPickeAndSelectSite from "../hooks/useAdminPickeAndSelectSite";
import AdminPickAndSelectSite from "../components/AdminPickAndSelectSite";

export default function AdminPickAndSelectSiteInterface(props) {
  const { onCancelAction, items, merchants, onPostSave } = props;
  const {
    platform,
    onChangeMultiple,
    onToggle,
    disabledButton,
    onSubmit,
    submitting,
  } = useAdminPickeAndSelectSite({ items, onPostSave, merchants });

  return (
    <AdminPickAndSelectSite
      platforms={merchants}
      platform={platform}
      items={items}
      onCancelAction={onCancelAction}
      disabledButton={disabledButton}
      onChangeMultiple={onChangeMultiple}
      onToggle={onToggle}
      onSubmit={onSubmit}
      submitting={submitting}
    />
  );
}
