import React from "react";

import useAdminPayments from "../hooks/useAdminPayments";
import AdminPayments from "../components/AdminPayments.js";

const AdminPaymentsInterface = function (props) {
  const { onEdit } = props;
  const { payments, onDelete, onRefreshData, apiError } = useAdminPayments();
  return (
    <AdminPayments
      payments={payments}
      onEdit={onEdit}
      onDelete={onDelete}
      apiError={apiError}
      onRefreshData={onRefreshData}
    />
  );
};

export default AdminPaymentsInterface;
