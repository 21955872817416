import React from "react";

import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { makeStyles } from "@material-ui/core/styles";
import {
  Tooltip,
  FormLabel,
  RadioGroup,
  Radio,
  Typography,
  FormGroup,
  InputAdornment,
} from "@material-ui/core";

import { parsePartnerURL } from "./utils/applibs";
import ItemsEnumeratorInterface from "../interfaces/ItemsEnumeratorInterface";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import PersonIcon from "@material-ui/icons/Person";
import MailIcon from "@material-ui/icons/Mail";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 0,
    border: "0px solid red",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  platformTitle: {
    marginTop: 20,
    marginBottom: 20,
  },
  marginRight: {
    marginRight: 10,
  },
  fullWidth: { width: "100%" },
  extraMarginTop: {
    marginTop: 20,
  },
  categories: {
    marginTop: 20,
    marginBottom: 20,
  },
  actions: {
    display: "flex",
    justifyContent: "space-around",
    padding: "10px 0",
  },
  platforms: {
    margin: "20px 0",
  },
}));

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: theme.palette.primary.main,
    fontSize: 13,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const AdminMerchant = (props) => {
  const {
    superUser,
    typeShop,
    paymethod,
    email,
    name,
    address,
    referer,
    currency,
    currencies,
    hasPagination,
    lang,
    langs,
    apikey,
    container,
    editMode,
    tags,
    categories,
    facebook,
    instagram,
    emailOwner,
    usershop,
    demo,
    partnerCategories,
    handlePostItem,
    onEmailChange,
    onNameChange,
    onAddressChange,
    onLangChange,
    onRefererChange,
    onCurrencyChange,
    onPaginationChange,
    onContainerChange,
    onChangeTypeShop,
    onChangePaymethod,
    onChangeLocationPartner,
    onChangeShopPartner,
    submitting,
    locationPartner,
    shopPartner,
    onTagsChange,
    onCategoriesChange,
    onChangeSocialInsta,
    onChangeSocialFB,
    onChangeUsershop,
    onChangeDemoSite,
    onEmailOwner,
    onCancelAction,
  } = props;
  const classes = useStyles();

  const typePlatformTitle = editMode
    ? typeShop === "1001"
      ? `Plateforme Kadomi.ch`
      : `Plateform personnel`
    : null;

  const hasCategoriesSelected = (catId) => {
    const categoriesFormatted =
      typeof categories === "string" ? [] : categories;
    const category = categoriesFormatted.find(
      (c) => parseInt(c.id, 10) === parseInt(catId, 10)
    );

    return !!category;
  };

  return (
    <React.Fragment>
      <form noValidate autoComplete="off" className={classes.root}>
        {!editMode ? (
          <React.Fragment>
            <FormLabel component="legend">
              <Typography>Type de shop</Typography>
            </FormLabel>
            <RadioGroup
              aria-label="Type de shop"
              name="gender1"
              value={typeShop}
              onChange={onChangeTypeShop}
            >
              {[
                {
                  id: "0",
                  name: "Inclure la boutique sur mon site personnel",
                  helper:
                    "Vos clients viennent sur votre site pour acheter les articles.",
                },
                {
                  id: "1001",
                  name: "Publier mes articles sur le site partenaire Kadomi.ch",
                  helper:
                    "Vous proposez à vos clients d'acheter vos articles sur le site partenaire https://kadomi.ch",
                },
              ].map((p) => (
                <BootstrapTooltip title={p.helper}>
                  <FormControlLabel
                    key={p.id}
                    value={p.id}
                    control={<Radio />}
                    label={p.name}
                  />
                </BootstrapTooltip>
              ))}
            </RadioGroup>
          </React.Fragment>
        ) : null}

        {editMode ? (
          <FormControlLabel
            className={classes.platformTitle}
            control={
              <Checkbox
                checked={true}
                onChange={() => { }}
                disabled={true}
                name="isPartnerShop"
              />
            }
            label={typePlatformTitle}
          />
        ) : null}

        <FormControlLabel
          control={
            <Checkbox
              checked={demo === "1"}
              onChange={onChangeDemoSite}
              name="demo"
              color="primary"
            />
          }
          label="Site de démo"
        />

        {typeShop === "1001" ? (
          <div>
            <TextField
              label="Lieu *"
              margin="normal"
              onChange={onChangeLocationPartner}
              variant="outlined"
              value={locationPartner}
              disabled={false}
            />
            <TextField
              label="Boutique *"
              margin="normal"
              onChange={onChangeShopPartner}
              variant="outlined"
              value={shopPartner}
              disabled={false}
            />
            <FormControl fullWidth>
              <TextField
                label="URL Kadomi"
                margin="normal"
                onChange={() => { }}
                variant="outlined"
                value={parsePartnerURL(locationPartner, shopPartner)}
                disabled={true}
              />
            </FormControl>
            <FormControl className={classes.categories}>
              <FormLabel component="legend">
                Catégorie(s) de votre shop en ligne
              </FormLabel>
              <FormGroup row>
                {partnerCategories.map((c) => (
                  <FormControlLabel
                    key={c.id}
                    control={
                      <Checkbox
                        checked={hasCategoriesSelected(c.id)}
                        onChange={onCategoriesChange}
                        name={c.id}
                      />
                    }
                    label={c.name.fr}
                  />
                ))}
              </FormGroup>
            </FormControl>
            <FormControl fullWidth>
              <TextField
                label="Page Facebook"
                margin="normal"
                onChange={onChangeSocialFB}
                variant="outlined"
                value={facebook}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FacebookIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                label="Compte Instagram"
                margin="normal"
                onChange={onChangeSocialInsta}
                variant="outlined"
                value={instagram}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <InstagramIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <FormControl>
              <TextField
                label="Nom d'utilisateur associé au shop *"
                margin="normal"
                onChange={onChangeUsershop}
                variant="outlined"
                value={usershop}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <FormControl>
              <TextField
                label="Email de contact *"
                margin="normal"
                onChange={onEmailOwner}
                variant="outlined"
                value={emailOwner}
                type="email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <ItemsEnumeratorInterface
              className={classes.extraMarginTop}
              title={`Mot-clés associés à votre shop`}
              values={typeof tags === "string" ? tags.split(",") : tags}
              type={`tags`}
              canDelete={false}
              onChange={onTagsChange}
            ></ItemsEnumeratorInterface>
          </div>
        ) : null}

        {typeShop === "0" ? (
          <React.Fragment>
            <FormControl fullWidth>
              <TextField
                label="Nom du point de vente / boutique"
                margin="normal"
                onChange={onNameChange}
                variant="outlined"
                value={name}
              />
            </FormControl>
            <div className={classes.fullWidth}>
              <TextField
                label="Identification du site"
                margin="normal"
                onChange={() => { }}
                variant="outlined"
                value={apikey}
                disabled={true}
              />
              <TextField
                label="Email du responsable du site"
                margin="normal"
                onChange={onEmailChange}
                variant="outlined"
                value={email}
                disabled={true}
              />
            </div>
            <FormControl fullWidth>
              <TextField
                label="Lieu"
                margin="normal"
                onChange={onAddressChange}
                variant="outlined"
                value={address}
              />
            </FormControl>
            <FormControl fullWidth>
              <BootstrapTooltip
                title={`Veuillez vous assurer que l'URL (ou adresse) de votre site soit toujours accessible en navigation sécurités (HTTPS et non HTTP)`}
              >
                <TextField
                  label="Url du site"
                  margin="normal"
                  onChange={onRefererChange}
                  variant="outlined"
                  value={referer}
                />
              </BootstrapTooltip>
            </FormControl>
            <div className={classes.fullWidth}>
              <TextField
                label={`Langue (${langs.map((l) => l.code).join(", ")})`}
                margin="normal"
                onChange={onLangChange}
                variant="outlined"
                value={lang}
              />
              <TextField
                label={`Devise (${currencies.map((c) => c.code).join(", ")})`}
                margin="normal"
                onChange={onCurrencyChange}
                variant="outlined"
                value={currency}
              />
              <BootstrapTooltip
                title={`Optionel: spécifie un 'conteneur' au nom unique sur votre page web destiné à afficher votre shopping. Si ce champ est remplie, veuillez ajouter le conteneur suivant sur votre page web : <div="${container}"></div>`}
              >
                <TextField
                  label="Identifiant de l'emplacement"
                  margin="normal"
                  onChange={onContainerChange}
                  variant="outlined"
                  value={container}
                />
              </BootstrapTooltip>
            </div>
            <div className={classes.fullWidth}>

              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">Passerelle de paiement</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={paymethod}
                  onChange={onChangePaymethod}
                >
                  <FormControlLabel value="1" control={<Radio />} label="DATATRANS" />
                  <FormControlLabel value="3" control={<Radio />} label="STRIPE" />
                </RadioGroup>
              </FormControl>
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={hasPagination}
                  onChange={onPaginationChange}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Pagination & Filtres"
            />
          </React.Fragment>
        ) : null}

        <FormControl fullWidth></FormControl>
        <div className={classes.actions}>
          {!superUser ? (
            <Button
              variant="contained"
              disabled={
                submitting ||
                (typeShop === "1001" &&
                  (locationPartner === "" ||
                    shopPartner === "" ||
                    usershop === ""))
              }
              onClick={handlePostItem}
            >
              {submitting ? "processing ..." : "Sauver"}
            </Button>
          ) : null}
          <Button
            variant="contained"
            disabled={submitting}
            onClick={onCancelAction}
          >
            Annuler
          </Button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default AdminMerchant;
