import React from "react";

import useAdminMerchants from "../hooks/useAdminMerchants";
import AdminMerchants from "../components/AdminMerchants";

const AdminMerchantsInterface = function (props) {
  const { onEdit, onClickOpenShoppingExport } = props;
  const {
    merchants,
    menus,
    superUser,
    onDelete,
    onRefreshData,
    apiError,
  } = useAdminMerchants();

  return (
    <AdminMerchants
      superUser={superUser}
      merchants={merchants}
      menus={menus}
      onEdit={onEdit}
      onDelete={onDelete}
      apiError={apiError}
      onRefreshData={onRefreshData}
      onShoppingSnipetRequest={onClickOpenShoppingExport}
    />
  );
};

export default AdminMerchantsInterface;
