import React, { useState } from "react";

import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";

import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";

import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import AuthRePassword from "./AuthRePassword";

const styles = makeStyles((theme) => ({
  root: {},
  header: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  container: {
    margin: "0 auto",
    background: "#fcfcfc",
    maxWidth: 460,
    width: "100%",
    marginTop: 120,
    borderRadius: "0px",
  },
  link: {
    color: "#413c3c",
    padding: "8px 0px",
    textDecoration: "none",
    borderRadius: 5,
    fontWeight: 550,
  },
  marginTop: {
    marginTop: 10,
  },
  actions: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: 0,
    padding: "10px 0",
  },
  error: {
    color: "red",
  },
}));

const AuthPasswordRecovery = function (props) {
  const classes = styles();

  const [email, setEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [codeReceived, setCodeReceived] = useState(false);
  const [destination, setDestination] = useState("");
  const [error, setError] = useState([]);

  const handleRecover = async () => {
    setError([]);
    setSubmitting(true);
    try {
      const response = await Auth.forgotPassword(email);
      setDestination(response.CodeDeliveryDetails.Destination);
      setSubmitting(false);
      setCodeReceived(true);
    } catch (error) {
      setSubmitting(false);
      setError([error.message || error.code]);
    }
  };

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return codeReceived ? (
    <AuthRePassword destination={destination} username={email} />
  ) : (
    <div className={classes.root}>
      <Card className={classes.container}>
        <CardHeader
          className={classes.header}
          title="Kadomi.Shop • Récupérer son mot de passe"
          subheader=""
          disableTypography={true}
        />
        <CardContent>
          <form noValidate autoComplete="off" className={classes.root}>
            <Typography>
              Veuillez saisir vote adresse email pour recevoir un lien
              d'activation
            </Typography>
            <FormControl fullWidth>
              <TextField
                label="E-mail *"
                margin="normal"
                placeholder="Saisissez votre e-mail"
                onChange={onEmailChange}
                variant="outlined"
                value={email}
              />
            </FormControl>
          </form>
          <Typography className={classes.marginTop}>
            Déjà un compte ?{" "}
            <Link className={classes.link} to="/login">
              Se connecter
            </Link>
          </Typography>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button
            variant="contained"
            disabled={submitting || email === ""}
            onClick={handleRecover}
          >
            {submitting ? "processing ..." : "Envoyer"}
          </Button>
        </CardActions>
        {error.length > 0
          ? error.map((e, key) => (
              <Typography className={classes.error} key={key}>
                {e}
              </Typography>
            ))
          : null}
      </Card>
    </div>
  );
};

export default AuthPasswordRecovery;
