import React from "react";

import useAdminManualTicket from "../hooks/useAdminManualTicket";
import AdminManualTicket from "../components/AdminManualTicket";

export default function AdminManualTicketInterface(props) {
  const {
    name,
    onNameChange,
    firstname,
    onFirstnameChange,
    mobile,
    onMobileChange,
    price,
    onPriceChange,
    title,
    onTitleChange,
    info,
    onInfoChange,
    saleId,
    onSaleIdChange,
    checkedSendSMS,
    onCheckedSendSMS,
    submitting,
    onCreateTicket,
  } = useAdminManualTicket(props);

  const { onCancelAction, canEdit } = props;

  return (
    <AdminManualTicket
      ticketTitle={
        (props.currentItem && props.currentItem.title) || "error#no-title"
      }
      name={name}
      onNameChange={onNameChange}
      firstname={firstname}
      onFirstnameChange={onFirstnameChange}
      mobile={mobile}
      onMobileChange={onMobileChange}
      title={title}
      onTitleChange={onTitleChange}
      price={price}
      onPriceChange={onPriceChange}
      info={info}
      onInfoChange={onInfoChange}
      saleId={saleId}
      onSaleIdChange={onSaleIdChange}
      checkedSendSMS={checkedSendSMS}
      onCheckedSendSMS={onCheckedSendSMS}
      onCreateTicket={onCreateTicket}
      onCancelAction={onCancelAction}
      submitting={submitting}
      canEdit={canEdit}
    />
  );
}
