import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import useAPI from "../hooks/useAPI";

import { useDispatch } from "react-redux";
import allCustomerActions from "../state/customers/actions/index";

export default function useAdminCustomers() {
  const dispatch = useDispatch();
  const localUser = JSON.parse(localStorage.getItem("kdmUser"));
  const superUser = localUser.sub === process.env.REACT_APP_ADMIN_UID;

  const [apiError] = useState(false); //TODO:Placer dans useAPI

  const { data, query: fetchData } = useAPI({
    type: "get",
    url: `customers`,
  });

  const customers = useSelector((state) => {
    return state.customers.customers;
  });

  // const { query: deleteItem } = useAPI({
  //   defer: true,
  //   type: "delete",
  //   url: "/customer",
  //   body: {},
  // });

  const handleDelete = async (id) => {
    // try {
    console.log("must no be implemented");
    //   setApiError(false);
    //   await deleteItem({
    //     url: `/customer/${id}`,
    //   });
    //   await fetchData();
    // } catch (error) {
    //   throw error;
    //   // setApiError(true); // TODO: Voir si on garde ce truc, pas vraiment utile je crois
    // }
  };

  const handleRefreshData = async () => {
    await fetchData();
  };

  useEffect(() => {
    if (data && data.items) {
      dispatch(
        allCustomerActions.customerActions.fetchAllCustomers(data.items)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    customers,
    superUser,
    onDelete: handleDelete,
    onRefreshData: handleRefreshData,
    apiError,
  };
}
