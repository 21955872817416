import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    flexWrap: "wrap",
    margin: "20px auto",
  },
  paper: {
    padding: theme.spacing(1, 2),
  },
  link: {
    textDecoration: "none",
    color: "#413c3c",
  },
}));

// function handleClick(event) {
//   event.preventDefault();
//   console.info("You clicked a breadcrumb!!");
// }

export default function CustomBreadcrumb(props) {
  const { textLink } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.paper}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link className={classes.link} color="inherit" to="/dashboard">
            Home
          </Link>
          {/* <Link
            color="inherit"
            href="/getting-started/installation/"
            onClick={handleClick}
          >
            E-commerce
          </Link> */}
          <Typography color="textPrimary">{textLink}</Typography>
        </Breadcrumbs>
      </Paper>
    </div>
  );
}
