import React from "react";

import useAdminPayment from "../hooks/useAdminPayment";
import AdminPayment from "../components/AdminPayment";

const AdminPaymentInterface = function (props) {
  const {  onCancelAction } = props;
  const {
    name,
    account,
    payments,
    owner,
    editMode,
    submitting,
    onNameChange,
    onOwnerNameChange,
    onAccounthange,
    onPostItem,
    onDelete,
    onRefreshData,
    // apiError,
  } = useAdminPayment(props);
  return (
    <AdminPayment
      name={name}
      account={account}
      owner={owner}
      payments={payments}
      editMode={editMode}
      submitting={submitting}
      onNameChange={onNameChange}
      onOwnerNameChange={onOwnerNameChange}
      onAccounthange={onAccounthange}
      onPostItem={onPostItem}
      // onEdit={onEdit}
      onDelete={onDelete}
      onCancelAction={onCancelAction}
      // apiError={apiError}
      onRefreshData={onRefreshData}
    />
  );
};

export default AdminPaymentInterface;
