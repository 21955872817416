const fetchAllTickets = (tickets) => {
  return {
    type: "FETCH_ALL_ACTIONS",
    tickets,
  };
};

export default {
  fetchAllTickets,
};
