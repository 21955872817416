import React from "react";

var slugify = require("slugify");

export default function useAddDimension(props) {
  const [open, setOpen] = React.useState(false);
  const [dimension, setDimension] = React.useState("");
  const [type, setType] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(true);
  const [snackbarMessage, setSnackMessage] = React.useState("");

  const { onAddDimension } = props;

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    closeDialog();
  };

  const handleCloseSnack = () => {
    setOpenSnack(false);
    setSubmitting(false);
  };

  const handleDimensionChange = (e) => {
    const { value } = e.target;
    if (value.length <= 20) {
      setDimension(e.target.value);
    }
  };

  const handleTypeChange = (e) => {
    const type = slugify(e.target.value);
    if (type.length <= 10) {
      setType(type.toUpperCase());
    }
  };

  const handleSave = () => {
    setSubmitting(true);
    setOpenSnack(false);
    const response = onAddDimension(dimension, type);

    if (!!response) {
      setDimension("");
      setType("");
      setSubmitting(false);
      closeDialog();
    } else {
      setSnackMessage(
        "Sauvegarde non possible. Vérifiez que la caractéristique n'existe pas déjà"
      );
      setOpenSnack(true);
    }
  };

  return {
    open,
    dimension,
    type,
    submitting,
    openSnack,
    snackbarMessage,
    openDialog,
    closeDialog,
    onSave: handleSave,
    onCancel: handleCancel,
    onDimensionChange: handleDimensionChange,
    onTypeChange: handleTypeChange,
    onCloseSnack: handleCloseSnack,
  };
}
