import { useState, useEffect } from "react";
import useAPI from "../hooks/useAPI";

import { useDispatch } from "react-redux";
import allSaleActions from "../state/sales/actions";

export default function useAdminSale(props) {
  // initialization & props
  const dispatch = useDispatch();
  const localUser = JSON.parse(localStorage.getItem("kdmUser"));
  const superUser = localUser.sub === process.env.REACT_APP_ADMIN_UID;
  const { callback, currentItem, onSave } = props;

  //state
  const uid =
    !!currentItem && currentItem.uid ? currentItem.uid : localUser.sub;

  const saleId = !!currentItem && currentItem.id ? currentItem.id : "";

  const [productId, setProductId] = useState(
    !!currentItem && currentItem.productId ? currentItem.productId : ""
  );

  const [productType, setProductType] = useState(
    !!currentItem && currentItem.productType ? currentItem.productType : "1"
  );

  const [saleStatus, setSaleStatus] = useState(
    !!currentItem && currentItem.saleStatus ? currentItem.saleStatus : "4"
  );

  const [saleNote, setSaleNote] = useState(
    !!currentItem && currentItem.dimensions ? currentItem.dimensions : ""
  );

  const [productName, setProductName] = useState(
    !!currentItem && currentItem.productName ? currentItem.productName : ""
  );

  const saleCreated =
    !!currentItem && currentItem.saleCreated ? currentItem.saleCreated : "";

  const [saleReferer, setReferer] = useState(
    !!currentItem && currentItem.saleReferer
      ? currentItem.saleReferer
      : "kadomi.shop (internal)"
  );

  const [saleCurrency, setCurrency] = useState(
    !!currentItem && currentItem.saleCurrency ? currentItem.saleCurrency : ""
  );

  const [saleAmount, setAmount] = useState(
    !!currentItem && currentItem.saleAmount ? currentItem.saleAmount : ""
  );

  const [saleNb, setSaleNb] = useState(
    !!currentItem && currentItem.nb ? currentItem.nb : 1
  );

  const [lang, setLang] = useState(
    !!currentItem && currentItem.lang ? currentItem.lang : ""
  );

  const [saleRefNumber, setSaleRefNumber] = useState(
    !!currentItem && currentItem.saleRefNumber ? currentItem.saleRefNumber : ""
  );

  const [saleUppTransactionNumber, setSaleUppTransactionNumber] = useState(
    !!currentItem && currentItem.saleUppTransactionNumber
      ? currentItem.saleUppTransactionNumber
      : ""
  );

  const [saleNotif, setSaleNotif] = useState(
    !!currentItem && currentItem.saleNotif ? currentItem.saleNotif : ""
  );

  const [salePaymentMethod, setPaymentMethod] = useState(
    !!currentItem && currentItem.salePaymentMethod
      ? currentItem.salePaymentMethod
      : ""
  );

  const [submitting, setSubmitting] = useState(false);

  // backend request
  const { data, query: fetchData } = useAPI({
    type: "get",
    url: `sales/${btoa(localUser.sub)}`,
    defer: true,
  });

  const { query: postItem } = useAPI({
    defer: true,
    type: "post",
    url: `sale/${btoa(localUser.sub)}`,
    body: {
      uid,
      productId,
      productType,
      productName,
      saleStatus,
      saleNote,
      saleReferer,
      saleUppTransactionNumber,
      saleRefNumber,
      saleCurrency,
      saleNb,
      saleAmount,
      salePaymentMethod,
      saleNotif,
    },
  });

  const { query: updateItem } = useAPI({
    defer: true,
    type: "post",
    url: `/sale/${btoa(localUser.sub)}`,
    body: {
      uid,
      id: !!currentItem && currentItem.id ? currentItem.id : 0,
      productId,
      productType,
      productName,
      saleStatus,
      saleNote,
      saleReferer,
      saleUppTransactionNumber,
      saleRefNumber,
      saleCurrency,
      saleNb,
      saleAmount,
      salePaymentMethod,
      saleNotif,
      // _method: "put",
    },
  });

  // methods
  const handlePostItem = async () => {
    setSubmitting(true);
    try {
      if (!!currentItem && currentItem.id) {
        await updateItem();
      } else {
        await postItem();
      }
      await fetchData();
      setSubmitting(false);
      onSave({ success: true, message: "Site sauvée avec succcès" });
      callback && callback();
    } catch (err) {
      console.log(err);
      onSave({ success: false, message: "Erreur à la sauvegarde du site" });
      setSubmitting(false);
    }
  };

  const handleProductTypeChange = (e) => {
    setProductType(e.target.value);
  };

  const handleProductIdChange = (e) => {
    setProductId(e.target.value);
  };

  const handleSaleStatusChange = (e) => {
    setSaleStatus(e.target.value);
  };

  const handleSaleNoteChange = (e) => {
    const { value } = e.target;
    try {
      const parseNote = JSON.parse(saleNote);
      console.log("saleNote", saleNote);
      if (parseNote.infoSale) {
        setSaleNote(
          JSON.stringify({
            infoSale: value,
          })
        );
      }
      if (Array.isArray(parseNote) && parseNote.length === 1) {
        setSaleNote(JSON.stringify([value]));
      }
      if (Array.isArray(parseNote) && parseNote.length === 0) {
        setSaleNote(JSON.stringify([value]));
      }
    } catch (e) {
      setSaleNote(JSON.stringify([value]));
    }
  };

  const handleProductNameChange = (e) => {
    setProductName(e.target.value);
  };

  const handleLangChange = (e) => {
    setLang(e.target.value);
  };

  const handleRefererChange = (e) => {
    setReferer(e.target.value);
  };

  const handleCurrencyChange = (e) => {
    const { value } = e.target;
    // if (["CHF", "EUR"].includes(value.toUpperCase())) {
    setCurrency(value.toUpperCase());
    // }
  };

  const handleAmountChange = (e) => {
    const { value } = e.target;
    if (!isNaN(parseFloat(value)) && isFinite(value)) {
      setAmount(value);
    }
  };

  const handleNbChange = (e) => {
    const { value } = e.target;
    if (!isNaN(parseFloat(value)) && isFinite(value)) {
      setSaleNb(value);
    }
  };

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleRefnumberChange = (e) => {
    setSaleRefNumber(e.target.value);
  };

  const handleSaleUppTransactionNumber = (e) => {
    setSaleUppTransactionNumber(e.target.value);
  };

  const handleSaleNotif = (e) => {
    const { value } = e.target;
    setSaleNotif(value);
  };

  //hook cycle
  useEffect(() => {
    if (data && data.sales) {
      dispatch(allSaleActions.saleActions.fetchAllSales(data.sales));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    superUser,
    saleId,
    saleCreated,
    productName,
    productType,
    productId,
    saleStatus,
    saleNote,
    saleCurrency,
    saleAmount,
    saleNb,
    lang,
    saleRefNumber,
    salePaymentMethod,
    saleNotif,
    saleUppTransactionNumber,
    saleReferer,
    submitting,
    handlePostItem,
    onProductNameChange: handleProductNameChange,
    onProductIdChange: handleProductIdChange,
    onProductTypeChange: handleProductTypeChange,
    onStatusChange: handleSaleStatusChange,
    onNoteChange: handleSaleNoteChange,
    onLangChange: handleLangChange,
    onRefererChange: handleRefererChange,
    onCurrencyChange: handleCurrencyChange,
    onAmountChange: handleAmountChange,
    onNbChange: handleNbChange,
    onPaymentMethodChange: handlePaymentMethodChange,
    onChangeSaleUppTransactionNumber: handleSaleUppTransactionNumber,
    onRefnumberChange: handleRefnumberChange,
    onSaleNotifChange: handleSaleNotif,
  };
}
