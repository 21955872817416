import React from "react";

import useAdminCategories from "../hooks/useAdminCategories";
import AdminCategories from "../components/AdminCategories";

export default function AdminCategoriesInterface(props) {
  const { onEdit } = props;
  const {
    categories,
    merchants,
    superUser,
    onDelete,
    onRefreshData,
    apiError,
  } = useAdminCategories();

  return (
    <AdminCategories
      superUser={superUser}
      categories={categories}
      merchants={merchants}
      onDelete={onDelete}
      apiError={apiError}
      onEdit={onEdit}
      onRefreshData={onRefreshData}
    />
  );
}
