import React from "react";
import PropTypes from "prop-types";
import CustomSnackbar from "./utils/Snakbar";
import { Button, CssBaseline, TextField } from "@material-ui/core";
import CustomBreadcrumb from "./utils/Breacrumb";
import { useSelector } from "react-redux";
import CommonDialog from "./utils/Dialogs";

var _ = require("lodash");

export default function AdminManager(props) {
  const { type, labelAddItem, breadcrumTitle, labelCustomComponent } = props;

  const [editModePass, setEditModePass] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState(
    "[default] Enregistré sauvé"
  );
  const [success, setSuccess] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState(null);

  const items = useSelector((state) => {
    switch (type) {
      case "items":
        return state.items.items;
      case "merchants":
        return state.merchants.merchants;
      case "sales":
        return state.sales.sales;
      case "categories":
        return state.categories.categories;
      case "payments":
        return state.payments.payments;
      case "tickets":
        return state.tickets.tickets;
      default:
        return [];
    }
  });

  const getfieldIdPerType = () => {
    switch (type) {
      case "sales":
      case "sale":
        return "saleId";
      case "tickets":
      case "ticket":
        return "ticketId";
      default:
        return "id";
    }
  };

  const getItemNamePerType = () => {
    switch (type) {
      case "items":
      case "item":
        return "productName";
      case "sales":
      case "sale":
        return "saleId";
      case "tickets":
      case "ticket":
        return "ticketCode";
      default:
        return null;
    }
  };

  const handleEditModePass = (e) => {
    setEditModePass(e.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event) => {
    setCurrentItem(null);
    setOpen(false);
  };

  const handleClose2 = (event) => {
    setCurrentItem(null);
    setOpen2(false);
  };

  const onEditItem2 = (rowId) => {
    let currentItem = null;
    if (rowId > 0) {
      currentItem = _.find(items, (u) => {
        return parseInt(u.id, 10) === parseInt(rowId, 10);
      });
    }

    if (currentItem) {
      setCurrentItem(currentItem);
    }

    setTimeout(setOpen2(true), 1000);
  };

  const onEditItem = (id) => {
    const fieldId = getfieldIdPerType();

    const itemsFormatted = items.map((i) => ({
      ...i,
      id: i[fieldId],
    }));

    let currentItem = null;
    if (id > 0) {
      currentItem = _.find(itemsFormatted, (u) => {
        return parseInt(u.id, 10) === parseInt(id, 10);
      });
    }

    if (currentItem) {
      setCurrentItem(currentItem);
    }

    setTimeout(setOpen(true), 1000);
  };

  const handleSave = ({ success, message }) => {
    setSnackbarMessage(message);
    setSuccess(true);
  };

  const handleCloseSnakbar = () => {
    setSuccess(false);
  };

  const { ListComponent, EditComponent, CustomComponent } = props;

  // this not work
  const currentItemFieldNameSelection = getItemNamePerType(type);
  const titleDialog =
    currentItem && currentItem[currentItemFieldNameSelection]
      ? `${currentItem[currentItemFieldNameSelection]}: Editer`
      : `${labelAddItem}`;

  return (
    <React.Fragment>
      <h1>{breadcrumTitle}</h1>

      <CustomSnackbar
        text={snackbarMessage}
        // text={labelAddItem}
        open={success}
        onCloseSnakbar={handleCloseSnakbar}
      />
      <CssBaseline />
      <div>
        <CustomBreadcrumb textLink={breadcrumTitle} />
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          {labelAddItem}
        </Button>
        {CustomComponent ? (
          <CommonDialog
            maxWidth="lg"
            title={labelCustomComponent}
            onClose={handleClose2}
            onCancelAction={handleClose2}
            open={open2}
            render={() => (
              <CustomComponent
                onSave={handleSave}
                onCancelAction={handleClose2}
                callback={handleClose2}
                currentItem={currentItem}
                canEdit={editModePass === "halloween111"}
              />
            )}
          />
        ) : null}
        {EditComponent ? (
          <CommonDialog
            maxWidth="lg"
            title={titleDialog}
            onClose={handleClose}
            onCancelAction={handleClose}
            callback={handleClose}
            onSave={handleSave}
            open={open}
            render={() => (
              <EditComponent
                canEdit={editModePass === "halloween111"}
                onCancelAction={handleClose}
                callback={handleClose}
                onSave={handleSave}
                currentItem={currentItem}
              />
            )}
          />
        ) : null}

        <ListComponent
          businessType={type}
          onEdit2={
            CustomComponent
              ? onEditItem2
              : () => {
                  console.log("noop");
                }
          }
          onEdit={
            EditComponent
              ? onEditItem
              : () => {
                  console.log("noop");
                }
          }
          canEdit={editModePass === "halloween111"}
          onSave={handleSave}
        />
      </div>

      <TextField
        label=""
        margin="normal"
        value={editModePass}
        onChange={handleEditModePass}
      />
    </React.Fragment>
  );
}

AdminManager.propTypes = {
  type: PropTypes.string.isRequired,
  component: PropTypes.object,
};
