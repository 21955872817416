const fetchAllCustomers = (customers) => {
  return {
    type: "FETCH_ALL_ACTIONS",
    customers,
  };
};

export default {
  fetchAllCustomers,
};
