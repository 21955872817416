const fetchAllUsers = (users) => {
  return {
    type: "FETCH_ALL_USERS",
    users,
  };
};

export default {
  fetchAllUsers,
};
