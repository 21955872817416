import React from "react";
import AuthLogin from "../AuthLogin";
import AuthRegister from "../AuthRegister";
import AuthVerify from "../AuthVerify";
import AuthPasswordRecovery from "../AuthPasswordRecovery";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { withStyles, createStyles } from "@material-ui/core/styles";
import PublicHome from "../PublicHome";

const styles = (theme) =>
  createStyles({
    root: {
      background: "#f3f3f3",
      height: "100%",
      "& > div": {
        background: "#f3f3f3",
        display: "grid",
        // height: "100%",
      },
      "& > div > div": {
        /* should be handled by the subcomponents and this subcomponents should instead share styles for authlogin, recoverlogin,etc... */
        height: "fit-content",
      },
    },
    header: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      border: "3px solid green",
    },
    footer: {
      border: "3px solid blue",
    },
  });

const AdminAuth = function (props) {
  const { classes } = props;

  return (
    <Router>
      <div className={classes.root}>
        <Switch>
          <Route path="/login">
            <AuthLogin />
          </Route>
          <Route path="/register">
            <AuthRegister />
          </Route>
          <Route path="/recover-password">
            <AuthPasswordRecovery />
          </Route>
          <Route path="/verify">
            <AuthVerify />
          </Route>
          <Route path="/">
            <PublicHome />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default withStyles(styles)(AdminAuth);
