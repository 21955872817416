const fetchAllCat = (categories) => {
  return {
    type: "FETCH_ALL_ACTIONS",
    categories,
  };
};

export default {
  fetchAllCat,
};
