import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import useAPI from "../hooks/useAPI";

import { useDispatch } from "react-redux";
import allMerchantActions from "../state/merchants/actions/index";

export default function useAdminMerchants() {
  const dispatch = useDispatch();
  const localUser = JSON.parse(localStorage.getItem("kdmUser"));
  const superUser = localUser.sub === process.env.REACT_APP_ADMIN_UID;

  const [apiError, setApiError] = useState(false); //TODO:Placer dans useAPI

  const { data, query: fetchData } = useAPI({
    type: "get",
    url: `merchants`,
  });

  const { data: dataMenus } = useAPI({
    type: "get",
    url: `categories`,
  });

  const merchants = useSelector((state) => {
    return state.merchants.merchants;
  });

  const { query: deleteItem } = useAPI({
    defer: true,
    type: "delete",
    url: "merchant",
    body: {},
  });

  const handleDelete = async (id) => {
    try {
      setApiError(false);
      await deleteItem({
        url: `merchant/${id}`,
      });
      await fetchData();
    } catch (error) {
      throw error;
      // setApiError(true); // TODO: Voir si on garde ce truc, pas vraiment utile je crois
    }
  };

  const handleRefreshData = async () => {
    await fetchData();
  };

  useEffect(() => {
    if (data && data.merchants) {
      dispatch(
        allMerchantActions.merchantActions.fetchAllMerchants(data.merchants)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    merchants,
    menus:
      dataMenus && Array.isArray(dataMenus.categories)
        ? dataMenus.categories.map((m) => ({ id: m.id, name: m.title }))
        : [],
    superUser,
    onDelete: handleDelete,
    onRefreshData: handleRefreshData,
    apiError,
  };
}
