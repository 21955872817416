export default {
  s3: {
    REGION: "eu-west-3",
    BUCKET: "src-k3iko-serverless-bucket",
  },
  // apiGateway: {
  //   REGION: "YOUR_API_GATEWAY_REGION",
  //   URL: "YOUR_API_GATEWAY_URL"
  // },

  // kadomi-post-july-10
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_rjovm25fO",
    APP_CLIENT_ID: "bcq4io5ut52fk78aojpt03n1q",
    IDENTITY_POOL_ID: "eu-west-1:0e538e07-449c-40d4-8cf1-7b5a3b2de618",
  },

  // kadomi-pre-july-10
  //purpose was to get rid of phone on registration but didnt work !!!!!
  // cognito: {
  //   REGION: "eu-west-1",
  //   USER_POOL_ID: "eu-west-1_2CQlnJ0Gj",
  //   APP_CLIENT_ID: "58optsqjegfgkeo4fc14v198sv",
  //   IDENTITY_POOL_ID: "eu-west-1:9c48c3a8-0c9c-48a9-95fa-09d127a212a7",
  // },

  // s3: {
  //   REGION: "eu-west-3",
  //   BUCKET: "src-k3iko-serverless-bucket"
  // },
  // // apiGateway: {
  // //   REGION: "YOUR_API_GATEWAY_REGION",
  // //   URL: "YOUR_API_GATEWAY_URL"
  // // },
  // cognito: {
  //   REGION: "eu-west-1",
  //   USER_POOL_ID: "eu-west-1_3K9tMpsN5",
  //   APP_CLIENT_ID: "61qlf24j5uk9o9sa0jubvlltf9",
  //   IDENTITY_POOL_ID: "eu-west-1:02764ae6-343f-47c2-890e-3a00a38371f0"
  // }
};
