const initialState = [];

const payments = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ALL_PAYMENTS":
      state = action.payments;
      return state;
    default:
      return state;
  }
};

export default payments;
