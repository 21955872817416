const fetchAll = (items) => {
  return {
    type: "FETCH_ALL",
    items,
  };
};

const setUser = (user) => {
  return {
    type: "SET_USER",
    user,
  };
};

const clearUser = (user) => {
  return {
    type: "CLEAR_USER",
    user,
  };
};

export default {
  fetchAll,
  setUser,
  clearUser,
};
