import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import useAPI from "../hooks/useAPI";

import { useDispatch } from "react-redux";
import allActions from "../state/items/actions/index";

export default function useAdminListItems() {
  const dispatch = useDispatch();
  const localUser = JSON.parse(localStorage.getItem("kdmUser"));
  const superUser = localUser.sub === process.env.REACT_APP_ADMIN_UID;

  const { data, query: fetchData } = useAPI({
    type: "get",
    // url: `/items/${btoa(localUser.sub) || null}`,
    url: `items`,
  });

  const { data: dataMerchants } = useAPI({
    type: "get",
    // url: `/merchants/${btoa(localUser.sub) || null}`,
    url: `merchants`,
  });

  const items = useSelector((state) => {
    return state.items.items;
  });

  const { data: dataMenu } = useAPI({
    type: "get",
    url: `category/7`, // TEST
  });

  const [apiError, setApiError] = useState(false); //TODO:Placer dans useAPI

  const { query: deleteItem } = useAPI({
    defer: true,
    type: "delete",
    url: "/item",
    body: {},
  });

  /**
   *
   * @param {string|array} id   @param id   ID(s) of items to delete
   */
  const handleDelete = async (id) => {
    try {
      setApiError(false);
      await deleteItem({
        url: `item/${id}`,
      });
      await fetchData();
    } catch (error) {
      throw error;
      // setApiError(true); // TODO: Voir si on garde ce truc, pas vraiment utile je crois
    }
  };

  const handleRefreshData = async () => {
    await fetchData();
  };

  useEffect(() => {
    if (data && data.items) {
      dispatch(allActions.itemActions.fetchAll(data.items));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    items,
    merchants:
      dataMerchants && Array.isArray(dataMerchants.merchants)
        ? dataMerchants.merchants.map((m) => ({ id: m.id, name: m.referer }))
        : [],
    menu: (dataMenu && dataMenu.menu && dataMenu.menu.menu) || {},
    superUser,
    onDelete: handleDelete,
    onRefreshData: handleRefreshData,
    apiError,
  };
}
