import React from "react";
import StickyHeadTable from "./StickyHeadTable";
import { makeStyles } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";
import { Button } from "@material-ui/core";
import CommonDialog from "./utils/Dialogs";
import CustomSnackbar from "./utils/Snakbar";
import AdminPickAndSelectMenuInterface from "../interfaces/AdminPickAndSelectMenuInterface";
import { getPaymentMethodLabel } from "./utils/applibs";

var dateFormat = require("dateformat");

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 30,
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(95, 85, 87, 0.3)",
    padding: "20px 30px",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  tableCommands: {
    margin: "16px auto",
  },
  panelTableSelection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  selection: {
    display: "inline-block",
    marginLeft: 20,
  },
}));

const AdminMerchants = function (props) {
  const classes = useStyles();
  const {
    merchants: items,
    menus,
    superUser,
    onDelete,
    onEdit,
    onRefreshData,
    onShoppingSnipetRequest,
  } = props;
  const [itemsForSelection, setItemsForSelection] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("test");
  const [success, setSuccess] = React.useState(false);

  const handleItemsForSelectionChange = (items) => {
    setItemsForSelection(items);
  };

  const handleClickAssignCategoriesToPlatform = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = (result) => {
    const {  message } = result || {};
    setOpenDialog(false);
    if (message) {
      setSnackbarMessage(message);
      setSuccess(true);
    }
  };

  const displayedColumns = [
    { id: "id", label: "#", minWidth: 50, align: "left" },
    { id: "valid", label: "Valid", minWidth: 170 },
    { id: "referer", label: "Site", minWidth: 170 },
    { id: "paymethod", label: "Mode de paiement", minWidth: 170 },
    { id: "currency", label: "Devise", minWidth: 100 },
    { id: "lang", label: "Langue", minWidth: 100 },
    { id: "menu", label: "Menu", minWidth: 100 },
    { id: "created", label: "Ajouté le", minWidth: 60 },
    { id: "action", label: "Actions", minWidth: isMobile ? 90 : 120 },
  ];

  return (
    <div className={classes.root}>
      <CustomSnackbar
        text={snackbarMessage}
        open={success}
        onCloseSnakbar={() => {
          setSuccess(false);
        }}
      />
      <div>
        Enregistrements: ({(items && items.length) || 0})
        <span className={classes.selection}>
          {" "}
          {itemsForSelection.length === 0
            ? `Aucune sélection`
            : itemsForSelection.length === 1
              ? `${itemsForSelection.length} sélectionné`
              : `${itemsForSelection.length} sélectionnés`}
        </span>
      </div>

      <div className={classes.tableCommands}>
        <Button
          disabled={itemsForSelection.length === 0}
          onClick={handleClickAssignCategoriesToPlatform}
          variant="contained"
        >
          Associer un menu
        </Button>
      </div>
      <CommonDialog
        title="Associer un menu au point de vente"
        onClose={handleDialogClose}
        open={openDialog}
        render={() => (
          <AdminPickAndSelectMenuInterface
            onCancelAction={handleDialogClose}
            callback={handleDialogClose}
            merchants={itemsForSelection}
            menus={menus}
            onPostSave={handleDialogClose}
          />
        )}
      />

      <StickyHeadTable
        superUser={superUser}
        filters={["referer", "currency", "valid", "created", "paymethod"]}
        columns={
          isMobile
            ? displayedColumns.filter(
              (c) => c.id === "referer" || c.id === "action"
            )
            : displayedColumns
        }
        data={items.map((i) => {
          return {
            ...i,
            ...{
              valid: i.valid === "1" ? "[valid]" : `[invalid (${i.valid})]`,
              paymethod: getPaymentMethodLabel(i.paymethod),
              menu: i.menu || "",
              ...{ created: dateFormat(i.created, "mmm dS, H:MM") },
            },
          };
        })}
        onDelete={onDelete}
        onEdit={onEdit}
        onItemsForSelectionChange={handleItemsForSelectionChange}
        onRefreshData={onRefreshData}
        onShoppingSnipetRequest={onShoppingSnipetRequest}
        checkboxEnabled={true}
      />
    </div>
  );
};

export default AdminMerchants;
