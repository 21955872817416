import { useState, useEffect } from "react";
import useAPI from "../hooks/useAPI";

import { useDispatch } from "react-redux";
import allPaymentActions from "../state/payments/actions";

const useAdminPayment = (props) => {
  const dispatch = useDispatch();
  const { callback, currentItem, onSave } = props;

  //get current user details
  const localUser = JSON.parse(localStorage.getItem("kdmUser"));

  const editMode = !!currentItem && currentItem.id ? true : false;

  const [submitting, setSubmitting] = useState(false);

  //state
  const uid =
    !!currentItem && currentItem.uid ? currentItem.uid : localUser.sub;

  const [name, setname] = useState(
    !!currentItem && currentItem.name ? currentItem.name : ""
  );

  const [account, setaccount] = useState(
    !!currentItem && currentItem.account ? currentItem.account : ""
  );

  const [owner, setowner] = useState(
    !!currentItem && currentItem.owner ? currentItem.owner : ""
  );

  const handleNameChange = (e) => {
    setname(e.target.value);
  };

  const handleOwnerNameChange = (e) => {
    setowner(e.target.value);
  };

  const handleAccounthange = (e) => {
    setaccount(e.target.value);
  };

  // backend request

  const { data, query: fetchData } = useAPI({
    type: "get",
    url: `/payments`,
    defer: true,
  });

  const { query: postItem } = useAPI({
    defer: true,
    type: "post",
    url: "/payment",
    body: {
      uid,
      name,
      account,
      owner,
    },
  });

  const { query: updateItem } = useAPI({
    defer: true,
    type: "post",
    url: `payment`,
    body: {
      uid,
      id: !!currentItem && currentItem.id ? currentItem.id : 0,
      name,
      account,
      owner,
    },
  });

  // methods

  const handlePostItem = async () => {
    setSubmitting(true);
    try {
      if (!!currentItem && currentItem.id) {
        await updateItem();
      } else {
        await postItem();
      }
      await fetchData();
      setSubmitting(false);
      onSave({ success: true, message: "Site sauvée avec succcès" });
      callback && callback();
    } catch (err) {
      console.log(err);
      onSave({ success: false, message: "Erreur à la sauvegarde du site" });
      setSubmitting(false);
    }
  };

  const handleDelete = () => { };

  const handleRefreshData = () => { };

  //hook cycle
  useEffect(() => {
    if (data && data.payments) {
      dispatch(
        allPaymentActions.paymentActions.fetchAllPayments(data.payments)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    name,
    account,
    owner,
    editMode,
    submitting,
    onNameChange: handleNameChange,
    onOwnerNameChange: handleOwnerNameChange,
    onAccounthange: handleAccounthange,
    onPostItem: handlePostItem,
    onDelete: handleDelete,
    onRefreshData: handleRefreshData,
  };
};

export default useAdminPayment;
