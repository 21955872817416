import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
// import { Link, NavLink } from "react-router-dom";

const styles = makeStyles(theme => ({
  root: {},
  // menu
  appMenuButton: {
    marginRight: theme.spacing(2),
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  appTitle: {
    flexGrow: 1,
  },
  titleSection: { fontWeight: "500", color: "#542f2f" },
  link: {
    textDecoration: "none",
    color: "#0b0c36",
    padding: "2px",
    fontWeight: "700",
  },
  // content
  contentPartner: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: "50px",
    marginBottom: "20px",
    justifyContent: "center",
    "& > img": {
      maxWidth: "240px",
      marginRight: "10px",
      marginBottom: "10px",
    },
  },
  partner: {},
  section: {
    color: "black",
    width: "100%",
    padding: "30px 0",
    "& > div": {
      padding: "10px 200px",
      [theme.breakpoints.down("sm")]: {
        padding: "6px 20px",
      },
    },
  },
  sectionTop: {
    height: "380px",
    position: "static",
    transform: "skewY(357deg)",
    marginTop: "-130px",
    backgroundImage: "linear-gradient(49deg, #6303B1, #ff0099)",
  },
  sectionReverse: {
    paddingTop: "180px",
    transform: "skewY(3deg)",
    color: "white",
    paddingLeft: "20px",
  },
  sectionOdd: {
    background: "white",
  },
  paymentStripeIllustration: {
    maxWidth: 300,
    margin: "20px 0",
    display: "block",
  },
  imgReference: {
    maxWidth: "320px",
    margin: "10px",
  },
  projectIllustration: {
    maxWidth: "1100px",
    display: "block",
    margin: "10px auto",
    marginTop: "20px",
    marginBottom: "20px",
    border: "3px solid #f8f8f8",
    borderRadius: "13px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  projectIllustrationV2: {
    maxWidth: "500px",
    margin: "0 auto",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },

  button: {
    margin: "20px auto",
    fontSize: "14px",
    display: "table",
  },
  sectionFooter: {
    color: "white",
    paddingTop: 15,
    background: "radial-gradient(#392d33, #3f3f3f)",
    height: "100%",
    "& > div": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
  },

  listItem2: {
    padding: 0,
    "& > *": {
      padding: 0,
      "& > div > span": {
        fontSize: 15,
      },
    },
  },
  listItem: {
    "& > a": {
      padding: 0,
    },
  },
}));

const PublicHome = function(props) {
  const classes = styles();
  const partners = [
    { title: "", src: "images/partners/partner--aws-amazon--kadomi-shop.png" },
    { title: "", src: "images/partners/partner--docker--kadomi-shop.png" },
    { title: "", src: "images/partners/partner--nodejs--kadomi-shop.png" },
    { title: "", src: "images/partners/partner--react--kadomi-shop.png" },
    { title: "", src: "images/partners/partner--ssl--kadomi-shop.png" },
    { title: "", src: "images/partners/partner--stripe--kadomi-shop.png" },
  ];

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.appMenuButton}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.appTitle}>
            Kadomi.Shop
          </Typography>
          {/* <Button color="inherit" component={Link} to="/login">
            Se connecter
          </Button> */}
        </Toolbar>
      </AppBar>
      <section className={`${classes.sectionTop}`} id="welcome">
        <div className={classes.sectionReverse}>
          <h1 style={{ fontWeight: "800", fontSize: "45px" }}>Kadomi.Shop</h1>
        </div>
      </section>
      <section className={`${classes.section}`} id="welcome">
        <div>
          <h2 className={classes.titleSection}>
            Intégrez une boutique en ligne à votre site.
          </h2>
          <Typography variant="body2">
            Kadomi.Shop est une plateforme en ligne vous permettant d'ajouter
            une boutique à votre site sans aucune connaissance technique
            particulière. Simple d'usage et rapide à installer, notre système
            vous permet de gérer la création de votre boutique et des articles
            en quelques clics.
            <br />
            <br />
          </Typography>
          <Typography variant="body2">
            Le paiement en ligne de Kadomi.Shop est effectué via&nbsp;
            <a
              className={`${classes.link}`}
              href="https://stripe.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              Stripe.com
            </a>
            , infrastructure de paiement en ligne sécurisée permettant le
            paiement par:
          </Typography>
          <ul>
            <li key={4}>Mastercard</li>
            <li key={3}>VISA</li>
            <li key={2}>Google Pay</li>
            <li key={1}>Apple Pay</li>
          </ul>
          <br />
          <Typography variant="body2">
            Démarrez-vous une activité de vente en ligne ? Nous sommes à votre
            disposition pour vous apporter un soutien technique. Pour cela vous
            pouvez nous contacter à l'adresse{" "}
            <span className={`${classes.link}`}>support@kadomi.shop</span>
          </Typography>
          <h2 className={classes.titleSection}>Clients</h2>
          <img
            className={classes.imgReference}
            alt="illustration kadomi shop for kadomi.ch"
            src="images/kadomi-references-bemyvalentine-switzerland.png"
          />
          <img
            className={classes.imgReference}
            alt="illustration kadomi shop for kadomi.ch"
            src="images/kadomi-references-centre-linguistique-chablais-switzerland.png"
          />
          {/* <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/login"
            className={classes.button}
          >
            Créer votre boutique
          </Button> */}
        </div>
      </section>
      <section
        className={`${classes.section} ${classes.sectionOdd}`}
        id="create-easy"
      >
        <div>
          <h2 className={classes.titleSection}>
            Créez en toute simplicité votre boutique en ligne.
          </h2>
          <Typography variant="body2">
            Notre solution est adaptés aux petites et moyennes entreprises
            désireuses d'apporter une visibilité commerciale tout en réduisant
            la charge technique et les coûts d'infrastructure. Mettez en avant
            vos produits et offrez la possibilité à votre clientèle le paiement
            en ligne.
            <img
              className={classes.projectIllustration}
              alt="illustration kadomi shop for kadomi.ch"
              src="images/kadomi-shop-example---kadomi.jpg"
            />
          </Typography>
        </div>
      </section>
      <section className={`${classes.section}`} id="create-easy">
        <div>
          <h2 className={classes.titleSection}>
            Boutique avec pagination et menu
          </h2>
          <Typography variant="body2">
            Utilisez un menu permettant de structurer et cloisonner votre gamme
            de produits.
            <img
              className={classes.projectIllustration}
              alt="illustration kadomi shop for metalheart.shop"
              src="images/kadomi-shop-example---metalheart.jpg"
            />
          </Typography>
        </div>
      </section>
      <section
        className={`${classes.section} ${classes.sectionOdd}`}
        id="sky-is-the-limit"
      >
        <div>
          <h2 className={classes.titleSection}>
            Une solution adaptée aux services, produits et e-ticket
          </h2>
          <Typography variant="body2">
            Notre solution de boutiques en ligne est adaptés pour les types de
            prestations suivantes:
            <br />
            <li>Produits</li>
            <li>services</li>
            <li>ticketing</li>
            <br />
          </Typography>
          <Typography variant="body2">
            La prestation de type produit concerne un bien physique pouvant être
            envoyé à l'acheteur.
          </Typography>
          <Typography variant="body2">
            Le service peut convenir pour l'achat d'un bien non-physique, un bon
            de restaurant par exemple.
          </Typography>
          <Typography variant="body2">
            Le ticketing, très similaire au service, est un produit
            événementiel. Un code QR est généré à l'attention du client.
          </Typography>
          <img
            className={
              (classes.projectIllustration, classes.projectIllustrationV2)
            }
            alt="kadomi e-ticket"
            src="images/ticketing-kadomi.png"
          />
        </div>
      </section>
      {/* <section className={`${classes.section}`} id="pricing">
        <div>
          <h2 className={classes.titleSection}>Tarifs</h2>
          <Typography variant="body2">
            Créez gratuitement votre billetterie et payez uniquement si vous
            vendez des biens.
          </Typography>
          <h2 className={classes.titleSection}>
            4,4% + 0.30 CHF par biens vendus.
          </h2>
        </div>
      </section> */}
      <section className={`${classes.section} `} id="count-on-us">
        <div>
          <h2 className={classes.titleSection}>Comptez sur nous</h2>
          <Typography variant="body2">
            Soucieux des retours d'informations de nos client, l'équipe de
            Kadomi est à votre disposition pour répondre aux questions. Dans le
            cas où vous souhaiteriez nous en faire part, vous pouvez nous
            contacter à l'adresse info@kadomi.shop
          </Typography>
        </div>
      </section>
      <section
        className={`${classes.section} ${classes.sectionOdd}`}
        id="about"
      >
        <div>
          <h2 className={classes.titleSection}>A propos de Kadomi.Shop</h2>
          <Typography variant="body2">
            Kadomi.Shop est un projet développé en Suisse au début 2019 sous
            l'impulsion d'une volonté de promouvoir le commerce local à moindre
            coût technique tout en garantissant au commerçant un écosystème
            technologique à la pointe.
          </Typography>
          <Typography variant="body2">
            Par ailleurs, nombre de solutions de boutique en ligne nécessite que
            le site complet soit compatible avec la solution. Avec Kadomi.shop,
            l'objectif est avant-tout de s'appuyer sur le site existant et de
            s'intégrer le plus naturellement possible.
          </Typography>
          <Typography variant="body2">
            Kadomi utilise le levier technologique des plus grands et repose
            notamment sur les services Cloud AWS Amazon, de la containerisation
            Docker. Le tout dans une conception Infrastructure as Code (IaC) et
            environnement applicatif NodeJS.
          </Typography>
          <div className={classes.contentPartner}>
            {partners.map((p, k) => {
              return (
                <img
                  key={k}
                  className={classes.partner}
                  alt={p.title}
                  src={p.src}
                />
              );
            })}
          </div>
        </div>
      </section>
      <section
        className={`${classes.section} ${classes.sectionFooter}`}
        id="footer"
      >
        <div>
          <div>
            <List
              className={classes.listItem2}
              component="nav"
              aria-label="dashboard"
            >
              <ListItem>
                <ListItemText
                  className={classes.listItem}
                  inset
                  primary="Kadomi.Shop"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  className={classes.listItem}
                  inset
                  primary="CH-550.1.193.050-9"
                />
              </ListItem>
            </List>
          </div>
          <div>
            <List
              className={classes.listItem2}
              component="nav"
              aria-label="dashboard"
            >
              <ListItem>
                <ListItemText
                  className={classes.listItem}
                  inset
                  primary="Kadomi"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  className={classes.listItem}
                  inset
                  primary="&copy;2019-2022"
                />
              </ListItem>
            </List>
          </div>
          <div>
            <List
              className={classes.listItem2}
              component="nav"
              aria-label="dashboard"
            >
              {/* <ListItem button to="/register" component={NavLink}>
                <ListItemText
                  className={classes.listItem}
                  inset
                  primary="Créez votre compte"
                />
              </ListItem>
              <ListItem button to="/login" component={NavLink}>
                <ListItemText
                  className={classes.listItem}
                  inset
                  primary="Mon compte"
                />
              </ListItem> */}
            </List>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PublicHome;
