import {
  Button,
  // FormControl,
  Grid,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import React from "react";

const useStyle = makeStyles({
  root: {},
  marginRight: {
    marginRight: 10,
  },
  actions: {
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: 40,
    padding: "10px 0",
  },
  grid: {
    padding: 10,
    marginBottom: 10,
  },
});

const AdminTicket = function (props) {
  const {
    ticketCode,
    productName,
    lastname,
    firstname,
    mobile,
    saleTransaction,
    ticketConsumed,
    ticketCreated,
    // ticketStatus,
    onCancelAction,
    submitting,
  } = props;
  const classes = useStyle();

  return (
    <React.Fragment>
      <div className={classes.root}>
        <form noValidate autoComplete="off">
          <Grid container spacing={3}>
            <Grid md={6} item xs={12}>
              <Paper className={classes.grid} spacing={3}>
                <TextField
                  className={classes.marginRight}
                  label="Code ticket"
                  margin="normal"
                  variant="outlined"
                  value={ticketCode}
                />
                <TextField
                  label="Crée le"
                  margin="normal"
                  variant="outlined"
                  value={ticketCreated}
                />
                <TextField
                  className={classes.marginRight}
                  label="Consommé le"
                  margin="normal"
                  variant="outlined"
                  value={ticketConsumed}
                />
                <TextField
                  fullWidth
                  label="Transaction"
                  margin="normal"
                  variant="outlined"
                  value={saleTransaction}
                />
                <TextField
                  fullWidth
                  label="Article"
                  margin="normal"
                  variant="outlined"
                  value={productName}
                />
              </Paper>
            </Grid>
            <Grid md={6} item xs={12}>
              <Paper className={classes.grid} spacing={3}>
                <TextField
                  className={classes.marginRight}
                  label="Nom de famille"
                  margin="normal"
                  variant="outlined"
                  value={lastname}
                />
                <TextField
                  label="Prénom"
                  margin="normal"
                  variant="outlined"
                  value={firstname}
                />
                <TextField
                  label="Mobile"
                  margin="normal"
                  variant="outlined"
                  value={mobile}
                />
              </Paper>
            </Grid>
          </Grid>
        </form>
        <div className={classes.actions}>
          <Button
            variant="contained"
            disabled={submitting}
            onClick={onCancelAction}
          >
            Annuler
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AdminTicket;
