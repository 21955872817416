import React from "react";
import AdminPickAndSelectMenuTree from "../components/AdminPickAndSelectMenuTree";
import useAdminPickAndSelectMenuTree from "../hooks/useAdminPickAndSelectMenuTree";

export default function AdminPickAndSelectMenuTreeInterface(props) {
  const { onCancelAction, onChange, selectedMenus } = props;
  const {
    menuParsedAsTag,
    submitting,
    onClickChipMenu,
  } = useAdminPickAndSelectMenuTree(props);

  return (
    <AdminPickAndSelectMenuTree
      menuParsedAsTag={menuParsedAsTag}
      selected={selectedMenus}
      onClickChipMenu={onClickChipMenu}
      submitting={submitting}
      onChange={onChange}
      onCancelAction={onCancelAction}
    />
  );
}
