import React from "react";

import CssBaseline from "@material-ui/core/CssBaseline";

import AdminHelperInterface from "../interfaces/AdminHelperInterface";
// import AdminMerchantInterface from "../interfaces/AdminMerchantInterface";

import CustomBreadcrumb from "./utils/Breacrumb";

const AdminMerchantsManager = function () {
  return (
    <React.Fragment>
      <CssBaseline />
      <div>
        <CustomBreadcrumb textLink="Support et demande d'aide" />
        {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Ouvrir un ticket
        </Button> */}
        <AdminHelperInterface />
      </div>
    </React.Fragment>
  );
};

export default AdminMerchantsManager;
