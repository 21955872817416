import React from "react";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";

import UploadImage from "../components/UploadImage";

import AdminDatePeriodManagerInterface from "../interfaces/AdminPeriodManagerInterface";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import ItemsEnumeratorInterface from "../interfaces/ItemsEnumeratorInterface";
import AddDimensionInterface from "../interfaces/AddDimensionInterface";
import AddPriceInterface from "../interfaces/AddPriceInterface";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DeleteIcon from "@material-ui/icons/Delete";

import { parseMenuGraphAsTags } from "../components/utils/applibs";

import {
  Typography,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  IconButton,
  Tooltip,
  Chip,
} from "@material-ui/core";
import CommonDialog from "./utils/Dialogs";
import AdminPickAndSelectMenuTreeInterface from "../interfaces/AdminPickAndSelectMenuTreeInterface";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  title: {
    fontSize: "1.5em",
    fontWeight: "normal",
  },
  price: {
    float: "left",
    marginTop: 14,
    width: 50,
    marginLeft: 10,
  },
  uploadImage: {
    margin: "20px auto",
  },
  published: {
    display: "flex",
    flexDirection: "row",
  },
  actions: {
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: 40,
    padding: "10px 0",
  },
  itemCategory: {
    margin: "10px auto 10px auto",
    width: "100%",
  },
  grid: {
    padding: 10,
    marginBottom: 10,
  },
  tagsAsMenu: {
    padding: 10,
    marginBottom: 10,
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  tagAsMenu: {
    margin: "0 10px",
  },
  image: {
    height: 60,
  },
}));

const SHOW_FREETAGS = false;

const useStylesBootstrap = makeStyles(theme => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 13,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

export default function AdminItem(props) {
  const {
    title,
    description,
    tags,
    tagsAsMenu,
    prices,
    nb,
    available,
    busy,
    files,
    dimensions,
    platforms,
    published,
    superUser,
    eventPlanner,
    isEvent,
    requiredFormular,
    dataMenus,
    handlePostItem,
    onTitleChange,
    onDescriptionChange,
    onTagsChange,
    onTagsAsMenuChange,
    onNbItemChange,
    onFileChange,
    submitting,
    onCancelAction,
    onDeleteDimension,
    onChangeDimension,
    onAddDimension,
    onStatusPublishedChange,
    onSavePrice,
    onDeleteSavedPrice,
    openDialogPricing,
    onDialogClosePricing,
    dialogPricing,
    onEventPlanner,
    onSetEvent,
    onSetFormular,
    onSaveMenuForItemRefresh,
    onHandleDeleteFile,
  } = props;

  const classes = useStyles();

  const { id = null } = props.currentItem || {};

  const titleFormatted = id ? `${title} [#${id}]` : title;

  const [openMenuDialog, setOpenMenuDialog] = React.useState(false);

  const handleClickAssignItemsToMenu = () => {
    setOpenMenuDialog(true);
  };

  const handleMenuDialogClose = (refetchData = false) => {
    setOpenMenuDialog(false);
    if (refetchData) {
      onSaveMenuForItemRefresh();
    }
  };

  const tagsRenderedAsLabel = parseMenuGraphAsTags(dataMenus || []).filter(m =>
    tagsAsMenu.some(tm => tm === m.key)
  );

  const hasTags = tags.length > 0;

  return (
    <React.Fragment>
      <h1 className={classes.title}>{titleFormatted}</h1>
      <form noValidate autoComplete="off" className={classes.root}>
        <Grid container spacing={3}>
          <Grid md={6} item xs={12}>
            <Paper className={classes.grid} spacing={3}>
              <FormControl fullWidth>
                <TextField
                  label="Nom de l'article *"
                  margin="normal"
                  onChange={onTitleChange}
                  variant="outlined"
                  value={title}
                />
              </FormControl>
              <FormControl>
                <RadioGroup
                  className={classes.published}
                  aria-label="status"
                  name="status"
                  value={published.toString()}
                  onChange={onStatusPublishedChange}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Publié"
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Non publié"
                  />
                </RadioGroup>
              </FormControl>

              <BootstrapTooltip
                title={`Cochez l'option dans le cas où votre article est lié à un événement.`}
              >
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isEvent === "1"}
                        onChange={onSetEvent}
                        color="primary"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                    }
                    label="Associer une billetterie pour cet article"
                  />
                </FormControl>
              </BootstrapTooltip>

              <BootstrapTooltip
                title={`Le formulaire de livraison est généralement associé à l'envoi d'un produit physique.`}
              >
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={requiredFormular === "1"}
                        onChange={onSetFormular}
                        color="primary"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                    }
                    label="Formulaire de livraison requis."
                  />
                </FormControl>
              </BootstrapTooltip>

              <FormControl fullWidth>
                <TextField
                  multiline
                  rows="4"
                  label="Description *"
                  margin="normal"
                  onChange={onDescriptionChange}
                  variant="outlined"
                  value={description}
                />
              </FormControl>

              <Paper className={classes.grid}>
                <TextField
                  label="Nombre d'unités (max. 1000)"
                  margin="normal"
                  onChange={onNbItemChange}
                  variant="outlined"
                  value={nb}
                />
                <FormControl fullWidth>
                  Stock disponible <span>{available}</span>
                </FormControl>
                <br />
                <FormControl>
                  Vendu / en cours de vente <span>{busy}</span>
                </FormControl>
              </Paper>

              <Paper className={classes.grid}>
                <Button variant="contained" onClick={openDialogPricing}>
                  Ajouter un prix *
                </Button>
                <div>
                  {prices.map((p, key) => (
                    <div key={key}>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => {
                          onDeleteSavedPrice(p.currency);
                        }}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                      <span
                        className={classes.price}
                      >{`${p.price} ${p.currency}`}</span>
                    </div>
                  ))}
                </div>
                <CommonDialog
                  title="Ajouter un prix"
                  onClose={onDialogClosePricing}
                  open={dialogPricing}
                  render={() => <AddPriceInterface onSavePrice={onSavePrice} />}
                />
              </Paper>
              <div>
                {files.length
                  ? files.map((f, key) => (
                      <div key={key}>
                        <img
                          key={key}
                          className={classes.image}
                          alt={`Item illustration for '${title}'`}
                          src={`https://src-k3iko-serverless-bucket.s3.eu-west-3.amazonaws.com/public/${f}`}
                        />
                        <Button
                          // onClick={() => console.log("delete...", f, key)}
                          onClick={() => onHandleDeleteFile(f, key)}
                        >
                          <DeleteIcon />
                        </Button>
                      </div>
                    ))
                  : null}
              </div>
              <div className={classes.uploadImage}>
                <UploadImage onFileChange={onFileChange} />
              </div>
            </Paper>
          </Grid>
          <Grid md={6} item xs={12}>
            <Paper className={classes.grid}>
              <Typography variant="h6">Caractériques</Typography>
              <FormControl fullWidth>
                {Array.isArray(dimensions)
                  ? dimensions.map((d, key) => (
                      <div key={key} className={classes.itemCategory}>
                        <ItemsEnumeratorInterface
                          key={key}
                          title={d.title}
                          values={d.items}
                          type={d.type}
                          canDelete={true}
                          onChange={onChangeDimension}
                          onDelete={onDeleteDimension}
                        ></ItemsEnumeratorInterface>
                      </div>
                    ))
                  : null}
              </FormControl>

              <FormControl>
                <AddDimensionInterface
                  onAddDimension={onAddDimension}
                ></AddDimensionInterface>
              </FormControl>
            </Paper>
            <Paper className={classes.grid}>
              {SHOW_FREETAGS ? (
                <ItemsEnumeratorInterface
                  className={classes.extraMarginTop}
                  title={`Mot-clés de l'article`}
                  values={typeof tags === "string" ? tags.split(",") : tags}
                  type={`tags`}
                  canDelete={false}
                  onChange={onTagsChange}
                ></ItemsEnumeratorInterface>
              ) : null}

              {hasTags || true ? (
                <React.Fragment>
                  <Button
                    onClick={handleClickAssignItemsToMenu}
                    variant="contained"
                  >
                    Lier/retirer des labels pour cette article
                  </Button>
                  <div className={classes.tagsAsMenu}>
                    {tagsRenderedAsLabel.map(t => (
                      <Chip
                        className={classes.tagAsMenu}
                        key={t.key}
                        label={t.label}
                      />
                    ))}
                  </div>
                </React.Fragment>
              ) : null}

              <CommonDialog
                title="Lier/retirer des labels pour cette article"
                onClose={() => {
                  handleMenuDialogClose(false);
                }}
                open={openMenuDialog}
                render={() => (
                  <AdminPickAndSelectMenuTreeInterface
                    onCancelAction={() => {
                      handleMenuDialogClose(false);
                    }}
                    callback={() => {
                      handleMenuDialogClose(true);
                    }}
                    onChange={onTagsAsMenuChange}
                    selectedMenus={tagsAsMenu}
                    dataMenus={dataMenus}
                  />
                )}
              />
            </Paper>
            <br />
            {isEvent === "1" ? (
              <Paper className={classes.grid}>
                <div>
                  <AdminDatePeriodManagerInterface
                    label="Période l'événement"
                    dateId="dateEvent"
                    date={eventPlanner.dateEvent}
                    onEventPlanner={onEventPlanner}
                  />
                  <AdminDatePeriodManagerInterface
                    label="Période de vente"
                    dateId="dateSale"
                    date={eventPlanner.dateSale}
                    onEventPlanner={onEventPlanner}
                  />
                </div>
              </Paper>
            ) : null}
            <Paper className={classes.grid}>
              <Typography variant="h6">
                Sites de publication de l'article
              </Typography>
              <FormControl>
                {platforms.map(p => (
                  <FormControlLabel
                    key={p.id}
                    control={<Checkbox checked={true} name={p.id} />}
                    label={p.name}
                  />
                ))}
              </FormControl>
            </Paper>
          </Grid>
        </Grid>
      </form>
      <div className={classes.actions}>
        {!superUser ? (
          <Button
            variant="contained"
            disabled={
              submitting ||
              title === "" ||
              description === "" ||
              prices.length === 0
            }
            onClick={handlePostItem}
          >
            {submitting ? "processing ..." : "Sauver"}
          </Button>
        ) : null}
        <Button
          variant="contained"
          disabled={submitting}
          onClick={onCancelAction}
        >
          Annuler
        </Button>
      </div>
    </React.Fragment>
  );
}
