import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { FormControl } from "@material-ui/core";
// import Avatar from "@material-ui/core/Avatar";
import CustomSnackbar from "./utils/Snakbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import CustomBreadcrumb from "./utils/Breacrumb";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      maxWidth: 460,
      width: "90%",
    },
  },
  sub: {
    fontSize: 10,
    margin: "20px 0",
  },
  // avatar: {
  //   width: theme.spacing(7),
  //   height: theme.spacing(7),
  // },
  margin: {
    margin: theme.spacing(1),
  },
}));

export default function AdminProfile(props) {
  const classes = useStyles();
  const { defaultParams } = props;
  const {
    sub,
    firstName,
    lastName,
    email,
    phone,
    city,
    npa,
    submitting,
    success,
    error,
    onChangeEmail,
    onChangeFirstName,
    onChangeLastName,
    onChangePhone,
    onChangeCity,
    onChangeNpa,
    onSubmit,
    onCloseSnakbar,
  } = defaultParams;

  return (
    <div>
      <CssBaseline />
      <CustomBreadcrumb textLink="Mon profil" />

      <CustomSnackbar
        autoHideDuration={6000}
        text={
          error
            ? "Une erreur est survenue"
            : "Profil sauvegardé ! Veuillez vous reconnecter pour visualiser les changes."
        }
        open={success}
        onCloseSnakbar={onCloseSnakbar}
      />

      <div className={classes.root}>
        <form noValidate autoComplete="off">
          {/* <FormControl fullWidth className={classes.margin}>
            <Avatar
              className={classes.avatar}
              alt="Remy Sharp"
              src="https://kadomi.ch/assets/images/kadomi-profil-fabiom.jpg"
            />
          </FormControl> */}
          <FormControl fullWidth className={classes.margin}>
            <div className={classes.sub}>uid: {sub}</div>
          </FormControl>
          <FormControl fullWidth className={classes.margin}>
            <TextField
              id="lastname"
              data-cy="profile-lastname"
              label="Nom de famille"
              variant="outlined"
              value={lastName}
              onChange={onChangeLastName}
            />
          </FormControl>
          <FormControl fullWidth className={classes.margin}>
            <TextField
              id="firstname"
              data-cy="profile-firstname"
              label="Prénom"
              variant="outlined"
              value={firstName}
              onChange={onChangeFirstName}
            />
          </FormControl>
          <FormControl fullWidth className={classes.margin}>
            <TextField
              id="email"
              data-cy="profile-email"
              label="Email"
              variant="outlined"
              value={email}
              onChange={onChangeEmail}
            />
          </FormControl>

          <FormControl fullWidth className={classes.margin}>
            <TextField
              id="phone"
              data-cy="profile-phone"
              label="Téléphone"
              variant="outlined"
              value={phone}
              onChange={onChangePhone}
            />
          </FormControl>
          <FormControl fullWidth className={classes.margin}>
            <TextField
              id="city"
              data-cy="profile-city"
              label="Ville"
              variant="outlined"
              value={city}
              onChange={onChangeCity}
            />
          </FormControl>
          <FormControl fullWidth className={classes.margin}>
            <TextField
              id="npa"
              data-cy="profile-npa"
              label="Code postal"
              variant="outlined"
              value={npa}
              onChange={onChangeNpa}
            />
          </FormControl>
          <FormControl className={classes.margin}>
            <Button
              disabled={submitting}
              variant="contained"
              onClick={onSubmit}
            >
              {submitting ? "Sauvegarde en cours..." : "Sauver"}
            </Button>
          </FormControl>
        </form>
      </div>
    </div>
  );
}
