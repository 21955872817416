import React from "react";
import useAddDimension from "../hooks/useAddDimension";
import AddDimension from "../components/AddDimension";

export default function AddDimensionInterface(props) {
  const {
    open,
    dimension,
    type,
    submitting,
    openSnack,
    snackbarMessage,
    onDimensionChange,
    onTypeChange,
    openDialog,
    closeDialog,
    onSave,
    onCancel,
    onCloseSnack,
  } = useAddDimension(props);
  return (
    <AddDimension
      open={open}
      dimension={dimension}
      type={type}
      submitting={submitting}
      openSnack={openSnack}
      snackbarMessage={snackbarMessage}
      onDimensionChange={onDimensionChange}
      onTypeChange={onTypeChange}
      openDialog={openDialog}
      closeDialog={closeDialog}
      onSave={onSave}
      onCancel={onCancel}
      onCloseSnack={onCloseSnack}
    ></AddDimension>
  );
}
