const fetchAllPayments = (payments) => {
  return {
    type: "FETCH_ALL_PAYMENTS",
    payments,
  };
};

export default {
  fetchAllPayments,
};
