const initialState = [];

const categories = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ALL_ACTIONS":
      state = action.categories || [];
      return state;
    default:
      return state;
  }
};

export default categories;
