import { useState, useEffect } from "react";
import useAPI from "../hooks/useAPI";

import { useDispatch } from "react-redux";
import allMerchantActions from "../state/merchants/actions";

import { parsePartnerURL, slugifier } from "../components/utils/applibs";

var slugify = require("slugify");

export default function useAdminMerchant(props) {
  const dispatch = useDispatch();
  const localUser = JSON.parse(localStorage.getItem("kdmUser"));
  const superUser = localUser.sub === process.env.REACT_APP_ADMIN_UID;

  const { callback, currentItem, onSave } = props;

  const editMode = !!currentItem && currentItem.id ? true : false;

  const langs = [{ code: "FR", label: "french" }];

  const currencies = [{ code: "CHF", label: "swissfranc" }];

  //state
  const uid =
    !!currentItem && currentItem.uid ? currentItem.uid : localUser.sub;

  const [typeShop, setTypeShop] = useState(
    !!currentItem && currentItem.typeShop ? currentItem.typeShop : "0"
  );
  const [paymethod, setPaymethod] = useState(
    !!currentItem && currentItem.paymethod ? currentItem.paymethod : ""
  );
  const [locationPartner, setLocationPartner] = useState(
    !!currentItem && currentItem.address ? currentItem.address : ""
  );
  const [shopPartner, setShopPartner] = useState(
    !!currentItem && currentItem.name ? currentItem.name : ""
  );

  const [shopPartnerURL, setShopPartnerURL] = useState("");

  const [tags, setTags] = useState((!!currentItem && currentItem.tags) || []);

  const [categories, setCategories] = useState(
    (!!currentItem && currentItem.categories) || []
  );

  const [instagram, setInstagram] = useState(
    (!!currentItem && currentItem.instagram) || "https://instagram.com/"
  );

  const [facebook, setFacebook] = useState(
    (!!currentItem && currentItem.facebook) || "https://facebook.com/"
  );

  const [emailOwner, setEmailOwner] = useState(
    (!!currentItem && currentItem.emailOwner) || ""
  );

  const [usershop, setUsershop] = useState(
    (!!currentItem && currentItem.usershop) || ""
  );

  const [demo, setDemo] = useState((!!currentItem && currentItem.demo) || "1");

  const partnerCategories = [
    { id: "1000", keyword: "art", name: { fr: "art" } },
    { id: "1001", keyword: "paiting", name: { fr: "peinture" } },
    {
      id: "1002",
      keyword: "litterature",
      name: { fr: "littérature" },
    },
    { id: "1003", keyword: "brico", name: { fr: "bricolage" } },
    { id: "1004", keyword: "clothing", name: { fr: "vêtement" } },
    { id: "1005", keyword: "outside", name: { fr: "extérieur" } },
    {
      id: "1006",
      keyword: "secondhand",
      name: { fr: "seconde main" },
    },
    { id: "1007", keyword: "realestate", name: { fr: "immobilier" } },
    { id: "1008", keyword: "education", name: { fr: "formation" } },
  ];

  const [name, setName] = useState(
    !!currentItem && currentItem.name ? currentItem.name : ""
  );

  const [address, setAddress] = useState(
    !!currentItem && currentItem.address ? currentItem.address : ""
  );

  const email =
    !!currentItem && currentItem.email ? currentItem.email : localUser.email;

  const [referer, setReferer] = useState(
    !!currentItem && currentItem.referer ? currentItem.referer : ""
  );

  const [currency, setCurrency] = useState(
    !!currentItem && currentItem.currency ? currentItem.currency : ""
  );

  const [hasPagination, setHasPagination] = useState(
    !!currentItem && currentItem.pagesearch ? currentItem.pagesearch : false
  );

  const [lang, setLang] = useState(
    !!currentItem && currentItem.lang ? currentItem.lang : ""
  );

  const apikey = !!currentItem && currentItem.apikey ? currentItem.apikey : "";

  const [container, setContainer] = useState(
    !!currentItem && currentItem.container ? currentItem.container : ""
  );

  const [submitting, setSubmitting] = useState(false);

  // backend request
  const { data, query: fetchData } = useAPI({
    type: "get",
    url: `merchants`,
    defer: true,
  });

  const { query: postItem } = useAPI({
    defer: true,
    type: "post",
    url: "merchant",
    body: {
      uid,
      name,
      email,
      address,
      referer,
      currency,
      hasPagination: hasPagination ? 1 : 0,
      container,
      lang,
      typeShop,
      paymethod,
      shopPartner,
      locationPartner,
      shopPartnerURL: parsePartnerURL(locationPartner, shopPartner),
      tags,
      categories,
      facebook,
      instagram,
      usershop,
      emailOwner,
      demo,
    },
  });

  const { query: updateItem } = useAPI({
    defer: true,
    type: "post",
    url: `merchant`,
    body: {
      uid,
      id: !!currentItem && currentItem.id ? currentItem.id : 0,
      name,
      address,
      email,
      referer,
      currency,
      hasPagination: hasPagination ? 1 : 0,
      container,
      lang,
      typeShop,
      paymethod,
      shopPartner,
      locationPartner,
      shopPartnerURL: parsePartnerURL(locationPartner, shopPartner),
      tags,
      categories,
      facebook,
      instagram,
      usershop,
      emailOwner,
      demo,
      category: 1,
      // _method: "put",
    },
  });

  // methods
  const handlePostItem = async () => {
    setSubmitting(true);
    try {
      if (!!currentItem && currentItem.id) {
        await updateItem();
      } else {
        await postItem();
      }
      await fetchData();
      setSubmitting(false);
      onSave({ success: true, message: "Site sauvée avec succcès" });
      callback && callback();
    } catch (err) {
      console.log(err);
      onSave({ success: false, message: "Erreur à la sauvegarde du site" });
      setSubmitting(false);
    }
  };

  const handleEmailChange = (e) => {
    //    setEmail(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleLangChange = (e) => {
    const langFound = langs
      .map((c) => c.code)
      .find((c) => c.toLowerCase().match(e.target.value.toLowerCase()));
    if (langFound !== undefined) {
      setLang(e.target.value.toUpperCase());
    }
  };

  const handleRefererChange = (e) => {
    setReferer(e.target.value);
  };

  const handleCurrencyChange = (e) => {
    const currencyFound = currencies
      .map((c) => c.code)
      .find((c) => c.toLowerCase().match(e.target.value.toLowerCase()));
    if (currencyFound !== undefined) {
      setCurrency(e.target.value.toUpperCase());
    }
  };

  const handlePaginationChange = (e) => {
    setHasPagination(e.target.checked);
  };

  const handleContainerChange = (e) => {
    const container = slugify(e.target.value);
    if (container.length <= 12) {
      setContainer(container);
    }
  };

  const handleChangeTypeShop = (event) => {
    setTypeShop(event.target.value);
  };

  const handleChangePaymethod = (event) => {
    setPaymethod(event.target.value);
  };

  const handleChangeLocationPartner = (event) => {
    const { value } = event.target;
    if (value.length > 24) {
      return;
    }
    setLocationPartner(value);
  };

  const handleChangeShopPartner = (event) => {
    const { value } = event.target;
    if (value.length > 32) {
      return;
    }
    setShopPartner(event.target.value);
    if (locationPartner !== "" && shopPartnerURL !== "") {
      setShopPartnerURL(parsePartnerURL(locationPartner, shopPartner));
    }
  };

  const handleTagsChange = (value) => {
    setTags(value);
  };

  const handleCategoriesChange = (e) => {
    const categoriesFormatted =
      typeof categories === "string" ? [] : categories;

    const category = partnerCategories.find(
      (c) => parseInt(c.id, 10) === parseInt(e.target.name, 10)
    );

    if (e.target.checked) {
      setCategories([...categoriesFormatted, ...[category]]);
    } else {
      // remove
      setCategories(
        categoriesFormatted.filter(
          (c) => parseInt(c.id, 10) !== parseInt(e.target.name, 10)
        )
      );
    }
  };

  const handleChangeSocialInsta = (event) => {
    setInstagram(event.target.value);
  };

  const handleChangeSocialFB = (event) => {
    setFacebook(event.target.value);
  };

  const handleChangeEmailOwner = (event) => {
    const value = slugifier(event.target.value, true);
    if (value.length > 48) {
      return;
    }

    setEmailOwner(value);
  };

  const handleChangeDemoSite = (event) => {
    setDemo(event.target.checked ? "1" : "0");
  };

  const handleChangeUsershop = (event) => {
    const value = slugifier(event.target.value, false);
    if (value.length <= 20) {
      setUsershop(value);
    }
  };

  //hook cycle
  useEffect(() => {
    if (data && data.merchants) {
      dispatch(
        allMerchantActions.merchantActions.fetchAllMerchants(data.merchants)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    typeShop,
    paymethod,
    email,
    name,
    address,
    currency,
    currencies,
    hasPagination,
    lang,
    langs,
    apikey,
    container,
    referer,
    submitting,
    locationPartner,
    shopPartner,
    tags,
    categories,
    facebook,
    instagram,
    emailOwner,
    usershop,
    editMode,
    demo,
    partnerCategories,
    superUser,
    handlePostItem,
    onEmailChange: handleEmailChange,
    onNameChange: handleNameChange,
    onAddressChange: handleAddressChange,
    onLangChange: handleLangChange,
    onRefererChange: handleRefererChange,
    onCurrencyChange: handleCurrencyChange,
    onPaginationChange: handlePaginationChange,
    onContainerChange: handleContainerChange,
    onChangeTypeShop: handleChangeTypeShop,
    onChangePaymethod: handleChangePaymethod,
    onChangeLocationPartner: handleChangeLocationPartner,
    onChangeShopPartner: handleChangeShopPartner,
    onTagsChange: handleTagsChange,
    onCategoriesChange: handleCategoriesChange,
    onChangeSocialInsta: handleChangeSocialInsta,
    onChangeSocialFB: handleChangeSocialFB,
    onChangeUsershop: handleChangeUsershop,
    onEmailOwner: handleChangeEmailOwner,
    onChangeDemoSite: handleChangeDemoSite,
  };
}
