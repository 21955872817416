import React from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import HelpIcon from "@material-ui/icons/Help";
import Typography from "@material-ui/core/Typography";
// import InputBase from "@material-ui/core/InputBase";
// import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
// import MenuIcon from "@material-ui/icons/Menu";
// import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from "@material-ui/icons/AccountCircle";
// import MailIcon from "@material-ui/icons/Mail";
// import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
import { NavLink } from "react-router-dom";
import DeckIcon from "@material-ui/icons/Deck";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import PeopleIcon from "@material-ui/icons/People";
import DehazeIcon from "@material-ui/icons/Dehaze";
import CategoryIcon from "@material-ui/icons/Category";

const localUser = JSON.parse(localStorage.getItem("kdmUser"));
const superUser =
  localUser && localUser.sub === process.env.REACT_APP_ADMIN_UID;

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    // display: "none",
    // [theme.breakpoints.up("sm")]: {
    //   display: "block",
    // },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function PrimarySearchAppBar() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleAutoCloseMenu = () => {
    setTimeout(() => {
      handleMobileMenuClose();
      setAnchorEl(null);
    }, 0);
  };

  const { email } = localUser;
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        button
        to="/profile"
        component={NavLink}
        onClick={handleAutoCloseMenu}
      >
        Mon profile
      </MenuItem>
      <MenuItem
        button
        to="/logout"
        component={NavLink}
        onClick={handleAutoCloseMenu}
      >
        Déconnexion
      </MenuItem>
      <MenuItem button to="/" component={NavLink} onClick={handleAutoCloseMenu}>
        Accueil
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>{email}</MenuItem>
      <MenuItem
        button
        to="/merchants"
        component={NavLink}
        onClick={handleAutoCloseMenu}
      >
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <DeckIcon />
        </IconButton>
        <p>Points de vente</p>
      </MenuItem>
      <MenuItem
        button
        to="/categories"
        component={NavLink}
        onClick={handleAutoCloseMenu}
      >
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <CategoryIcon />
        </IconButton>
        <p>Catégories</p>
      </MenuItem>
      <MenuItem
        button
        to="/items"
        component={NavLink}
        onClick={handleAutoCloseMenu}
      >
        <IconButton aria-label="show 4 new mails" color="inherit">
          {/* <Badge badgeContent={4} color="secondary"> */}
          <DehazeIcon />
          {/* </Badge> */}
        </IconButton>
        <p>Articles</p>
      </MenuItem>
      <MenuItem
        button
        to="/sales"
        component={NavLink}
        onClick={handleAutoCloseMenu}
      >
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <MonetizationOnIcon />
        </IconButton>
        <p>Ventes</p>
      </MenuItem>
      <MenuItem
        button
        to="/payments"
        component={NavLink}
        onClick={handleAutoCloseMenu}
      >
        <IconButton
          aria-label="payments for current user"
          aria-controls="primary-search-payments-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountBalanceIcon />
        </IconButton>
        <p>Réception de paiement</p>
      </MenuItem>
      <MenuItem
        button
        to="/customers"
        component={NavLink}
        onClick={handleAutoCloseMenu}
      >
        <IconButton
          aria-label="customers for current user"
          aria-controls="primary-search-customers-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountBalanceIcon />
        </IconButton>
        <p>Mes clients</p>
      </MenuItem>
      <MenuItem
        button
        to="/tickets"
        component={NavLink}
        onClick={handleAutoCloseMenu}
      >
        <IconButton
          aria-label="tickets for current user"
          aria-controls="primary-search-tickets-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountBalanceIcon />
        </IconButton>
        <p>Tickets générés</p>
      </MenuItem>
      {superUser ? (
        <MenuItem
          button
          to="/users"
          component={NavLink}
          onClick={handleAutoCloseMenu}
        >
          <IconButton
            aria-label="list of users"
            aria-controls="primary-search-users-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <PeopleIcon />
          </IconButton>
          <p>Utilisateurs</p>
        </MenuItem>
      ) : null}
      <MenuItem to="/helper" component={NavLink} onClick={handleAutoCloseMenu}>
        <IconButton
          aria-label="helper for user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <HelpIcon />
        </IconButton>
        <p>Aide</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar>
          {/* <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton> */}
          <Typography className={classes.title} variant="h6" noWrap>
            Admin Kadomi
          </Typography>
          {/* <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </div> */}
          <div className={classes.grow} />

          {/* <div className={classes.sectionDesktop}>
            <IconButton aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={44} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div> */}

          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}
