import React from "react";

// import useAPI from "../hooks/useAPI";

import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import { FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  actions: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: 40,
    padding: "10px 0",
  },
  checkboxes: {
    display: "block",
  },
}));

const AdminPickAndSelectSite = (props) => {
  const {
    platforms,
    platform,
    onToggle,
    disabledButton,
    onCancelAction,
    onSubmit,
    submitting,
  } = props;

  const classes = useStyles();

  const stripHttp = (platform) => {
    return platform.replace("https://", "");
  };

  const isSelected = (p) => {
    return !!platform.find((p2) => parseInt(p2.id, 10) === parseInt(p, 10));
  };

  return (
    <div>
      <FormGroup className={classes.checkboxes}>
        <InputLabel shrink htmlFor="select-multiple-native">
          Sélectionnez les sites sur lequels vous souhaitez afficher vos
          articles à vendre.
        </InputLabel>

        {platforms.map((platform) => (
          <FormControlLabel
            key={platform.id}
            control={
              <Checkbox
                checked={isSelected(platform.id)}
                onChange={onToggle}
                name={stripHttp(platform.id)}
              />
            }
            label={stripHttp(platform.name)}
          />
        ))}
      </FormGroup>

      <FormControl className={classes.formControl}>
        <div className={classes.actions}>
          <Button variant="contained" onClick={onCancelAction}>
            Annuler
          </Button>
          <Button
            variant="contained"
            disabled={disabledButton || submitting}
            onClick={onSubmit}
          >
            {submitting ? "processing ..." : "Sauver"}
          </Button>
        </div>
      </FormControl>
    </div>
  );
};

export default AdminPickAndSelectSite;
