import React from "react";

// import useAPI from "../hooks/useAPI";

import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import { FormControlLabel, RadioGroup, Radio } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  actions: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: 40,
    padding: "10px 0",
  },
  checkboxes: {
    display: "block",
  },
}));

const AdminPickAndSelectMenu = (props) => {
  const {
    menus,
    menu,
    onChange,
    disabledButton,
    onCancelAction,
    onSubmit,
    submitting,
  } = props;

  const classes = useStyles();

  return (
    <div>
      <InputLabel shrink htmlFor="select-multiple-native">
        Sélectionnez le menu que vous souhaitez affecter au point de vente.
      </InputLabel>

      <RadioGroup
        aria-label="menu"
        name="menu1"
        value={menu}
        onChange={onChange}
      >
        {menus.map((menu) => (
          <FormControlLabel
            key={menu.id}
            value={menu.id}
            control={<Radio />}
            label={menu.name}
          />
        ))}
      </RadioGroup>

      <FormControl className={classes.formControl}>
        <div className={classes.actions}>
          <Button
            variant="contained"
            disabled={disabledButton || submitting}
            onClick={onSubmit}
          >
            {submitting ? "processing ..." : "Sauver"}
          </Button>
          <Button variant="contained" onClick={onCancelAction}>
            Annuler
          </Button>
        </div>
      </FormControl>
    </div>
  );
};

export default AdminPickAndSelectMenu;
