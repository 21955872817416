import React from "react";

import Button from "@material-ui/core/Button";

import StickyHeadTable from "./StickyHeadTable";
import AdminPickAndSelectSiteInterface from "../interfaces/AdminPickAndSelectSiteInterface";

import CustomSnackbar from "./utils/Snakbar";
import CommonDialog from "./utils/Dialogs";

import { makeStyles } from "@material-ui/core/styles";

import { isMobile } from "react-device-detect";
// import AdminPickAndSelectMenuTreeInterface from "../interfaces/AdminPickAndSelectMenuTreeInterface";

var dateFormat = require("dateformat");

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 30,
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(95, 85, 87, 0.3)",
    padding: "20px 30px",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  tableCommands: {
    margin: "16px auto",
    display: "flex",
    flexDirection: "row",
  },
  selection: {
    display: "inline-block",
    marginLeft: 20,
  },
}));

const AdminItems = props => {
  const classes = useStyles();
  const {
    superUser,
    items,
    merchants,
    // menu,
    onDelete,
    onEdit,
    onEdit2,
    onRefreshData,
  } = props;

  const [itemsForSelection, setItemsForSelection] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  // const [openMenuDialog, setOpenMenuDialog] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("test");
  const [success, setSuccess] = React.useState(false);

  const handleItemsForSelectionChange = items => {
    setItemsForSelection(items);
  };

  const handleClickAssignItemsToPlatform = () => {
    setOpenDialog(true);
  };

  // const handleClickAssignItemsToMenu = () => {
  //   setOpenMenuDialog(true);
  // };

  const handleDialogClose = result => {
    const { message } = result || {};
    setOpenDialog(false);
    if (message) {
      setSnackbarMessage(message);
      setSuccess(true);
    }
  };

  // const handleMenuDialogClose = (result) => {
  //   const { success, message } = result || {};
  //   setOpenMenuDialog(false);
  //   if (message) {
  //     setSnackbarMessage(message);
  //     setSuccess(true);
  //   }
  // };

  const isCompliant = item => {
    const errors = [];
    if (!item.prices || item.prices.length === 0) {
      errors.push("Prix non renseigné.");
    }
    if (!item.files || item.files.length === 0) {
      errors.push("Une image est nécessaire pour l'affichage en boutique.");
    }
    if (!item.platforms || item.platforms.length === 0) {
      errors.push("Une plateforme de promotion de l'article est requise.");
    }
    if (parseInt(item.published, 10) === 0) {
      errors.push("L'article n'est pas encore publié.");
    }
    return errors;
  };

  const formattedItems = items.map(i => ({
    ...i,
    ...{ created: dateFormat(i.created, "mmm dS, H:MM") },
    ...{ compliant: isCompliant(i) },
    ...{ published: i.published === 1 ? "Publié" : "Non publié" },
    ...{ nb: `${i.busy} / ${i.nb}` },
    ...{
      description:
        i.description.length > 60
          ? `${i.description.substr(1, 60)}...`
          : i.description,
    },
  }));

  const displayedColumns = [
    { id: "id", label: "#", minWidth: 50, align: "left" },
    { id: "compliant", label: "Valide", minWidth: 50, align: "left" },
    { id: "title", label: "Libellé", minWidth: 170 },
    { id: "published", label: "Status", minWidth: 150 },
    { id: "nb", label: "Stock", minWidth: 80 },
    { id: "created", label: "Crée le", minWidth: 60 },
    {
      id: "platforms",
      label: "platformes",
      minWidth: 170,
      align: "left",
    },
    { id: "files", label: "Images", minWidth: 120 },
    { id: "action", label: "Actions", minWidth: isMobile ? 90 : 200 },
  ];

  return (
    <div className={classes.root}>
      <CustomSnackbar
        text={snackbarMessage}
        open={success}
        onCloseSnakbar={() => {
          setSuccess(false);
        }}
      />
      <div>
        Enregistrements: ({(items && items.length) || 0})
        <span className={classes.selection}>
          {" "}
          {itemsForSelection.length === 0
            ? `Aucune sélection`
            : itemsForSelection.length === 1
            ? `${itemsForSelection.length} sélectionné`
            : `${itemsForSelection.length} sélectionnés`}
        </span>
      </div>
      <div className={classes.tableCommands}>
        <Button
          disabled={itemsForSelection.length === 0}
          onClick={handleClickAssignItemsToPlatform}
          variant="contained"
        >
          Assigner un site de vente
        </Button>
      </div>
      <CommonDialog
        title="Sélection des plateformes de promotion"
        onClose={handleDialogClose}
        open={openDialog}
        render={() => (
          <AdminPickAndSelectSiteInterface
            onCancelAction={handleDialogClose}
            callback={handleDialogClose}
            items={itemsForSelection}
            merchants={merchants}
            onPostSave={handleDialogClose}
          />
        )}
      />

      <StickyHeadTable
        superUser={superUser}
        filters={["id", "title", "description", "published", "platforms"]}
        columns={
          isMobile
            ? displayedColumns.filter(
                c => c.id === "title" || c.id === "action"
              )
            : displayedColumns
        }
        data={formattedItems}
        onDelete={onDelete}
        onEdit={onEdit}
        onEdit2={onEdit2}
        onItemsForSelectionChange={handleItemsForSelectionChange}
        onRefreshData={onRefreshData}
        checkboxEnabled={true}
      />
    </div>
  );
};

export default AdminItems;
