import React from "react";

// import Button from "@material-ui/core/Button";

import StickyHeadTable from "./StickyHeadTable";

import CustomSnackbar from "./utils/Snakbar";

import { makeStyles } from "@material-ui/core/styles";

import { isMobile } from "react-device-detect";

var dateFormat = require("dateformat");

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 30,
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(95, 85, 87, 0.3)",
    padding: "20px 30px",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  tableCommands: {
    margin: "16px auto",
  },
  selection: {
    display: "inline-block",
    marginLeft: 20,
  },
}));

const AdminUsers = (props) => {
  const classes = useStyles();
  const { superUser, users, onDelete, onEdit, onRefreshData } = props;
  const [usersForSelection, setUsersForSelection] = React.useState([]);
  // const [ setOpenDialog] = React.useState(false);
  const [snackbarMessage] = React.useState("test");
  // const [setSnackbarMessage] = React.useState("test");
  const [success, setSuccess] = React.useState(false);

  const handleUsersForSelectionChange = (users) => {
    setUsersForSelection(users);
  };

  // const handleDialogClose = (result) => {
  //   const { success, message } = result || {};
  //   setOpenDialog(false);
  //   if (message) {
  //     setSnackbarMessage(message);
  //     setSuccess(true);
  //   }
  // };

  const formattedUsers = users.map((u) => {
    const { Attributes, ...user } = u;
    const email = Attributes.find((a) => a.Name === "email").Value;

    return {
      ...user,
      ...{ id: u.Username },
      ...{ email },
      ...{ created: dateFormat(user.UserCreateDate, "mmm dS, H:MM") },
      ...{ modified: dateFormat(user.UserLastModifiedDate, "mmm dS, H:MM") },
      ...{ isEnabled: user.Enabled ? "oui" : "non" },
    };
  });

  const displayedColumns = [
    { id: "id", label: "#", minWidth: 50, align: "left" },
    { id: "created", label: "Crée le", minWidth: 60 },
    { id: "email", label: "Email", minWidth: 80 },
    { id: "UserStatus", label: "Status", minWidth: 60 },
    { id: "isEnabled", label: "Activé", minWidth: 60 },
  ];

  return (
    <div className={classes.root}>
      <CustomSnackbar
        text={snackbarMessage}
        open={success}
        onCloseSnakbar={() => {
          setSuccess(false);
        }}
      />
      <div>
        Enregistrements: ({(users && users.length) || 0})
        <span className={classes.selection}>
          {" "}
          {usersForSelection.length === 0
            ? `Aucune sélection`
            : usersForSelection.length === 1
            ? `${usersForSelection.length} sélectionné`
            : `${usersForSelection.length} sélectionnés`}
        </span>
      </div>

      <StickyHeadTable
        superUser={superUser}
        filters={["id", "email"]}
        columns={
          isMobile
            ? displayedColumns.filter(
                (c) => c.id === "action" || c.id === "email"
              )
            : displayedColumns
        }
        data={formattedUsers}
        onDelete={onDelete}
        onEdit={onEdit}
        onUsersForSelectionChange={handleUsersForSelectionChange}
        onRefreshData={onRefreshData}
        checkboxEnabled={true}
      />
    </div>
  );
};

export default AdminUsers;
