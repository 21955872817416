import { useState, useEffect } from "react";
import useAPI from "../hooks/useAPI";
import { Storage } from "aws-amplify";
import shortid from "shortid";

import { useDispatch } from "react-redux";
import allActions from "../state/items/actions/index";

export default function useAdminItem(props) {
  const dispatch = useDispatch();
  const localUser = JSON.parse(localStorage.getItem("kdmUser"));
  const superUser = localUser.sub === process.env.REACT_APP_ADMIN_UID;

  const { currentItem, callback, onSave } = props;

  const { data, query: fetchData } = useAPI({
    type: "get",
    url: `items`,
    defer: true,
  });

  const { data: dataMenus } = useAPI({
    type: "get",
    url: `categories`,
  });

  const [title, setTitle] = useState(
    !!currentItem && currentItem.title ? currentItem.title : ""
  );

  const [description, setDescription] = useState(
    !!currentItem && currentItem.description ? currentItem.description : ""
  );

  const [prices, setPrices] = useState(
    !!currentItem && currentItem.prices ? currentItem.prices : []
  );

  const [nb, setNb] = useState(
    !!currentItem && currentItem.nb ? currentItem.nb : 0
  );

  const available =
    !!currentItem && currentItem.available ? currentItem.available : 0;

  const busy = !!currentItem && currentItem.busy ? currentItem.busy : 0;

  const [files, setFiles] = useState(
    !!currentItem && currentItem.files ? currentItem.files : []
  );

  const [tags, setTags] = useState((!!currentItem && currentItem.tags) || []);

  const [tagsAsMenu, setTagsAsMenu] = useState(
    (!!currentItem && currentItem.tags) || []
  );

  const [dimensions, setDimensions] = useState(
    (!!currentItem && currentItem.dimensions) || []
  );

  const [published, setPublished] = useState(
    (!!currentItem && currentItem.published) || "0"
  );

  const [isEvent, setIsEvent] = useState(
    (!!currentItem && currentItem.isEvent) || "0"
  );

  const [requiredFormular, setRequireFormular] = useState(
    (!!currentItem && currentItem.requiredFormular) || "0"
  );

  const [eventPlanner, setEventPlanner] = useState(
    (!!currentItem && currentItem.eventPlanner) || {
      dateEvent: {
        start: null,
        end: null,
      },
      dateSale: {
        start: null,
        end: null,
      },
    }
  );

  const platforms = (!!currentItem && currentItem.platforms) || [];

  const [submitting, setSubmitting] = useState(false);

  const [dialogPricing, setDialogPricing] = useState(false);

  const { query: postItem } = useAPI({
    defer: true,
    type: "post",
    url: "item",
    body: {
      uid: btoa(localUser.sub),
      title,
      description,
      tags,
      // price,
      prices,
      filesUploaded: files,
      dimensions,
      published,
      nb,
      eventPlanner,
      isEvent,
      requiredFormular,
    },
  });

  const { query: updateItem } = useAPI({
    defer: true,
    type: "post",
    url: `item`,
    body: {
      id: !!currentItem && currentItem.id ? currentItem.id : 0,
      value: "put",
      title,
      description,
      tags: tagsAsMenu,
      // price,
      prices,
      filesUploaded: files,
      dimensions,
      published,
      nb,
      eventPlanner,
      isEvent,
      requiredFormular,
    },
  });

  const handlePostItem = async () => {
    const item = {
      title,
      description,
      tags: tagsAsMenu,
      prices,
      filesUploaded: files,
    };

    setSubmitting(true);
    try {
      if (!!currentItem && currentItem.id) {
        await updateItem(item);
      } else {
        await postItem(item);
      }
      await fetchData();
      setSubmitting(false);
      onSave({ success: true, message: "Article sauvée avec succcès" });
      callback && callback();
    } catch (err) {
      console.log(err);
      onSave({
        success: false,
        message: "Erreur à la sauvegarde de l'article",
      });
      setSubmitting(false);
    }
  };

  const handleTitleChange = (e) => {
    const { value } = e.target;
    if (value.length > 50) {
      return;
    }
    setTitle(value);
  };

  const handleDescriptionChange = (e) => {
    const { value } = e.target;
    if (value.length > 700) {
      return;
    }
    setDescription(value);
  };

  const handleTagsChange = (value) => {
    setTags(value);
  };

  const handleTagsAsMenu = (value) => {
    if (tagsAsMenu.indexOf(value) === -1) {
      setTagsAsMenu([...tagsAsMenu, value]);
    } else {
      setTagsAsMenu(tagsAsMenu.filter((s) => s !== value));
    }
  };

  const handleNbItemChange = (e) => {
    const price = parseInt(e.target.value || 0, 10);
    if (price <= 1000) {
      setNb(price);
    }
  };

  const handleFileChange = (e) => {
    /* upload file */

    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      const filename = `kadomi/${shortid.generate()}-${file.name}`;
      Storage.put(filename, file, {
        contentType: file.type,
        progressCallback(progress) {
          const progressPercent = (progress.loaded / progress.total) * 100;
          console.log(
            `Uploaded file ${filename} : ${Math.round(progressPercent)}%`
          );
        },
      })
        .then((result) => {
          setFiles([...files, ...[filename]]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleDeleteFile = (f, key) => {
    const filesUpdated = [...files].filter((f, k) => k !== key);
    setFiles(filesUpdated);
  };

  const handleChangeDimension = (value, type) => {
    const idx = dimensions.findIndex((d) => d.type === type);
    if (idx === -1) {
      return;
    }
    dimensions[idx].items = value;
    setDimensions(dimensions);
  };

  const handleDeleteDimension = (type) => {
    setDimensions(dimensions.filter((d) => d.type !== type));
  };

  const handleAddDimension = (dimension, type) => {
    // verify that code is unique
    const dimensionExisting =
      Array.isArray(dimensions) && Array.isArray(dimensions).length > 0
        ? dimensions.find((d) => d.type === type)
        : false;

    if (!!dimensionExisting) {
      return false;
    }

    // add new object to the array
    setDimensions([
      ...dimensions,
      ...[
        {
          title: dimension,
          type,
          items: [],
        },
      ],
    ]);

    return true;
  };

  const handleStatusPublishedChange = (e) => {
    setPublished(e.target.value);
  };

  const handleOpenDialogPricing = () => {
    setDialogPricing(true);
  };

  const handleDialogClosePricing = () => {
    setDialogPricing(false);
  };

  const handleSavePrice = (price, currency) => {
    if (currency === "") {
      return;
    }
    setPrices([
      ...prices.filter((p) => p.currency !== currency),
      ...[{ price, currency }],
    ]);
    handleDialogClosePricing();
  };

  const handleDeleteSavedPrice = (currency) => {
    setPrices(prices.filter((p) => p.currency !== currency));
  };

  const handleEventPlanner = (date, id, moment) => {
    const dateObj = JSON.parse(`{"${moment}":"${date.toJSON()}"}`);

    const eventPlannerUpdated = { ...eventPlanner };

    if (id === "dateEvent") {
      Object.assign(eventPlannerUpdated.dateEvent, dateObj);
    }

    if (id === "dateSale") {
      Object.assign(eventPlannerUpdated.dateSale, dateObj);
    }

    setEventPlanner(eventPlannerUpdated);
  };

  const handleSetEvent = (event) => {
    setIsEvent(event.target.checked ? "1" : "0");
  };

  const handleSetFormular = (event) => {
    setRequireFormular(event.target.checked ? "1" : "0");
  };

  const handleSaveMenuForItemRefresh = (event) => {
    console.log("handleSaveMenuForItemRefresh");
  };

  useEffect(() => {
    if (data && data.items) {
      dispatch(allActions.itemActions.fetchAll(data.items));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    title,
    description,
    prices,
    nb,
    available,
    busy,
    tags,
    tagsAsMenu,
    files,
    dimensions,
    platforms,
    published,
    submitting,
    currentItem,
    dialogPricing,
    superUser,
    eventPlanner,
    isEvent,
    requiredFormular,
    dataMenus: dataMenus && dataMenus.menus ? dataMenus.menus : [],
    handlePostItem,
    onTitleChange: handleTitleChange,
    onDescriptionChange: handleDescriptionChange,
    onTagsChange: handleTagsChange,
    onTagsAsMenuChange: handleTagsAsMenu,
    onNbItemChange: handleNbItemChange,
    onFileChange: handleFileChange,
    onChangeDimension: handleChangeDimension,
    onDeleteDimension: handleDeleteDimension,
    onAddDimension: handleAddDimension,
    onStatusPublishedChange: handleStatusPublishedChange,
    openDialogPricing: handleOpenDialogPricing,
    onDialogClosePricing: handleDialogClosePricing,
    onSavePrice: handleSavePrice,
    onDeleteSavedPrice: handleDeleteSavedPrice,
    onEventPlanner: handleEventPlanner,
    onSetEvent: handleSetEvent,
    onSetFormular: handleSetFormular,
    onSaveMenuForItemRefresh: handleSaveMenuForItemRefresh,
    onHandleDeleteFile: handleDeleteFile,
  };
}
