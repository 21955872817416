import React from "react";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import Treeview from "./utils/Treeview";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";

import { IconButton } from "@material-ui/core";
import { extractIDsFromGraph } from "./utils/applibs";

import { GraphNode } from "../modules/Graph";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  fullWidth: { width: "100%" },
  title: {
    fontSize: "1.5em",
    fontWeight: "normal",
  },
  treeview: {
    overflow: "auto",
  },
  price: {
    float: "left",
    marginTop: 14,
    width: 50,
    marginLeft: 10,
  },
  uploadImage: {
    margin: "20px auto",
  },
  published: {
    display: "flex",
    flexDirection: "row",
  },
  actions: {
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: 40,
    padding: "10px 0",
  },
  categoryCategory: {
    margin: "10px auto 10px auto",
    width: "100%",
  },
  grid: {
    padding: 10,
    marginBottom: 10,
  },
  extraMarginTop: {
    marginTop: 20,
  },
  image: {
    height: 60,
  },
  fullWidth2: {
    width: "100%",
    display: "flex",
  },
}));

export default function AdminCategory(props) {
  const {
    title,
    description,
    graph,
    currentItem,
    onTitleChange,
    onDescriptionChange,
    onGraphChange,
    submitting,
    onCancelAction,
    onNodeSave,
  } = props;

  const classes = useStyles();
  const { id = null } = currentItem || {};
  let graphClone = { ...graph };

  const [selected, setSelected] = React.useState([]);
  const [labelSelected, setLabelSelected] = React.useState("");
  const [newNodeValue, setNewNodeValue] = React.useState("");

  const titleFormatted = id ? `${title} [#${id}]` : title;

  const handleSelectedNode = (nodeId) => {
    const id = parseInt(nodeId, 10);
    const selectedNode = GraphNode.findNodeByIdInTree(id, graphClone);
    setSelected(id);
    setLabelSelected(selectedNode.label);
  };

  const handleNodeChange = (e) => {
    setLabelSelected(e.target.value);
  };

  // const displayCurrentCategory = () => {
  //   const currentCategory = GraphNode.findNodeByIdInTree(selected, graphClone);
  //   return currentCategory && currentCategory.label && currentCategory.id !== 0
  //     ? currentCategory.label
  //     : "";
  // };

  const handleNewNodeChange = (e) => {
    setNewNodeValue(e.target.value);
  };

  const handleNewNodeSaveAction = () => {
    const nodeSelected = GraphNode.findNodeByIdInTree(selected, graphClone);
    if (nodeSelected && nodeSelected.label) {
      //insert new node
      const graphUpdated = GraphNode.syncCategoriesTreeWithNodeUpdatedHandler(
        newNodeValue,
        nodeSelected,
        graphClone
      );
      onGraphChange(graphUpdated);
    } else {
      console.log(`Node '${id}' not found`);
    }
  };

  const handleNodeDeleteAction = () => {
    const graphUpdated = GraphNode.searchAndDeleteNodeGraph(
      graphClone,
      selected
    );
    onGraphChange(graphUpdated);
  };

  const handleNodeSaveAction = () => {
    const nodeSelected = GraphNode.findNodeByIdInTree(selected, graphClone);
    if (nodeSelected && nodeSelected.label) {
      //update node
      const nodeUpdated = { ...nodeSelected, label: labelSelected };
      let cloneCategories = JSON.parse(JSON.stringify(graphClone));
      GraphNode.updateCategoriesTreeWithNewNode(nodeUpdated, cloneCategories);
      onGraphChange(cloneCategories);
    } else {
      console.log(`Node with id ${selected} not found`);
    }
  };

  const nodeIds = extractIDsFromGraph([], graphClone);

  return (
    <React.Fragment>
      <h1 className={classes.title}>{titleFormatted}</h1>
      <form noValidate autoComplete="off" className={classes.root}>
        <Grid container spacing={3}>
          <Grid md={12} item xs={12}>
            <Paper className={classes.grid} spacing={3}>
              <FormControl fullWidth>
                <TextField
                  label="Nom du menu"
                  margin="normal"
                  onChange={onTitleChange}
                  variant="outlined"
                  value={title}
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  label="Description"
                  margin="normal"
                  onChange={onDescriptionChange}
                  variant="outlined"
                  value={description}
                />
              </FormControl>
            </Paper>
            <Paper className={classes.grid} spacing={3}>
              <Grid md={12} item xs={12} className={classes.fullWidth2}>
                {labelSelected !== "" ? (
                  <div className={classes.fullWidth}>
                    <TextField
                      label="Menu sélectionné"
                      margin="normal"
                      onChange={handleNodeChange}
                      variant="outlined"
                      value={labelSelected}
                    />
                    <IconButton
                      edge="end"
                      disabled={submitting || labelSelected === ""}
                      aria-label="edit"
                      onClick={handleNodeSaveAction}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      disabled={submitting || labelSelected === ""}
                      aria-label="delete"
                      onClick={handleNodeDeleteAction}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <TextField
                      label={`Nouveau sous-menu`}
                      margin="normal"
                      onChange={handleNewNodeChange}
                      variant="outlined"
                      value={newNodeValue}
                    />
                    <IconButton
                      edge="end"
                      disabled={submitting}
                      aria-label="add"
                      onClick={handleNewNodeSaveAction}
                    >
                      <AddIcon />
                    </IconButton>
                  </div>
                ) : null}
              </Grid>
              <Grid className={classes.treeview} md={12} item xs={12}>
                <Treeview
                  expanded={nodeIds}
                  categories={graphClone}
                  onSelectedNode={handleSelectedNode}
                />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </form>
      <div className={classes.actions}>
        <Button
          variant="contained"
          disabled={submitting || title === ""}
          onClick={onNodeSave}
        >
          {submitting ? "processing ..." : "Sauver"}
        </Button>

        <Button
          variant="contained"
          disabled={submitting}
          onClick={onCancelAction}
        >
          Annuler
        </Button>
      </div>
    </React.Fragment>
  );
}
