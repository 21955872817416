import React from "react";
import useAdminTicket from "../hooks/useAdminTicket";
import AdminTicket from "./../components/AdminTicket";

const AdminTicketInterface = (props) => {
  const {
    ticketCode,
    productName,
    lastname,
    firstname,
    mobile,
    saleTransaction,
    ticketConsumed,
    ticketCreated,
    ticketStatus,
  } = useAdminTicket(props);

  const { onCancelAction } = props;

  return (
    <AdminTicket
      ticketCode={ticketCode}
      productName={productName}
      lastname={lastname}
      firstname={firstname}
      mobile={mobile}
      saleTransaction={saleTransaction}
      ticketConsumed={ticketConsumed}
      ticketCreated={ticketCreated}
      ticketStatus={ticketStatus}
      onCancelAction={onCancelAction}
    />
  );
};

export default AdminTicketInterface;
