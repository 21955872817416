import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";

export default function useAdminProfile(props) {
  // read user from localStorage (redux once refactor of store is done))

  const [sub, setSub] = useState("");
  const [firstName, setFirstname] = useState("");
  const [lastName, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [npa, setNpa] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const localUser = JSON.parse(localStorage.getItem("kdmUser"));
    setSub(localUser.sub);
    setEmail(localUser.email);
    setFirstname(localUser["custom:firstName"]);
    setLastname(localUser["custom:lastName"]);
    setPhone(localUser.phone_number || "");
    setCity(localUser["custom:city"]);
    setNpa(localUser["custom:npa"]);
  }, []);

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleChangeFirstName = (e) => {
    setFirstname(e.target.value);
  };

  const handleChangeLastName = (e) => {
    setLastname(e.target.value);
  };

  const handleChangePhone = (e) => {
    setPhone(e.target.value);
  };

  const handleChangeCity = (e) => {
    setCity(e.target.value);
  };

  const handleChangeNpa = (e) => {
    setNpa(e.target.value);
  };

  const handleSnackbar = (bool) => {
    setSuccess(bool);
  };

  const handleUpdateUserAtrributes = async () => {
    setSubmitting(true);
    setSuccess(false);
    setError(false);
    try {
      let user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        "custom:firstName": firstName,
        "custom:lastName": lastName,
        "custom:city": city,
        "custom:npa": npa,
      });
      setSubmitting(false);
      setSuccess(true);
      setError(false);
    } catch (error) {
      console.log(error);
      setSubmitting(false);
      setSuccess(true);
      setError(true);
    }
  };

  return {
    defaultParams: {
      sub,
      firstName,
      lastName,
      email,
      phone,
      city,
      npa,
      submitting,
      success,
      error,
      onChangeEmail: handleChangeEmail,
      onChangeFirstName: handleChangeFirstName,
      onChangeLastName: handleChangeLastName,
      onChangePhone: handleChangePhone,
      onChangeCity: handleChangeCity,
      onChangeNpa: handleChangeNpa,
      onUpdateUserAtrributes: handleUpdateUserAtrributes,
      onSubmit: handleUpdateUserAtrributes,
      onCloseSnakbar: handleSnackbar,
    },
  };
}
