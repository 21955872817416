import "date-fns";
import React from "react";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

export default function MaterialUIPickers(props) {
  // const [selectedDate, setSelectedDate] = React.useState(props.date || null);
  const { dateId, moment, date = null, onEventPlanner } = props;
  const datePickerDialogId = `date-picker-dialog-${dateId}-${moment}`;
  const timePickerDialogId = `time-picker-dialog-${dateId}-${moment}`;

  const handleDateChange = (date) => {
    onEventPlanner(date, dateId, moment);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
        <KeyboardDatePicker
          margin="normal"
          id={datePickerDialogId}
          format="MM/dd/yyyy"
          value={date !== "" ? date : null}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
        <KeyboardTimePicker
          margin="normal"
          id={timePickerDialogId}
          value={date !== "" ? date : null}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            "aria-label": "change time",
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
