import React from "react";

import StickyHeadTable from "./StickyHeadTable";

// import CustomSnackbar from "./utils/Snakbar";

import { makeStyles } from "@material-ui/core/styles";

import { isMobile } from "react-device-detect";

var dateFormat = require("dateformat");

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 30,
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(95, 85, 87, 0.3)",
    padding: "20px 30px",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  tableCommands: {
    margin: "16px auto",
  },
  panelTableSelection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  selection: {
    display: "inline-block",
    marginLeft: 20,
  },
}));

const AdminTickets = function(props) {
  const classes = useStyles();
  const {
    tickets: items,
    superUser,
    onEdit,
    canEdit,
    onRefreshData,
    onDelete,
    onSMSRequest,
    // candidateForSMS,
    businessType,
    search,
  } = props;
  const [itemsForSelection, setItemsForSelection] = React.useState([]);
  // const [snackbarMessage, setSnackbarMessage] = React.useState("test");
  // const [success, setSuccess] = React.useState(false);

  const handleItemsForSelectionChange = items => {
    setItemsForSelection(items);
  };

  const displayedColumnsInitial = [
    { id: "ticketId", label: "#", minWidth: 50, align: "left" },
    { id: "productName", label: "Evénement", minWidth: 60 },
    { id: "ticketCode", label: "Ticket n°", minWidth: 60 },
    { id: "ticketCreated", label: "Créé le", minWidth: 60 },
    { id: "ticketStatus", label: "Status", minWidth: 60 },
    { id: "ticketConsumed", label: "Consommé", minWidth: 60 },
    { id: "saleId", label: "Réf. vente", minWidth: 60 },
    { id: "firstname", label: "Prénom", minWidth: 60 },
    { id: "lastname", label: "Nom", minWidth: 60 },
    { id: "action", label: "Actions", minWidth: isMobile ? 60 : 120 },
  ];

  const displayedColumns = superUser
    ? displayedColumnsInitial
    : displayedColumnsInitial.filter(c => c.id !== "___FIELDS_TO_EXCLUDE___");

  return (
    <div className={classes.root}>
      {/* <CustomSnackbar
        text={snackbarMessage}
        open={success}
        onCloseSnakbar={() => {
          setSuccess(false);
        }}
      /> */}
      <div>
        Enregistrements: ({(items && items.length) || 0})
        <span className={classes.selection}>
          {" "}
          {itemsForSelection.length === 0
            ? `Aucune sélection`
            : itemsForSelection.length === 1
            ? `${itemsForSelection.length} sélectionné`
            : `${itemsForSelection.length} sélectionnés`}
        </span>
      </div>

      <StickyHeadTable
        search={search}
        businessType={businessType}
        canEdit={canEdit}
        superUser={superUser || true}
        filters={[
          "ticketId",
          "ticketCreated",
          "ticketCode",
          "firstname",
          "lastname",
          "productName",
          "mobile",
          "saleId",
        ]}
        columns={
          isMobile
            ? displayedColumns.filter(
                c =>
                  c.id === "ticketCode" ||
                  c.id === "productName" ||
                  c.id === "action"
              )
            : displayedColumns
        }
        data={(items || []).map(i => ({
          ...i,
          ...{ id: i.ticketId },
          ...{
            ticketCreated: dateFormat(i.ticketCreated, "mmm dS, H:MM"),
          },
          ...{
            ticketConsumed: i.ticketConsumed
              ? dateFormat(i.ticketConsumed, "mmm dS, H:MM")
              : "",
          },
          ...{
            ticketStatus: ["1", "4"].includes(i.ticketStatus)
              ? "Valide"
              : "Consommé",
          },
          ...{
            rawStatus: i.ticketStatus,
          },
        }))}
        onDelete={onDelete}
        onEdit={onEdit}
        onItemsForSelectionChange={handleItemsForSelectionChange}
        onRefreshData={onRefreshData}
        checkboxEnabled={true}
        onSMSRequest={onSMSRequest}
      />
    </div>
  );
};

export default AdminTickets;
